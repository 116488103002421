import { api } from './api'

type AllTags = {
  id: number
  tag: string
}

type Tag = {
  created_at: string
  tag_type_id: number
  tag: string
  client_id: string
  loan_id: string
  state: boolean
}

type ClientTag = {
  id: number
  tag: string
}

const tagsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllTags: builder.query<AllTags[], void>({
      query: () => ({
        url: '/crm/v1/tags/get-all-tags'
      })
    }),
    getLoanTags: builder.query<Tag[], string>({
      query: (id) => `crm/v1/tags/loans/${id}`,
      providesTags: (_result, _err, id) => [{ type: 'LoanTags', id }]
    }),
    getLoansTags: builder.query<{ id: number; tag: string }[], void>({
      query: () => `crm/v1/tags/loans`
    }),
    getClientTags: builder.query<Tag[], string>({
      query: (id) => `crm/v1/tags/clients/${id}`,
      providesTags: (_result, _err, id) => [{ type: 'ClientTags', id }]
    }),
    getClientsTags: builder.query<ClientTag[], void>({
      query: () => '/crm/v1/tags/clients'
    }),
    setLoansTagsByID: builder.mutation<string, { id: string; body: number[] }>({
      query: ({ id, body }) => ({
        url: `/crm/v1/tags/loans/${id}/add`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: () => ['LoanTags']
    }),
    removeLoansTagsByID: builder.mutation<
      string,
      { id: string; body: number[] }
    >({
      query: ({ id, body }) => ({
        url: `/crm/v1/tags/loans/${id}/delete`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: () => ['LoanTags']
    }),
    setClientsTagsByID: builder.mutation<void, { id: string; body: any }>({
      query: ({ id, body }) => ({
        url: `/crm/v1/tags/clients/${id}/add`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: () => ['ClientTags']
    }),
    removeClientsTagsByID: builder.mutation<void, { id: string; body: any }>({
      query: ({ id, body }) => ({
        url: `/crm/v1/tags/clients/${id}/delete`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: () => ['ClientTags']
    })
  })
})

export const {
  useGetAllTagsQuery,
  useGetLoanTagsQuery,
  useGetLoansTagsQuery,
  useGetClientTagsQuery,
  useGetClientsTagsQuery,
  useSetLoansTagsByIDMutation,
  useRemoveLoansTagsByIDMutation,
  useSetClientsTagsByIDMutation,
  useRemoveClientsTagsByIDMutation
} = tagsApi
