import { LayoutItem, LayoutSubItem } from '../layoutItems.types'

export const getSprData = () => {
  const subItems: LayoutSubItem[] = [
    {
      id: 'redirect-settings',
      label: 'Redirect settings',
      link: '/redirect-settings'
    }
  ]

  const sprData: LayoutItem = {
    id: 'SPR',
    label: 'SPR',
    icon: 'mdi mdi-arrow-decision-outline',
    subItems
  }

  return sprData
}
