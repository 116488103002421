import { LayoutItem, LayoutSubItem } from '../layoutItems.types'

export const getPaymentsData = () => {
  const subItems: LayoutSubItem[] = [
    {
      id: 'payments-all',
      label: 'View all',
      link: '/payments'
    },
    {
      id: 'payment-view',
      link: '/payment-view',
      hidden: true,
      label: 'View'
    }
  ]

  const paymentsData: LayoutItem = {
    id: 'payments',
    label: 'Payments',
    icon: 'las la-money-bill-wave',
    subItems
  }

  return paymentsData
}
