import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Flatpickr from 'react-flatpickr'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import { formatAPIDateTime, formatUIDate } from 'src/utils'
import {
  Row,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Alert,
  Spinner
} from 'reactstrap'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  useGetPaymentTypesQuery,
  useGetTransactionByIdQuery,
  useSetTransactionMutation
} from 'src/services/payments'
import { paymentsLink } from './constants'
import { UseQueryHookResultData } from 'src/services/api'

function parseProductCode(
  // eslint-disable-next-line @typescript-eslint/ban-types
  value: null | undefined | 'IL' | 'PDL' | 'undefined' | (string & {})
): string | null {
  switch (value) {
    case 'IL':
    case 'PDL':
    case 'il':
    case 'pdl':
      return value
    default:
      return null
  }
}

export const PaymentCreatePage: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const id = searchParams.get('loan_id')
  const productCode = parseProductCode(searchParams.get('product_code'))
  const { data: paymentTypes } = useGetPaymentTypesQuery()

  const { data: payment } = useGetTransactionByIdQuery(id!, { skip: !id })
  const [setTransaction, { isSuccess, isError, isLoading, error: errorText }] =
    useSetTransactionMutation()

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      product_code: productCode ?? 'IL',
      loan_id: id || '',
      amount: '',
      transaction_id: '',
      transaction_date: '',
      comment: '',
      payment_type: '',
      ...payment
    } as Omit<
      UseQueryHookResultData<typeof useGetTransactionByIdQuery>,
      'is_prepayment' | 'receipt_date' | 'payment_type'
    > & {
      transaction_id: string
      transaction_date: string
      product_code: 'IL' | 'PDL'
      payment_type: '1' | '2' | '3' | '4' | ''
    },
    validationSchema: Yup.object({
      loan_id: Yup.string(),
      amount: Yup.number().positive(),
      transaction_id: Yup.string(),
      transaction_date: Yup.string(),
      comment: Yup.string(),
      payment_type: Yup.string().when('product_code', {
        is: (value: string) => value === 'IL',
        then: (schema) => schema.required('Выберите тип платежа'),
        otherwise: (schema) => schema.notRequired().nullable()
      })
    }),
    onSubmit: ({ product_code: _, ...values }) =>
      setTransaction({
        ...values,
        payment_type: parseInt(values.payment_type) || null
      })
        .unwrap()
        .then(() =>
          toast.success(t('Operation success'), {
            onClose: () => navigate(paymentsLink)
          })
        )
        .catch(() => toast.error(t('Error adding')))
  })

  const pageTitle = t('Create_payment')
  document.title = pageTitle

  return (
    <>
      <UiContent />
      <div className='page-content'>
        <BreadCrumb title={pageTitle} />
        <Card>
          <CardBody>
            <MetaBar backLink={paymentsLink} />
            <Row className='mt-3'>
              <Form
                className='needs-validation'
                onSubmit={validation.handleSubmit}
              >
                <FormGroup className='mb-3'>
                  <Label htmlFor='product_code'>{t('product_code')}</Label>
                  <Input
                    name='product_code'
                    type='select'
                    id='product_code'
                    className='form-select form-select-sm'
                    onChange={(event) => {
                      switch (event.currentTarget.value) {
                        case 'IL':
                          validation.setFieldValue('payment_type', '1')
                          break
                        case 'PDL':
                        default:
                          validation.setFieldValue('payment_type', '')
                          break
                      }
                      validation.handleChange(event)
                    }}
                    onBlur={validation.handleBlur}
                    value={validation.values.product_code}
                    // если в адресной строке уже был указан код продукта, то не разрешаем менять
                    disabled={!!productCode}
                  >
                    <option value='IL'>IL</option>
                    <option value='PDL'>PDL</option>
                  </Input>
                </FormGroup>
                <FormGroup className='mb-3'>
                  <Label htmlFor='loan_id'>{t('loan')}</Label>
                  <Input
                    name='loan_id'
                    type='text'
                    className='form-control form-control-sm'
                    id='loan_id'
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.loan_id}
                    invalid={!!validation.errors.loan_id}
                  />
                  <FormFeedback>{validation.errors.loan_id}</FormFeedback>
                </FormGroup>

                <FormGroup className='mb-3'>
                  <Label htmlFor='amount'>{t('amount')}</Label>
                  <Input
                    name='amount'
                    type='number'
                    className='form-control form-control-sm'
                    id='amount'
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.amount}
                    invalid={!!validation.errors.amount}
                  />
                  <FormFeedback>{validation.errors.amount}</FormFeedback>
                </FormGroup>
                <FormGroup className='mb-3'>
                  <Label htmlFor='transaction_id'>{t('transaction_id')}</Label>
                  <Input
                    name='transaction_id'
                    type='text'
                    className='form-control form-control-sm'
                    id='transaction_id'
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.transaction_id}
                  />
                </FormGroup>
                <FormGroup className='mb-3'>
                  <Label htmlFor='transaction_date'>
                    {t(
                      validation.values.product_code === 'IL'
                        ? 'Дата поступления'
                        : 'transaction_date'
                    )}
                  </Label>
                  <Flatpickr
                    name='transaction_date'
                    id='transaction_date'
                    className='form-control form-control-sm'
                    options={{
                      enableTime: true,
                      time_24hr: true,
                      dateFormat: 'd-m-Y H:i',
                      defaultDate: formatUIDate(
                        validation.values.transaction_date
                      ),
                      onChange: (selectedDates: Date[]) => {
                        validation.setFieldValue(
                          'transaction_date',
                          formatAPIDateTime(selectedDates[0])
                        )
                      }
                    }}
                  />
                </FormGroup>
                <FormGroup className='mb-3'>
                  <Label htmlFor='comment'>{t('comment')}</Label>
                  <Input
                    name='comment'
                    type='text'
                    className='form-control form-control-sm'
                    id='comment'
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.comment}
                  />
                </FormGroup>
                <FormGroup className='mb-3'>
                  <Label htmlFor='payment_type'>{t('payment_type')}</Label>
                  <Input
                    type='select'
                    name='payment_type'
                    id='payment_type'
                    className='form-select form-select-sm'
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.payment_type}
                    disabled={validation.values.product_code !== 'IL'}
                    invalid={!!validation.errors.payment_type}
                  >
                    <option
                      value=''
                      hidden={validation.values.product_code === 'IL'}
                      disabled={validation.values.product_code === 'IL'}
                    >
                      -
                    </option>
                    {paymentTypes?.map((paymentType) => (
                      <option
                        key={paymentType.id}
                        value={paymentType.id.toString()}
                      >
                        {paymentType.name}
                      </option>
                    ))}
                  </Input>
                  <FormFeedback>{validation.errors.payment_type}</FormFeedback>
                </FormGroup>

                {isError && errorText && (
                  <Alert color='warning'>
                    <strong>{JSON.stringify(errorText)}</strong>
                  </Alert>
                )}

                {isSuccess && (
                  <Alert color='success'>
                    <strong>{t('Created')}</strong>
                  </Alert>
                )}

                {isLoading ? (
                  <Spinner color='primary'>{t('Loading...')}</Spinner>
                ) : (
                  <Button
                    className='text-nowrap'
                    color='primary'
                    type='submit'
                    disabled={!validation.dirty}
                  >
                    {t('Create')}
                  </Button>
                )}
              </Form>
            </Row>
          </CardBody>
        </Card>
      </div>
    </>
  )
}
