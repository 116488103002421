import { api, omitBlankEntries } from './api'

type Collectors = {
  name: string
  surname: string
  id: string
  group_id: number
  username: string
  status: string
  midname: string
  collection_stage_id: number
  sip_username?: null
  sip_password?: null
}
type CheckSipId = {
  success: boolean
  data: {
    exists: boolean
  }
}

type CollectorRequest = {
  id: number
  name: string
  sip_username: string
}

const CollectorsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCollectors: builder.query<
      Collectors[],
      Partial<{
        limit: number
        offset: number
      }>
    >({
      query: (params) => ({
        url: 'crm/v1/calling/collectors',
        params: omitBlankEntries(params)
      }),
      providesTags: (result = []) => [
        ...result.map(({ id }) => ({ type: 'Collectors', id }) as const),
        { type: 'Collectors', id: 'LIST' }
      ]
    }),
    getCollector: builder.query<CollectorRequest[], string>({
      query: (user_id) => ({
        url: `crm/v1/calling/collectors/${user_id}`
      }),
      providesTags: ['Collector']
    }),
    postSegmentToCollector: builder.mutation<
      Collectors,
      {
        id: string
        sip_id: string
        body: { stage_ids: number[] }
      }
    >({
      query: ({ id, sip_id, body }) => ({
        url: `crm/v1/calling/collectors/${id}?sip_id=${sip_id}`,
        method: 'POST',
        body
      }),
      invalidatesTags: () => ['Collector']
    }),
    checkSipId: builder.query<CheckSipId, string>({
      query: (sip_id) => ({
        url: `crm/v1/calling/collectors/check-sip/${sip_id}`
      })
    })
  })
})

export const {
  useGetCollectorsQuery,
  useGetCollectorQuery,
  useLazyCheckSipIdQuery,
  usePostSegmentToCollectorMutation
} = CollectorsApi
