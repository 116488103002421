import { useTranslation } from 'react-i18next'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import { Alert, Card, CardBody, Input, Label } from 'reactstrap'
import List from 'src/Components/Common/EntitiesView/List'
import {
  useGetLoansAssignmentListQuery,
  useUploadFileResecuringLoansMutation
} from 'src/services/calling'
import Loader from 'src/Components/Common/Loader'
import ErrorPage from '../ErrorPage'

type createError = {
  data: {
    detail: {
      error_id?: number
      message: string
    }
  }
}

export const ResecuringLoans = () => {
  const { t } = useTranslation()

  const {
    data: list,
    isFetching,
    isSuccess,
    error: listError
  } = useGetLoansAssignmentListQuery()

  const [
    uploadFile,
    {
      isLoading: isLoadUploadFile,
      error: createErrorUploadFile,
      isSuccess: isSuccessfulUploadFile
    }
  ] = useUploadFileResecuringLoansMutation()

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0]
    if (!file) return
    const inputFile = new FormData()
    inputFile.append('csv', file)
    uploadFile(inputFile)
    event.target.value = ''
  }

  const handleErrors = (error: unknown) => {
    const { data } = error as createError
    return data.detail.message
  }

  const title = t('Re-securing loans')

  if (listError) {
    if ('data' in listError) {
      return <ErrorPage title={title} error={listError} />
    }
  }

  return (
    <div className='page-content'>
      <BreadCrumb title={title} pageTitle={undefined} link={undefined} />
      <Card>
        <CardBody>
          <div className='d-flex align-items-center gap-3'>
            <Label
              htmlFor='upload_resecuringLoans'
              className='mb-0 btn-label btn btn-primary'
              style={{ backgroundColor: '#405189' }}
            >
              <i className='ri-add-line label-icon align-middle fs-16 me-2'></i>
              {t('Upload document')}
            </Label>
            <Input
              className='d-none'
              type='file'
              name='upload_resecuringLoans'
              id='upload_resecuringLoans'
              accept='.csv'
              onChange={handleFileInputChange}
            />
            {isLoadUploadFile && <Loader />}
          </div>
          <div className='my-2'>
            {isSuccessfulUploadFile && (
              <Alert color='success'>
                <strong>{t('Upload successful. Reattaching')}</strong>
              </Alert>
            )}
            {createErrorUploadFile && 'data' in createErrorUploadFile && (
              <Alert color='warning'>
                <strong>{handleErrors(createErrorUploadFile)}</strong>
              </Alert>
            )}
          </div>
          {isFetching ? (
            <Loader />
          ) : isSuccess && list?.length ? (
            <div className='table-responsive table-card mt-3'>
              <List
                style={{ width: 'fit-content' }}
                list={list?.map((item) => ({ ...item }))}
                fieldsDescription={{
                  created_at: { isDateTime: true },
                  file_name: {},
                  status: {},
                  description: { mappingClassName: () => 'text-wrap' }
                }}
                className='table-sm align-middle table-nowrap mb-0'
                hover
              />
            </div>
          ) : (
            <p className='mt-3'>{t('No data')}</p>
          )}
        </CardBody>
      </Card>
    </div>
  )
}
