import * as items from './items/index'
import { LayoutItem } from './layoutItems.types'

export const getLayoutMenuData = (roles: string[]) => {
  const includes_audit_fssp = roles?.includes('audit_fssp')
  const includes_head_of_comunications = roles?.includes(
    'head_of_comunications'
  )
  const includes_head_of_collection = roles?.includes('head_of_collection')
  const distribute_the_loan = roles?.includes('distribute_the_loan')

  const basicItems: LayoutItem[] = [
    items.getPaymentsData(),
    items.getLoansData(),
    items.getApplicationsData(),
    items.getClientsData(),
    items.getProfilesData(),
    items.getSettingsData(),
    items.getPromotionsData()
  ]

  const noAuditItems: LayoutItem[] = !includes_audit_fssp
    ? [
        items.getCollectionsData(
          includes_head_of_collection,
          distribute_the_loan
        ),
        items.getAdminData(),
        items.getClaimsData(includes_head_of_comunications),
        items.getChatsData(),
        items.getCompaniesData(),
        items.getSprData()
      ]
    : []

  return [...basicItems, ...noAuditItems]
}
