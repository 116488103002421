import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Loader from '../Loader'
import Table4Columns from './Table4Columns'
import { useGetTrafficSourcesQuery } from 'src/services/applications'
import { DangerAlert } from 'src/pages/Admin/ErrorPage'

type ApplicationsTrafficProps = {
  applicationId: string
  style?: any
}

const columnsDescription = [['name'], ['url_params']]

const fieldsDescription = {
  name: { label: 'traffic source' },
  url_params: { label: 'utm', isObjList: true }
}

export const TrafficSource: FC<ApplicationsTrafficProps> = ({
  applicationId,
  style
}) => {
  const { t } = useTranslation()

  const {
    data: trafficSources,
    error,
    isLoading
  } = useGetTrafficSourcesQuery(applicationId, { skip: applicationId == null })

  if (isLoading) {
    return <Loader />
  }

  if (!trafficSources || !trafficSources.length) {
    return (
      <div className={style}>
        <dl className='row mb-0'>
          {error ? (
            <DangerAlert error={error} />
          ) : (
            <small>{t('No data')}</small>
          )}
        </dl>
      </div>
    )
  }

  return (
    <div className={style}>
      {trafficSources.map((list) => (
        <Table4Columns
          key={list.name}
          columnsDescription={columnsDescription}
          fieldsDescription={fieldsDescription}
          className='table-sm align-middle mb-0'
          style={{ width: 'max-content' }}
          entity={list}
        />
      ))}
    </div>
  )
}
