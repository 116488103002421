import { LayoutItem, LayoutSubItem } from '../layoutItems.types'

export const getClientsData = () => {
  const subItems: LayoutSubItem[] = [
    {
      id: 'clients-all',
      label: 'View all',
      icon: 'bx bx-pencil',
      link: '/clients'
    },
    {
      id: 'clients-tags',
      label: 'Tags',
      link: '/add-tags'
    },
    {
      id: 'client-view',
      hidden: true,
      link: '/client-view',
      label: 'View'
    },
    {
      id: 'client-edit',
      hidden: true,
      link: '/client-edit',
      label: 'Edit'
    }
  ]

  const clientsData: LayoutItem = {
    id: 'clients',
    label: 'Clients',
    icon: 'ri-user-3-line',
    subItems
  }

  return clientsData
}
