import { LayoutItem, LayoutSubItem } from '../layoutItems.types'

export const getCompaniesData = () => {
  const subItems: LayoutSubItem[] = [
    {
      id: 'companies',
      label: 'Companies',
      link: '/companies'
    },
    {
      id: 'company-edit',
      label: 'Add company',
      link: '/company-edit'
    }
  ]

  const companiesData: LayoutItem = {
    id: 'companies',
    label: 'Companies',
    icon: 'ri-bank-line',
    subItems
  }

  return companiesData
}
