import { ComponentProps, FC } from 'react'
import { useTranslation } from 'react-i18next'
import Client, { ENTITY_TYPE } from 'src/client'
import Tabs from 'src/Components/Common/Tabs'
import { Loan } from 'src/services/loans'
import { Profile } from 'src/services/profiles'
import { useSelector } from 'src/store'
import { omit } from 'src/utils'
import { CallFeedbacks } from './CallFeedbacks'
import { KpkEvents } from 'src/Components/Common/EntitiesView/KpkEvents/KpkEvents'

export const LoanRelated: FC<{
  loan: Loan
  profile?: Profile
  open: () => void
  setPhone: (phone: string) => void
}> = ({ loan, profile, open, setPhone }) => {
  const { t } = useTranslation()
  const roles = useSelector((state) => state.Login.userInfo?.roles)

  const items: ComponentProps<typeof Tabs>['items'] = []

  if (profile && profile.client)
    items.push({
      title: `${t('Client')}`, //`` id: ${loan.profile.client.id}`,
      entity: profile.client,
      entityId: profile.client.id,
      entityType: Client.ENTITY_TYPE.CLIENTS,
      icon: null //'ri-user-heart-line',
    })

  items.push({
    title: `${t('Profile')}`, // id: ${loan.profile.id}`,
    entityId: loan.profile_id,
    entityType: Client.ENTITY_TYPE.PROFILES,
    icon: null //'ri-user-settings-line',
  })

  if (roles?.includes('audit_fssp') === false) {
    items.push({
      title: t('Communications'),
      entityId: loan.profile_id,
      entityType: ENTITY_TYPE.COMMUNICATIONS,
      icon: null
    })
  }

  // @ts-expect-error Property 'application' does not exist on type 'Loan'
  if (loan.application) {
    // @ts-expect-error Property 'application' does not exist on type 'Loan'
    const application = omit(loan.application, [
      'profile',
      'profile_id',
      'verification_process',
      'loan'
    ])
    items.push({
      title: `${t('Application')}`, // id: ${loan.application.id}`,
      entity: application,
      // @ts-expect-error Property 'application' does not exist on type 'Loan'
      entityId: loan.application.id,
      entityType: Client.ENTITY_TYPE.APPLICATIONS,
      icon: null //'ri-pencil-line',
    })
  }

  if (loan.product_copy)
    items.push({
      title: `${t('Product')}`, // id: ${loan.product_copy.id}`,
      entityId: loan.product_copy.id,
      entityType: Client.ENTITY_TYPE.PRODUCT,
      icon: null //'ri-product-hunt-line',
    })

  /*if (loan.product_copy && loan.product_copy.extension_setting) {
    const {created_at, canceled_at, updated_at, ...extension_setting} = loan.product_copy.extension_setting;
    items.push(
      {
        title: t('Extensions'),
        entity: extension_setting,
        entityType: 'extension_setting',
        icon: null, //'ri-coin-line',
      },
    );
  }*/

  items.push({
    title: t('Funds'),
    entityType: 'loan_transactions',
    entity: loan,
    icon: null, //'ri-coin-line',
    entityId: loan.id
  })

  items.push({
    title: t('Operations'),
    entityType: 'loan_operations',
    entityId: loan.id,
    icon: null //'ri-coin-line',
  })

  items.push({
    title: t('Tranches'),
    entityId: loan.id,
    entityType: 'loan_tranches',
    icon: null //'ri-coin-line',
  })

  items.push({
    title: t('Holidays'),
    entityId: loan.id,
    entityType: 'loan_holidays',
    icon: null //'ri-coin-line',
  })

  if (roles?.includes('audit_fssp') === false) {
    items.push({
      title: t('Agencies'),
      entityId: loan.id,
      entityType: 'loan_agencies',
      icon: null //'ri-coin-line',
    })
  }

  items.push({
    title: `${t('DocumentsSigned')}`,
    entityId: loan.id,
    entityType: 'documents_signed'
  })

  if (roles?.includes('audit_fssp') === false) {
    if (
      roles?.find(
        (role) =>
          role === 'collector' || role === 'operatorBackOffice' || 'admin'
      )
    ) {
      items.push({
        title: `${t('call result')}`,
        entity: loan,
        entityType: 'communications'
      })
    }
  }

  items.push({
    title: t('loans_bki'),
    entityId: loan.id,
    entityType: 'loans_bki',
    icon: null
  })

  if (roles?.includes('audit_fssp') === false) {
    items.push({
      title: t('tags'),
      entityId: loan.id,
      entityType: 'loans_tags',
      icon: null
    })
  }

  items.push({
    title: t('recurring_payments'),
    entityId: loan.id,
    entityType: 'rec_payments',
    icon: null
  })

  items.push({
    title: t('Chargeback'),
    entityId: loan.id,
    entityType: 'chargeback',
    icon: null
  })

  items.push({
    title: t('Atol'),
    entityId: loan.id,
    entityType: 'atol',
    icon: null
  })

  items.push({
    title: t('loan_history'),
    entityId: loan.id,
    entityType: 'loan_history',
    icon: null
  })

  if (roles?.includes('audit_fssp') === false) {
    items.push({
      title: 'Найденные номера телефонов',
      component: (
        <CallFeedbacks
          phonePrefix={loan.phone_prefix}
          clientId={profile?.client.id}
          open={open}
          setPhone={setPhone}
        />
      )
    })
  }

  if (process.env.REACT_APP_ENVIRONMENT === 'KPK') {
    items.push({
      title: 'События КПК',
      component: (
        <KpkEvents
          loanId={loan.id}
          profileId={loan.profile_id}
          //@ts-ignore
          applicationId={loan?.application_id}
        />
      )
    })
  }
  if (loan.product_copy.product_code === 'IL') {
    items.push({
      title: t('Payment schedule'),
      entityId: loan.id,
      entityType: 'payment_schedule',
      icon: null
    })
  }

  return <Tabs items={items} />
}
