import { LayoutItem, LayoutSubItem } from '../layoutItems.types'

export const getSettingsData = () => {
  const subItems: LayoutSubItem[] = [
    {
      id: 'profile-settings-formatting',
      label: 'Formatting data',
      icon: 'ri-percent-line',
      link: '/settings'
    }
  ]

  const settingsData: LayoutItem = {
    id: 'profile-settings',
    label: 'Settings',
    icon: 'las la-sliders-h',
    subItems
  }

  return settingsData
}
