import List from './List'
import React, { useState } from 'react'
import MetaBar from '../MetaBar'
import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Form,
  FormGroup,
  Col,
  Row,
  Label,
  FormFeedback,
  Alert
} from 'reactstrap'
import { Link } from 'react-router-dom'
import { useGetLoanPaymentsQuery } from 'src/services/loans'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Flatpickr from 'react-flatpickr'
import {
  useGetPaymentTypesQuery,
  useSetChargebackMutation
} from 'src/services/payments'
import { DangerAlert } from 'src/pages/Admin/ErrorPage'
import { omitBy } from 'src/utils'
import { useGetUserInfoQuery } from '../../../services/keycloak'

const fieldsDescription = {
  id: { isShortId: false, isLink: true, linkFieldName: 'id_link' },
  operation_date: { isDate: true },
  created_at: { isDateTime: true },
  receipt_date: { label: 'Дата поступления', isDateTime: true },
  payment_type: {},
  prepayment: {
    fieldName: 'is_prepayment',
    mappingValue: (value) => (value ? '1' : '-')
  },
  direction: {},
  amount: { isAmount: true, rightAligned: true },
  payment_gate: { fieldName: 'comment' },
  /*is_accepted: {
    mappingValue: (value) => value ? 'Confirmed' : 'Waiting',
    mappingClassName: (value) => value ? 'text-success' : 'text-warning'
  },*/
  accept_date: { isDateTime: true }
}

// TODO: use rtkq only, remove `entity` and `error` props
const EntityContent = ({ style, entityId, entity, visible, error }) => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const [transactionId, setTransactionId] = useState('')

  const { data: payments, error: rtkqError } = useGetLoanPaymentsQuery(
    entityId,
    {
      skip: !visible || entityId == null
    }
  )

  const { data: paymentTypes } = useGetPaymentTypesQuery()
  const { data: userInfo } = useGetUserInfoQuery()

  const [
    setChargeback,
    { isSuccess, isError, isLoading, error: setChargebackError }
  ] = useSetChargebackMutation()

  const toggle = () => setShowModal(false)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      transaction_id: transactionId,
      chargeback_date: ''
    },
    validationSchema: Yup.object().shape({
      chargeback_date: Yup.string().required(t('No date specified'))
    }),
    onSubmit: (values) => {
      setChargeback(values)
    }
  })

  return (
    <div>
      {payments?.length ? (
        <>
          <List
            list={payments.map((item) => ({
              ...item,
              id_link: `/payment-view?id=${item.id}`,
              profile_link: `/profile-view?id=${item.profile_id}`,
              loan_link: `/loan-view?id=${item.loan_id}`
            }))}
            fieldsDescription={{
              ...omitBy(
                {
                  ...fieldsDescription,
                  payment_type: {
                    mappingValue: (value) =>
                      paymentTypes?.find((it) => it.id === value)?.name ?? '-'
                  }
                },
                // удаляем ключи из объекта, если код продукта PDL
                // (в этом продукте по смыслу этих ключей нет, но по факту они есть со значением null)
                (_, key) =>
                  entity?.product_copy?.product_code === 'PDL' &&
                  key === 'receipt_date',
                (_, key) =>
                  entity?.product_copy?.product_code === 'PDL' &&
                  key === 'prepayment',
                (_, key) =>
                  entity?.product_copy?.product_code === 'PDL' &&
                  key === 'payment_type'
              )
            }}
            style={style}
            actions={payments.map((item, rank) => (
              <div
                key={`action-${rank}`}
                className={'d-flex gap-4 align-item-center'}
              >
                {userInfo?.permissions.includes(
                  'РЕДАКТИРОВАНИЕ ТИПА ПЛАТЕЖЕЙ'
                ) &&
                  item?.payment_type === 1 &&
                  item?.is_prepayment === true &&
                  new Date(item?.accept_date).setHours(0, 0, 0, 0) >
                    new Date().setHours(0, 0, 0, 0) && (
                    <Link
                      to={`/payment-view?id=${item.id}&edit=true`}
                      color='link'
                      className='link-success fs-15'
                    >
                      <i className='ri-edit-2-line'></i>
                    </Link>
                  )}
                {item.is_accepted && item.direction === 'In' && (
                  <Button
                    size='sm'
                    onClick={() => {
                      setShowModal(true)
                      setTransactionId(item.id)
                    }}
                  >
                    {t('chargeback')}
                  </Button>
                )}
              </div>
            ))}
          />
          <div className='mt-3' />
          <MetaBar
            createLink={`/payment-create?loan_id=${entityId}&product_code=${entity?.product_copy?.product_code}`}
            disableBorder
          />
        </>
      ) : (
        <small>{t('No data')}</small>
      )}

      {(error || rtkqError) && <DangerAlert error={error || rtkqError} />}
      <Modal isOpen={showModal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>{t('Chargeback')}</ModalHeader>

        <ModalBody>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md='4'>
                <FormGroup>
                  <Label htmlFor='chargeback_date'>
                    {t('chargeback_date')}
                  </Label>
                  <Flatpickr
                    name='chargeback_date'
                    id='chargeback_date'
                    type='date'
                    className='form-control form-control-sm'
                    options={{
                      dateFormat: 'Y-m-d',
                      maxDate: new Date(),
                      onChange: (selectedDates, dateStr) => {
                        formik.setFieldValue('chargeback_date', dateStr)
                      }
                    }}
                  />
                  {formik.touched.chargeback_date &&
                  formik.errors.chargeback_date ? (
                    <FormFeedback
                      style={{ display: 'block', marginBottom: '10px' }}
                      type='invalid'
                    >
                      <Alert color='warning'>
                        <strong> {formik.errors.chargeback_date} </strong>
                      </Alert>
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>

            {isError && (
              <Alert color='warning'>
                <strong>
                  {JSON.stringify(setChargebackError.data['detail'])}
                </strong>
              </Alert>
            )}

            {isSuccess && (
              <Alert color='success'>
                <strong> {t('Operation success')} </strong>
              </Alert>
            )}

            <Button type='submit' disabled={isLoading}>
              {t('Send')}
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default EntityContent
