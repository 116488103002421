import { Card, CardBody } from 'reactstrap'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import List from 'src/Components/Common/EntitiesView/List'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'
import MetaBar from 'src/Components/Common/MetaBar'

import { useTranslation } from 'react-i18next'

import DownloadDossier from 'src/Components/Common/EntitiesView/DownloadDossier'
import { FIELDS_DESCRIPTION } from './constants/agencies.constants'
import { useGetAgenciesQuery } from '../../../services/agencies'
import { handleErrors } from 'src/utils'

export const Agencies = () => {
  const { t } = useTranslation()

  const {
    data: list,
    isLoading: listLoading,
    error: listError
  } = useGetAgenciesQuery()

  const title = t('Agencies')
  document.title = title

  if (listError) {
    if ('data' in listError) {
      const messageError = handleErrors(listError)

      return <ErrorPage title={title} error={messageError} />
    } else {
      return <ErrorPage title={title} error={listError} />
    }
  }

  if (listLoading) {
    return <LoadingPage title={title} />
  }

  const downloadDossier = <DownloadDossier />

  const metaBar = (
    <MetaBar
      createLink={'/external-agencies-edit'}
      buttons={[downloadDossier]}
    />
  )

  const bread = <BreadCrumb title={title} />

  return (
    <>
      <UiContent />
      <div className='page-content'>
        {bread}

        <Card>
          <CardBody>
            {metaBar}
            <div className='table-responsive table-card mt-3'>
              <List
                style={{ width: 'fit-content' }}
                list={list?.map((item) => ({
                  ...item,
                  id_link: `/external-agencies-view?id=${item.id}`
                }))}
                fieldsDescription={FIELDS_DESCRIPTION}
                className='table-sm align-middle table-nowrap mb-0'
                hover
              />
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  )
}
