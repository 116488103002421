import { api, omitBlankEntries } from './api'
import {
  ApplicationEntity,
  ApplicationFilters,
  LoanApplication
} from '../pages/Admin/Applications/types/applications.types'

type ApplicationVerification = {
  id: string
  request_id: string
  process: {
    pass: unknown[]
    requirement: unknown[]
    result: unknown[]
  }
}

type ApplicationsTabs = {
  id: string | number
  company: string
  created_at: string
}

export type ApplicationSignedDocs = {
  doc_id: string
  doc_name: string
  id: string
  signed_at: string
  type: string
}

type ApplicationDocuments = {
  documents: unknown[]
  total: number
}

export type ApplikationBkiList = {
  id: string
  company: string
  file_name: string
  created_at: string
  updated_at?: string | null
  request_id?: string | null
  event_name: string
  event_type?: string | null
  loan_uuid_cbr: string
}

export type ApplikationBki = {
  equifax: ApplikationBkiList[]
  nbki: ApplikationBkiList[]
}

type ApplicationLoan = {
  id: string
  short_name: string
  created_at: string
  updated_at: string
  request_id: string
  profile_id: string
  product_copy: Record<string, unknown>
  interest: string
  psk: number
  status: string
  term: number
  issue_date: string
  repayment_date: string
  closing_date: string
  amount_principal_accrued: number
  amount_principal_paid: number
  amount_interest_accrued: number
  amount_interest_paid: number
  amount_issuance_fee_accrued: number
  amount_issuance_fee_paid: number
  amount_ext_fee_accrued: number
  amount_ext_fee_paid: number
  amount_repayment_fee_accrued: number
  amount_repayment_fee_paid: number
  amount_premium_account_accrued: number
  amount_premium_account_paid: number
  amount_insurance_accrued: number
  amount_insurance_paid: number
  amount_penalty_accrued: number
  amount_penalty_paid: number
  amount_overpayment: number
  amount_writeoff: number
  prolongation_count: number
  consent_direct_debit: boolean
  last_login: string
  org_origin: string
  org_owner: string
  org_collect: string
  phone_prefix: 0
  interest_frozen_end_date: string
  penalties_frozen_end_date: string
  dpd: number
  ext_details: Record<string, unknown>
  extension_type_id: number
  product_type: string
  amount_debt: number
  amount_principal_debt: number
  amount_interest_debt: number
  amount_penalty_debt: number
  amount_premium_account_debt: number
  amount_insurance_debt: number
  amount_services_debt: number
  amount_fees_debt: number
  amount_ext_fees_debt: number
  amount_non_principal_debt: number
  amount_non_principal_debt_excl_penalty: number
  amount_non_principal_debt_excl_penalty_premium_insurance: number
  amount_non_principal_debt_excl_premium_insurance: number
  collector_fio: string
  timezone: string
  operations: unknown[]
  loan_tranches: unknown[]
}

export type CombineApplication = LoanApplication & {
  verificationHistory: unknown[]
  documents: ApplicationDocuments['documents']
  documentsSigned: ApplicationSignedDocs[]
  loan: ApplicationLoan | null
}

export type BlockReason = {
  id: number
  reason: string
}

export type BlockHistory = {
  operation_id: number
  created_at: string
  reason: string
  username: string
  comment: string
}

export type BlockRequestBody = {
  client_id: string
  reason_id?: number
  comment: string
}

export type TrafficSourceData = {
  name: string
  url_params: {
    webmaster_id?: string | number | null
    partnerapi_id?: string | null
    affiliate_id?: string | number | null
    track_id?: string | null
    is_desktop?: boolean | null
    promocode_group?: number | null
  }
}

export const {
  useGetApplicationsQuery,
  useGetSingleApplicationQuery,
  useGetApplicationVerificationQuery,
  useGetBluredOtpMutation,
  useGetApplicationsKIQuery,
  useGetApplicationsKIReportMutation,
  useGetApplicationsUPRIDQuery,
  useGetApplicationsUPRIDReportMutation,
  useApplicationAsFraudulentMutation,
  useGetDecideInfoQuery,
  useGetApplicationDocumentsQuery,
  useGetApplicationBkiEventsQuery,
  useGetApplicationLoanQuery,
  useGetApplicationSignedDocumentsQuery,
  useGetBlockReasonsQuery,
  useGetBlockHistoryQuery,
  useBlockClientMutation,
  useUnblockClientMutation,
  useGetTrafficSourcesQuery
} = api.injectEndpoints({
  endpoints: (builder) => ({
    getApplications: builder.query<
      ApplicationEntity[],
      Partial<ApplicationFilters> & { offset: number; limit: number }
    >({
      query: (params) => ({
        url: `crm/v1/applications`,
        params
      })
    }),
    getSingleApplication: builder.query<LoanApplication, string>({
      query: (id) => `crm/v1/applications/${id}`
    }),
    applicationAsFraudulent: builder.mutation<Record<string, boolean>, string>({
      query: (id) => ({
        url: `/crm/v1/applications/${id}/fraud`,
        method: 'POST'
      })
    }),
    getApplicationVerification: builder.query<ApplicationVerification, string>({
      query: (id) => `crm/v1/applications/${id}/verification`
    }),

    getBluredOtp: builder.mutation<any, any>({
      query: ({ request_id }) => ({
        url: `crm/v1/applications/${request_id}/otp`,
        method: 'GET'
      })
    }),

    getApplicationsKI: builder.query<ApplicationsTabs[], string>({
      query: (id) => `/crm/v1/applications/${id}/ki-requests`
    }),
    getApplicationsKIReport: builder.mutation<any, any>({
      query: (params) => ({
        url: `/crm/v1/applications/ki-report`,
        method: 'GET',
        params: omitBlankEntries(params),
        headers: {
          'Content-Type': 'text/plain; charset=UTF-8'
        },
        responseHandler: (response) => response.text()
      })
    }),
    getApplicationsUPRID: builder.query<ApplicationsTabs[], string>({
      query: (id) => `/crm/v1/applications/${id}/uprid-requests`
    }),
    getApplicationsUPRIDReport: builder.mutation<any, any>({
      query: (params) => ({
        url: `/crm/v1/applications/uprid-result`,
        method: 'GET',
        params: omitBlankEntries(params)
      })
    }),
    getDecideInfo: builder.query<unknown[], string>({
      query: (id) => `/crm/v1/roles/verifications/applications/${id}/decide`
    }),
    getApplicationDocuments: builder.query<ApplicationDocuments, string>({
      query: (id) => `/crm/v1/documents/form-cards/application/${id}`
    }),
    getApplicationBkiEvents: builder.query<ApplikationBki, string>({
      query: (id) => `/crm/v1/loans-bki-events/${id}/application-bki-events`
    }),
    getApplicationSignedDocuments: builder.query<
      ApplicationSignedDocs[],
      string
    >({
      query: (id) => `/crm/v1/applications/${id}/signed-documents`
    }),
    getApplicationLoan: builder.query<ApplicationLoan, string>({
      query: (id) => `/crm/v1/applications/${id}/loan`
    }),
    getBlockReasons: builder.query<BlockReason[], void>({
      query: () => `/crm/v1/applications/block-reasons`
    }),
    getBlockHistory: builder.query<BlockHistory[], { client_id: any }>({
      query: (params) => ({
        url: '/crm/v1/applications/block-history',
        params
      }),
      providesTags: ['BlockedUser']
    }),
    blockClient: builder.mutation<any, BlockRequestBody>({
      query: (body) => ({
        url: `/crm/v1/applications/block-client`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['BlockedUser']
    }),
    unblockClient: builder.mutation<any, BlockRequestBody>({
      query: (body) => ({
        url: `/crm/v1/applications/unblock-client`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['BlockedUser']
    }),
    getTrafficSources: builder.query<TrafficSourceData[], string>({
      query: (id) => `/crm/v1/applications/${id}/traffic-sources`
    })
  })
})
