import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import {
  Card,
  CardBody,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
  ButtonGroup,
  Alert
} from 'reactstrap'
import * as Yup from 'yup'
import { useGetReloadingMutation } from 'src/services/debts'
import { handleErrors } from 'src/utils'

export const Reloading = () => {
  const { t } = useTranslation()
  const [file, setFile] = useState<Blob>()

  const [
    reloading,
    { isLoading: isLoading, error: createError, isSuccess: isSuccess }
  ] = useGetReloadingMutation()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      reload_or_export: true,
      type_credit_bureau: 'Equifax',
      email: ''
    },
    validationSchema: Yup.object().shape({
      reload_or_export: Yup.boolean(),
      type_credit_bureau: Yup.string(),
      email: Yup.string().required(t('Cannot be empty'))
    }),
    onSubmit: (values) => {
      if (file) {
        const formData = new FormData()
        formData.append('file', file)
        const reqBody = {
          file: formData,
          params: values
        }
        reloading(reqBody)
      }
    }
  })

  const title = t('Reloading')
  return (
    <div className='page-content'>
      <BreadCrumb title={title} pageTitle={undefined} link={undefined} />
      <Card>
        <CardBody>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md='4'>
                <FormGroup>
                  <Label htmlFor='reload_or_export'>
                    {t('operation_type')}
                  </Label>
                  <ButtonGroup className='w-100'>
                    <Button
                      color='primary'
                      outline
                      active={formik.values.reload_or_export === true}
                      size='sm'
                      onClick={() =>
                        formik.setFieldValue('reload_or_export', true)
                      }
                      disabled={isLoading}
                    >
                      {t('Перевыгрузка')}
                    </Button>
                    <Button
                      color='primary'
                      outline
                      active={formik.values.reload_or_export === false}
                      size='sm'
                      onClick={() =>
                        formik.setFieldValue('reload_or_export', false)
                      }
                      disabled={isLoading}
                    >
                      {t('Выгрузка')}
                    </Button>
                  </ButtonGroup>
                  {formik.touched.reload_or_export &&
                  formik.errors.reload_or_export ? (
                    <FormFeedback type='invalid'>
                      {formik.errors.reload_or_export}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </Col>
              <Col md='4'>
                <FormGroup>
                  <Label htmlFor='type_credit_bureau'>{t('company')}</Label>
                  <select
                    name='type_credit_bureau'
                    id='type_credit_bureau'
                    className='form-select form-select-sm'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.type_credit_bureau ?? ''}
                    disabled={isLoading}
                  >
                    <option value='Equifax'>Эквифакс</option>
                    <option value='NBKI'>НБКИ</option>
                  </select>
                  {formik.touched.type_credit_bureau &&
                  formik.errors.type_credit_bureau ? (
                    <FormFeedback type='invalid'>
                      {formik.errors.type_credit_bureau}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </Col>
              <Col md='4'>
                <FormGroup>
                  <Label htmlFor='email'>{t('email')}</Label>
                  <Input
                    name='email'
                    type='email'
                    id='email'
                    className='form-control form-control-sm'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email ?? ''}
                    disabled={isLoading}
                    invalid={formik.touched.email && !!formik.errors.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <FormFeedback type='invalid'>
                      {formik.errors.email}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </Col>
              <Col md='4'>
                <FormGroup>
                  <Label htmlFor='upload_reloading'>{t('File Uploads')}</Label>
                  <Input
                    type='file'
                    onChange={(e) => setFile(e.target.files?.[0])}
                    name='upload_reloading'
                    id='upload_reloading'
                    accept='.csv'
                    disabled={isLoading}
                  />
                </FormGroup>
              </Col>
            </Row>
            {createError && 'data' in createError && (
              <Alert color='warning'>
                <strong> {handleErrors(createError)} </strong>
              </Alert>
            )}
            {isSuccess && (
              <Alert color='success'>
                <strong> {t('Operation success')} </strong>
              </Alert>
            )}
            <Button
              className='text-nowrap'
              color='primary'
              style={{ backgroundColor: '#405189' }}
              type='submit'
              disabled={isLoading || !file}
            >
              {t('Send')}
            </Button>
          </Form>
        </CardBody>
      </Card>
    </div>
  )
}
