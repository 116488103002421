import { LayoutItem, LayoutSubItem } from '../layoutItems.types'

export const getClaimsData = (includes_head_of_comunications: boolean) => {
  const subItems: LayoutSubItem[] = [
    {
      id: 'my-claims',
      label: 'My claims',
      link: '/my-claims'
    },
    {
      id: 'topic-setting',
      label: 'Topic setting',
      link: '/topic-setting'
    },
    {
      id: 'category-of-errors',
      label: 'category_of_errors',
      link: '/category-of-errors'
    },
    {
      id: 'claim-templates',
      label: 'Templates claim',
      link: '/claim-templates'
    },
    {
      id: 'insolvent',
      label: 'Insolvent',
      icon: 'ri-aliens-line',
      link: '/insolvent'
    }
  ]

  if (includes_head_of_comunications) {
    subItems.push(
      ...[
        {
          id: 'claims-queue',
          label: 'Queue claims',
          link: '/claims-queue'
        },
        {
          id: 'claims-report',
          label: 'Report on appeals',
          link: '/claims-report'
        }
      ]
    )
  }

  const claimsData: LayoutItem = {
    id: 'claims',
    label: 'Claims',
    icon: 'mdi mdi-message-badge-outline',
    subItems
  }

  return claimsData
}
