import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import SimpleBar from 'simplebar-react'
import logoSmDark from '../assets/images/logo/logo-img-dark.png'
import logoSmLight from '../assets/images/logo/logo-img-light.png'
import logoDark from '../assets/images/logo/logo-text-dark.png'
import logoLight from '../assets/images/logo/logo-text-light.png'
import { Container } from 'reactstrap'
import { VerticalLayout } from './VerticalLayouts/VerticalLayout'

const Sidebar = () => {
  useEffect(() => {
    let verticalOverlay = document.getElementsByClassName('vertical-overlay')
    if (verticalOverlay) {
      verticalOverlay[0].addEventListener('click', function () {
        document.body.classList.remove('vertical-sidebar-enable')
      })
    }
  })

  const addEventListenerOnSmHoverMenu = () => {
    // add listener Sidebar Hover icon on change layout from setting
    if (
      document.documentElement.getAttribute('data-sidebar-size') === 'sm-hover'
    ) {
      document.documentElement.setAttribute(
        'data-sidebar-size',
        'sm-hover-active'
      )
    } else if (
      document.documentElement.getAttribute('data-sidebar-size') ===
      'sm-hover-active'
    ) {
      document.documentElement.setAttribute('data-sidebar-size', 'sm-hover')
    } else {
      document.documentElement.setAttribute('data-sidebar-size', 'sm-hover')
    }
  }

  return (
    <React.Fragment>
      <div
        className='app-menu navbar-menu'
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <div className='navbar-brand-box'>
          <Link to='/' className='logo logo-dark'>
            <span className='logo-sm'>
              <img src={logoSmDark} alt='' height='50' />
            </span>
            <span className='logo-lg'>
              <img src={logoSmDark} alt='' height='50' className='mt-3' />
              <img src={logoDark} alt='' height='33' />
            </span>
          </Link>
          <Link to='/' className='logo logo-light'>
            <span className='logo-sm'>
              <img src={logoSmLight} alt='' height='50' />
            </span>
            <span className='logo-lg'>
              <img src={logoSmLight} alt='' height='50' className='mt-3' />
              <img src={logoLight} alt='' height='33' />
            </span>
          </Link>
          <button
            onClick={addEventListenerOnSmHoverMenu}
            type='button'
            className='btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover'
            id='vertical-hover'
          >
            <i className='ri-record-circle-line'></i>
          </button>
        </div>
        <SimpleBar
          className='h-100'
          style={{ maxHeight: 'calc(100vh - 140px)' }}
        >
          <Container fluid>
            <div id='two-column-menu'></div>
            <VerticalLayout />
          </Container>
        </SimpleBar>
        <div className='sidebar-background'></div>
      </div>
      <div className='vertical-overlay'></div>
    </React.Fragment>
  )
}

export default Sidebar
