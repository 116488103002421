import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ApplikationBkiList,
  useGetApplicationBkiEventsQuery,
  useGetApplicationsKIReportMutation
} from 'src/services/applications'
import { DangerAlert } from 'src/pages/Admin/ErrorPage'
import List from './List'

type ApplicationsKIProps = {
  applicationId: string
  style: any
}

const fieldsDescription = {
  company: {},
  created_at: { isDateTime: true },
  file_name: {},
  event_name: {},
  loan_uuid_cbr: {}
}

export const ApplicationsKI: FC<ApplicationsKIProps> = ({
  applicationId,
  style
}) => {
  const { t } = useTranslation()
  const [items, setItems] = useState<ApplikationBkiList[] | null>([])

  const { data: KiListDoc, error } = useGetApplicationBkiEventsQuery(
    applicationId,
    {
      skip: applicationId == null
    }
  )

  const [KIReport] = useGetApplicationsKIReportMutation()

  const showXml = async (doc: any) => {
    KIReport({ report_id: doc.id, company_name: doc.company }).then(
      (response: any) => {
        try {
          const blob = new Blob([response.data], {
            type: 'text/plain; charset=UTF-8'
          })
          const url = URL.createObjectURL(blob)
          const newWindow = window.open(url, '_blank')
          newWindow?.addEventListener('unload', () => {
            URL.revokeObjectURL(url)
          })
        } catch (error) {
          console.error('Error parsing XML:', error)
        }
      }
    )
  }

  useEffect(() => {
    const updatedItems = Object.values(KiListDoc || {})
      .flat()
      .sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      )

    setItems(updatedItems)
  }, [KiListDoc])

  if (!items?.length)
    return (
      <div style={style}>
        <dl className='row mb-0'>
          <small>{t('No_signed_documents')}</small>
        </dl>
      </div>
    )

  return (
    <>
      {error ? <DangerAlert error={error} /> : null}
      {KiListDoc ? (
        <List
          hover
          list={items?.map((doc) => ({
            ...doc,
            onPress: () => showXml(doc)
          }))}
          fieldsDescription={fieldsDescription}
          style={style}
          // @ts-expect-error TODO: check if this property is used at all
          onRowPress={showXml}
        />
      ) : null}
    </>
  )
}
