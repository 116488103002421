import { LayoutItem, LayoutSubItem } from '../layoutItems.types'

export const getApplicationsData = () => {
  const subItems: LayoutSubItem[] = [
    {
      id: 'applications-all',
      label: 'View all',
      link: '/applications'
    },
    {
      id: 'application-view',
      link: '/application-view',
      hidden: true,
      label: 'View'
    }
  ]

  const applicationsData: LayoutItem = {
    id: 'applications',
    label: 'Applications',
    icon: 'ri-file-edit-line',
    subItems
  }

  return applicationsData
}
