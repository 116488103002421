import ReactApexChart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'

import { formatAmount } from '../../../utils'

function getChartColorsArray(colors) {
  colors = JSON.parse(colors)
  return colors.map(function (value) {
    let newValue = value.replace(' ', '')
    if (newValue.indexOf(',') === -1) {
      let color = getComputedStyle(document.documentElement).getPropertyValue(
        newValue
      )
      if (color.indexOf('#') !== -1) color = color.replace(' ', '')
      if (color) return color
      else return newValue
    } else {
      let val = value.split(',')
      if (val.length === 2) {
        let rgbaColor = getComputedStyle(
          document.documentElement
        ).getPropertyValue(val[0])
        rgbaColor = 'rgba(' + rgbaColor + ',' + val[1] + ')'
        return rgbaColor
      } else {
        return newValue
      }
    }
  })
}

const CircleChart = ({ dataColors, series, labels, total, loanId }) => {
  const { t } = useTranslation()

  const donutchartportfolioColors = getChartColorsArray(dataColors)

  let options = {
    labels: labels || ['Bitcoin', 'Ethereum', 'Litecoin', 'Dash'],
    chart: {
      type: 'donut',
      height: 224
    },

    plotOptions: {
      pie: {
        size: 100,
        offsetX: 0,
        offsetY: 0,
        donut: {
          size: '70%',
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '18px',
              offsetY: -5
            },
            value: {
              show: true,
              fontSize: '20px',
              color: '#363d48',
              fontWeight: 500,
              offsetY: 5,
              formatter: function (val) {
                return formatAmount(val)
              }
            },
            total: {
              show: true,
              fontSize: '11px',
              label: t('amount_debt'),
              color: '#9599ad',
              fontWeight: 500,
              formatter: function () {
                return formatAmount(total)
              }
            }
          }
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return formatAmount(value)
        }
      }
    },
    stroke: {
      lineCap: 'round',
      width: 2
    },
    colors: donutchartportfolioColors
  }
  return (
    <>
      <ReactApexChart
        key={loanId}
        options={options}
        series={series || [19405, 40552, 15824, 30635]}
        type='donut'
        height='210'
        className='apex-charts'
      />
    </>
  )
}

export default CircleChart
