import { LayoutItem, LayoutSubItem } from '../layoutItems.types'

export const getLoansData = () => {
  const subItems: LayoutSubItem[] = [
    {
      id: 'loans-all',
      label: 'View all',
      link: '/loans'
    },
    {
      id: 'loan-holidays',
      label: 'Holidays',
      link: '/loan-holidays'
    },
    {
      id: 'loan-view',
      link: '/loan-view',
      hidden: true,
      label: 'View'
    }
  ]

  const loansData: LayoutItem = {
    id: 'loans',
    label: 'Loans',
    icon: 'ri-pie-chart-line',
    subItems
  }

  return loansData
}
