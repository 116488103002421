import { LayoutItem, LayoutSubItem } from '../layoutItems.types'

export const getChatsData = () => {
  const subItems: LayoutSubItem[] = [
    {
      id: 'my-chats',
      label: 'My chats',
      link: '/chats/my'
    },
    {
      id: 'chats-templates',
      label: 'Chat templates',
      link: '/chats/templates'
    },
    {
      id: 'chats-topics',
      label: 'Chat topics',
      link: '/chats/topics'
    },
    {
      id: 'chats-settings-global',
      label: 'Chat global settings',
      link: '/chats/settings/global'
    },
    {
      id: 'chats-settings',
      label: 'Chat user settings',
      link: '/chats/settings'
    }
  ]

  const chatsData: LayoutItem = {
    id: 'chats',
    label: 'Chats',
    icon: 'bx bxs-chat',
    subItems
  }

  return chatsData
}
