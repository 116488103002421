import { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Alert,
  Modal,
  ModalBody,
  ModalHeader,
  ListGroupItem,
  FormFeedback
} from 'reactstrap'

import {
  mapStatusToClassName,
  mapStatusToName
} from 'src/Components/Common/EntitiesView/Communications'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import MetaBar from 'src/Components/Common/MetaBar'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'
import { ClaimResponseView } from './ClaimResponseView'
import List from 'src/Components/Common/EntitiesView/List'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import { autoResizeInput } from 'src/utils'

import {
  useGetClaimQuery,
  useGetTemplatesEmailQuery,
  useSetSendEmailMutation,
  useSetUploadAttachmentMutation,
  useGetMessagesClaimQuery,
  useSetPreviewDocxMutation
} from 'src/services/claims'

import { UseQueryHookResultData } from 'src/services/api'
import { useGetTextTemplatesQuery } from 'src/services/templatesClaim'
import type { SendEmail, UploadAttachment } from 'src/services/claims'
import { SelectSearch } from './Components/select'

export const ClaimResponse: FC = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const [templatesList, setTemplatesList] = useState('-')
  const [templateFile, setTemplateFile] = useState<File>()
  const [templateTopic, setTemplateTopic] = useState('')
  const [templateResponse, setTemplateResponse] = useState<UploadAttachment[]>(
    []
  )
  const [claimChildList, setClaimChildList] = useState<string>('-')
  const [claimChildView, setClaimChildView] = useState(false)

  const {
    data: claim,
    isError,
    error
  } = useGetClaimQuery(
    {
      id: searchParams.get('id')!
    },
    {
      skip: searchParams.get('id') == null
    }
  )

  const [uploadAttachment] = useSetUploadAttachmentMutation()

  const [
    sendEmail,
    {
      isLoading: isSendEmailLoading,
      error: sendEmailError,
      isSuccess: isSendEmailSuccess
    }
  ] = useSetSendEmailMutation()

  const { data: templatesClaim } = useGetTextTemplatesQuery({})

  const { data: templatesEmail } = useGetTemplatesEmailQuery([])

  const { data: messages } = useGetMessagesClaimQuery(
    {
      claim_id: searchParams.get('id')!,
      params: { includeProxy: !claim?.parent_claim_uuid }
    },
    { skip: searchParams.get('id')! == null }
  )

  const [showModal, setShowModal] = useState(false)
  const [historyClaim, setHistoryClaim] =
    useState<UseQueryHookResultData<typeof useGetMessagesClaimQuery>[0]>()
  const toggle = () => setShowModal(false)

  const handleHistoryClaimClick = useCallback(
    (listItem: UseQueryHookResultData<typeof useGetMessagesClaimQuery>[0]) => {
      setHistoryClaim(listItem)
      setShowModal(true)
    },
    []
  )

  const [previewDocx, { error: previewDocxError }] = useSetPreviewDocxMutation()

  const addFile = (claimId: string, file: any) => {
    const inputFile = new FormData()
    inputFile.append('file', file)
    //@ts-ignore
    uploadAttachment({ id: claimId, file: inputFile }).then((resolve): void => {
      if ('data' in resolve) {
        if (templateResponse?.length) {
          const newListResponse = [...templateResponse, resolve.data]
          setTemplateResponse(newListResponse)
        } else setTemplateResponse([resolve.data])
      }
    })
  }

  const downloadFile = (id: string, body: string) => {
    previewDocx({ id: id, body: { template_name: body } }).then((blob) => {
      if ('data' in blob) {
        const url = window.URL.createObjectURL(new Blob([blob.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${body}.docx`)
        document.body.appendChild(link)
        link.click()
        link.remove()
      }
    })
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: '',
      text: '',
      templates_name_list: [],
      id: claim?.id,
      autoassign: claim?.autoassign,
      attachments: [],
      result: '',
      custom_email: '',
      proxy_claims: [],
      answer_type: ''
    } as SendEmail,
    validationSchema: Yup.object().shape({
      title: Yup.string().nullable(),
      text: Yup.string().nullable(),
      autoassign: Yup.boolean(),
      templates_name_list: Yup.array(),
      proxy_claims: Yup.array(),
      result: Yup.string().nullable(),
      answer_type: Yup.string().nullable(),
      custom_email: Yup.string().when('answer_type', {
        is: 'email',
        then: Yup.string().email().required(t('Field is required')),
        otherwise: Yup.string().email().nullable()
      })
    }),
    onSubmit: (values) => {
      if (claim?.parent_claim_uuid) {
        const dataSendEmail = {
          title: values.title,
          text: values.text,
          templates_name_list: values.templates_name_list,
          attachments: templateResponse,
          custom_email: values.custom_email,
          proxy_claims: [],
          result: values.result === '-' ? null : values.result,
          answer_type: values.answer_type === '-' ? null : values.answer_type
        }
        if (claim) sendEmail({ id: claim.id, body: dataSendEmail })
      } else {
        values.proxy_claims?.forEach((el) => {
          //@ts-ignore
          if (el.result === '-') el.result = null
        })
        const dataSendEmail = {
          title: values.title,
          text: values.text,
          templates_name_list: values.templates_name_list,
          attachments: templateResponse,
          proxy_claims: values.proxy_claims,
          custom_email: values.custom_email,
          result: values.result === '-' ? null : values.result,
          answer_type: values.answer_type === '-' ? null : values.answer_type
        }
        if (claim) sendEmail({ id: claim.id, body: dataSendEmail })
      }
    }
  })

  const title = t('Response claim')
  document.title = title

  const methodAnswer = useMemo(() => {
    if (!claim) return ''

    switch (claim.answer_type) {
      case 'email':
        return 'E-mail'
      case 'mail':
        return t('pochta_rf')
      case 'phone':
        return t('phone')
      default:
        return ''
    }
  }, [claim, t])

  if (isError) {
    return <ErrorPage backLink={'/products'} title={title} error={error} />
  }

  if (!claim || !messages) {
    return <LoadingPage backLink={'/products'} title={title} />
  }

  return (
    <div className='page-content'>
      <BreadCrumb title={title} pageTitle={undefined} link={undefined} />
      <Card>
        <CardBody>
          <MetaBar
            backLink={
              claim.channel_type === 'proxy'
                ? `/claim-view?id=${claim.id}`
                : `/main-claim-view?id=${claim.id}`
            }
            editLink={undefined}
            entity={undefined}
            createLink={undefined}
            buttons={undefined}
            disableBorder={undefined}
          />
          <h4>{t('Client request')}</h4>
          <Row>
            <ListGroupItem>
              <Row>
                <Col>{t('claim_topic')}</Col>
                <Col>{claim.claim_topic}</Col>
              </Row>
            </ListGroupItem>

            <ListGroupItem>
              <Row>
                <Col>{t('claim_body')}</Col>
                <Col>{claim.claim_body}</Col>
              </Row>
            </ListGroupItem>

            <ListGroupItem>
              <Row>
                <Col>{t('client_email')}</Col>
                <Col>{claim.client_email}</Col>
              </Row>
            </ListGroupItem>

            <ListGroupItem>
              <Row>
                <Col>{t('email_from')}</Col>
                <Col>{claim.email_from}</Col>
              </Row>
            </ListGroupItem>

            <ListGroupItem>
              <Row>
                <Col>{t('method_answer')}</Col>
                <Col>{methodAnswer}</Col>
              </Row>
            </ListGroupItem>
          </Row>
          <h4 className='mt-4'>{t('Response claim')}</h4>
          <Form onSubmit={formik.handleSubmit}>
            {!claim.parent_claim_uuid ? (
              <Row>
                <Col md='7'>
                  <FormGroup>
                    <Label htmlFor='claimChild'>
                      {t('choosing a topic to answer')}
                    </Label>
                    <div className='d-flex flex-row gap-2 align-items-center'>
                      <select
                        name='claimChild'
                        id='claimChild'
                        className='form-select form-select-sm'
                        onChange={(e) => setClaimChildList(e.target.value)}
                        onBlur={formik.handleBlur}
                        value={claimChildList}
                      >
                        <option value='-'>-</option>
                        {claim.proxy_claims
                          ?.filter((el) => el.status !== 'Закрыто')
                          .map((claim) => {
                            return (
                              <option key={claim.id} value={claim.id}>
                                {claim.claim_subtopic_name}
                              </option>
                            )
                          })}
                      </select>
                      <div
                        role='button'
                        className='text-decoration-underline'
                        onClick={() => {
                          if (!claimChildList || claimChildList === '-') return
                          const newList = []

                          newList.push(...formik.values.proxy_claims, {
                            id: claimChildList,
                            result: null
                          })
                          formik.setFieldValue('proxy_claims', newList)
                          setClaimChildView(true)
                        }}
                      >
                        <small>{t('Add')}</small>
                      </div>
                    </div>
                    <div className='mt-2 mx-2'>
                      {claimChildView
                        ? formik.values.proxy_claims.map((item, index) => {
                            const itemName = claim.proxy_claims?.filter(
                              (el) => el.id === item.id
                            )
                            return (
                              <div
                                className='d-flex flex-row gap-2 align-items-center'
                                key={item.id}
                              >
                                <div style={{ width: 'auto' }}>
                                  {itemName
                                    ? itemName[0].claim_subtopic_name
                                    : item.id}
                                </div>
                                <div
                                  role='button'
                                  className='text-decoration-underline'
                                  onClick={() => {
                                    const newList =
                                      formik.values.proxy_claims.filter(
                                        (v) => v.id !== item.id
                                      )
                                    formik.setFieldValue(
                                      'proxy_claims',
                                      newList
                                    )
                                  }}
                                >
                                  <small>{t('Remove')}</small>
                                </div>
                                <Col md='4'>
                                  <FormGroup>
                                    <Label
                                      htmlFor={`proxy_claims[${index}].result`}
                                    >
                                      {t('result_claim')}
                                    </Label>
                                    <select
                                      name={`proxy_claims[${index}].result`}
                                      id={`proxy_claims[${index}].result`}
                                      className='form-select form-select-sm'
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          `proxy_claims[${index}].result`,
                                          e.target.value
                                        )
                                      }}
                                      onBlur={formik.handleBlur}
                                      value={
                                        formik.values.proxy_claims[index]
                                          ?.result ?? ''
                                      }
                                      disabled={false}
                                    >
                                      <option value='-'>-</option>
                                      <option value='Удовлетворено'>
                                        Удовлетворено
                                      </option>
                                      <option value='Не удовлетворено'>
                                        Не удовлетворено
                                      </option>
                                      <option value='Частично удовлетворено'>
                                        Частично удовлетворено
                                      </option>
                                    </select>
                                  </FormGroup>
                                </Col>
                              </div>
                            )
                          })
                        : null}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            ) : null}

            <Row>
              <Col md='4'>
                <FormGroup>
                  <Label htmlFor='title'>{t('claim_topic')}</Label>
                  <Input
                    name='title'
                    type='text'
                    id='title'
                    className='form-control form-control-sm'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.title || ''}
                  />
                </FormGroup>
              </Col>

              <Col md='4'>
                <Label for='templates_name_list'>{t('templates')}</Label>
                <div className='d-flex flex-row gap-2 align-items-center'>
                  <select
                    name='templates_name_list'
                    id='templates_name_list'
                    className='form-select form-select-sm'
                    onChange={(e) => setTemplatesList(e.target.value)}
                    value={templatesList}
                  >
                    <option value=''>-</option>
                    {templatesEmail?.templates?.map((el) => (
                      <option key={el} value={el}>
                        {el}
                      </option>
                    ))}
                  </select>
                  <div
                    role='button'
                    className='text-decoration-underline'
                    onClick={() => {
                      if (!templatesList || templatesList === '-') return

                      if (
                        formik.values.templates_name_list.includes(
                          //@ts-ignore
                          templatesList
                        )
                      )
                        return
                      const newList = formik.values.templates_name_list
                        ? [...formik.values.templates_name_list, templatesList]
                        : []
                      formik.setFieldValue('templates_name_list', newList)
                    }}
                  >
                    <small>{t('Add')}</small>
                  </div>
                </div>
                <div className='mt-2 mx-2'>
                  {formik.values.templates_name_list
                    ? formik.values.templates_name_list.map((item) => {
                        return (
                          <div className='d-flex flex-row gap-2' key={item}>
                            <div style={{ width: 'auto' }}>{item}</div>
                            <div
                              role='button'
                              className='text-decoration-underline'
                              onClick={() => {
                                const newList =
                                  formik.values.templates_name_list.filter(
                                    (v) => v !== item
                                  )
                                formik.setFieldValue(
                                  'templates_name_list',
                                  newList
                                )
                              }}
                            >
                              <small>{t('Remove')}</small>
                            </div>
                            <div
                              role='button'
                              className='text-decoration-underline'
                              onClick={() => downloadFile(claim.id, item)}
                            >
                              <small>{t('Download')}</small>
                            </div>
                          </div>
                        )
                      })
                    : null}
                </div>
              </Col>

              <Col
                md='4'
                className='gap-2'
                style={{
                  display: 'flex',
                  alignItems: 'flex-start'
                }}
              >
                <FormGroup>
                  <Label htmlFor='template_file'>{t('template_file')}</Label>
                  <Input
                    type='file'
                    onChange={(e) => setTemplateFile(e.target.files?.[0])}
                    name='template_file'
                    id='template_file'
                    className='form-control form-control-sm'
                  />
                  <div className='mt-2 mx-2'>
                    {templateResponse?.map((file) => (
                      <div
                        className='d-flex flex-row gap-2'
                        key={file.file_path}
                      >
                        <div style={{ width: 'auto' }}>{file.file_name}</div>
                        <div
                          role='button'
                          className='text-decoration-underline'
                          onClick={() => {
                            const newList = templateResponse.filter(
                              (el) => el.file_name !== file.file_name
                            )
                            setTemplateResponse(newList)
                          }}
                        >
                          <small>{t('Remove')}</small>
                        </div>
                      </div>
                    ))}
                  </div>
                </FormGroup>
                <Button
                  className='text-nowrap'
                  color='primary'
                  style={{ backgroundColor: '#405189', marginTop: '20px' }}
                  type='button'
                  onClick={() => addFile(claim.id, templateFile)}
                  disabled={!templateFile}
                >
                  {t('attach file')}
                </Button>
              </Col>
              {previewDocxError && (
                <Alert className='mt-3 mb-3' color='warning'>
                  <strong>{JSON.stringify(previewDocxError)}</strong>
                </Alert>
              )}
              <Col md='4'>
                <FormGroup>
                  <Label htmlFor='text'>{t('claim_body')}</Label>
                  <Input
                    name='text'
                    type='textarea'
                    style={{ height: '150px' }}
                    onInput={autoResizeInput}
                    id='text'
                    className='form-control form-control-sm'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.text || ''}
                  />
                </FormGroup>
              </Col>

              <Col md='4'>
                <Label htmlFor='template_name_list'>{t('template')}</Label>
                <div className='d-flex flex-row gap-2'>
                  <SelectSearch
                    setTemplateTopic={setTemplateTopic}
                    templatesClaim={templatesClaim}
                  />
                  <div
                    role='button'
                    className='text-decoration-underline'
                    onClick={() => formik.setFieldValue('text', templateTopic)}
                  >
                    <small>{t('Add')}</small>
                  </div>
                </div>
              </Col>

              {claim.parent_claim_uuid && (
                <Col md='4'>
                  <FormGroup>
                    <Label htmlFor='result'>{t('result_claim')}</Label>
                    <select
                      name='result'
                      id='result_claim'
                      className='form-select form-select-sm'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.result || ''}
                      disabled={false}
                    >
                      <option value='-'>-</option>
                      <option value='Удовлетворено'>Удовлетворено</option>
                      <option value='Не удовлетворено'>Не удовлетворено</option>
                      <option value='Частично удовлетворено'>
                        Частично удовлетворено
                      </option>
                    </select>
                  </FormGroup>
                </Col>
              )}

              <Col md='4'>
                <FormGroup>
                  <Label htmlFor='answer_type'>{t('method_answer')}</Label>
                  <select
                    name='answer_type'
                    id='answer_type'
                    className='form-select form-select-sm'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.answer_type || ''}
                    disabled={false}
                  >
                    <option value='-'>-</option>
                    <option value='email'>E-mail</option>
                    <option value='mail'>{t('pochta_rf')}</option>
                    <option value='phone'>{t('phone')}</option>
                  </select>
                </FormGroup>
              </Col>

              <Col md='4'>
                <FormGroup>
                  <Label htmlFor='custom_email'>{t('email')}</Label>
                  <Input
                    name='custom_email'
                    type='text'
                    id='custom_email'
                    className='form-control form-control-sm'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.custom_email || ''}
                    invalid={!!formik.errors.custom_email}
                  />
                  <FormFeedback>{formik.errors.custom_email}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>

            {sendEmailError && (
              <Alert color='warning'>
                <strong>{JSON.stringify(sendEmailError)}</strong>
              </Alert>
            )}

            {isSendEmailSuccess && (
              <Alert color='success'>
                <strong> {t('Operation success')}</strong>
              </Alert>
            )}

            <Button
              className='text-nowrap'
              color='primary'
              style={{ backgroundColor: '#405189' }}
              type='submit'
              disabled={
                isSendEmailLoading ||
                !formik.values.text ||
                !formik.values.title ||
                (!claim.parent_claim_uuid
                  ? !formik.values.proxy_claims.length
                  : false)
              }
            >
              {t('Send')}
            </Button>
          </Form>
          <div className='mt-3'>
            <h4>{t('Response history')}</h4>
          </div>
          {messages.length ? (
            <List
              list={messages.map((message) => {
                return {
                  ...message
                }
              })}
              className={undefined}
              style={{ width: 'fit-content' }}
              fieldsDescription={{
                id: {
                  isButton: true,
                  onClick: handleHistoryClaimClick
                },
                response_code: {
                  label: 'status',
                  mappingValue: (value: number) =>
                    t(mapStatusToName(`${value}`)),
                  mappingClassName: (value: number) =>
                    mapStatusToClassName(`${value}`)
                },
                email: {},
                subject: {},
                text: { label: 'text_response' },
                author_username: { label: 'assignee_username' },
                created_at: { isDate: true }
              }}
              hover
              actions={undefined}
            />
          ) : (
            <div>{t('No data available')}</div>
          )}

          <Modal isOpen={showModal} toggle={toggle} centered>
            <ModalHeader toggle={toggle}>{t('Response history')}</ModalHeader>

            <ModalBody>
              <ClaimResponseView messages={historyClaim} />
            </ModalBody>
          </Modal>
        </CardBody>
      </Card>
    </div>
  )
}
