import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'src/store'
import { getLayoutMenuData } from '../layoutMenuData'
import { useTranslation } from 'react-i18next'
import { VerticalLayoutItem } from './VerticalLayoutItem'

const VerticalLayout = () => {
  const { t } = useTranslation()
  const userInfo = useSelector((state) => state.Login.userInfo)
  const [openedItemId, setOpenedItemId] = useState<string>('')

  const layoutMenuData = useMemo(
    () => getLayoutMenuData(userInfo?.roles || []),
    [userInfo]
  )

  const handleOpened = useCallback(
    (itemId: string) =>
      setOpenedItemId((prevItemId) => (prevItemId === itemId ? '' : itemId)),
    []
  )

  return (
    <ul className='navbar-nav' id='navbar-nav'>
      <li className='menu-title'>
        <span data-key='t-menu'>{t('Menu')}</span>
      </li>
      {layoutMenuData.map((item) => (
        <VerticalLayoutItem
          onToggle={handleOpened}
          isOpened={openedItemId === item.id}
          item={item}
          key={item.id}
        />
      ))}
    </ul>
  )
}

export { VerticalLayout }
