import { useFormik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  ButtonGroup,
  FormFeedback
} from 'reactstrap'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import MetaBar from 'src/Components/Common/MetaBar'
import * as Yup from 'yup'
import { autoResizeInput } from 'src/utils'

import {
  useAddMainClaimTopicMutation,
  useAddMainCliamForceTopicMutation,
  useGetAllTopicQuery,
  useGetAssignClientMutation,
  useGetClaimQuery,
  useGetErrorCategoriesQuery,
  useSetFraudCommitMutation,
  useUpdateClaimMutation
} from 'src/services/claims'
import { UseQueryHookResultData } from 'src/services/api'

export const EditMainClaim = () => {
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()
  const [formikInitial, setFormikInitial] = useState({
    topics: [
      {
        repeated: false,
        is_complaint: false,
        topic_id: 0,
        subtopic_id: 0,
        error_category_id: 0 as number | null,
        error_subcategory_id: 0 as number | null,
        comment: ''
      }
    ]
  })

  const { data: claim, isFetching } = useGetClaimQuery(
    {
      id: searchParams.get('id')!
    },
    {
      skip: searchParams.get('id') == null
    }
  )

  const { data: errorCategories } = useGetErrorCategoriesQuery()

  const { data: topics, error: topicError } = useGetAllTopicQuery([])

  const [
    assingTopic,
    {
      isLoading: isLoadingAssing,
      error: createErrorAssing,
      isSuccess: isSuccessAssing
    }
  ] = useAddMainClaimTopicMutation()

  const [
    forceTopic,
    {
      isLoading: isLoadingForceTopic,
      error: createErrorForceTopic,
      isSuccess: isSuccessForceTopic
    }
  ] = useAddMainCliamForceTopicMutation()

  const [
    updateClaim,
    { isLoading: isUpdating, error: updateError, isSuccess: isUpdateSuccess }
  ] = useUpdateClaimMutation()

  const [
    fraudCommit,
    {
      isLoading: isUpdatingFraud,
      error: updateErrorFraud,
      isSuccess: isUpdateSuccessFraud
    }
  ] = useSetFraudCommitMutation()

  const [
    assignClient,
    {
      isLoading: isUpdatingAssignClient,
      error: updateErrorAssignClient,
      isSuccess: isUpdateSuccessAssignClient
    }
  ] = useGetAssignClientMutation({})

  const addTopicForm = () => {
    const newFormiklInitial: any = {
      topics: [
        ...formik.values.topics,
        {
          repeated: false,
          is_complaint: false,
          topic_id: 0,
          subtopic_id: 0,
          error_category_id: 0,
          error_subcategory_id: 0,
          comment: ''
        }
      ]
    }
    setFormikInitial(newFormiklInitial)
  }

  const deleteTopicForm = () => {
    formikInitial.topics.pop()
    const newFormiklInitial: any = {
      topics: [...formikInitial.topics]
    }
    setFormikInitial(newFormiklInitial)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitial,
    validationSchema: Yup.object().shape({
      claim_topic_id: Yup.number().nullable(),
      claim_subtopic_id: Yup.number().nullable(),
      comment: Yup.string().nullable(),
      repeated: Yup.string(),
      is_complaint: Yup.string(),
      error_category_id: Yup.number().nullable(),
      error_subcategory_id: Yup.number().nullable()
    }),
    onSubmit: (values) => {
      values.topics.forEach((el) => {
        //@ts-ignore
        if (el.repeated === 'true') el.repeated = true
        else el.repeated = false
        //@ts-ignore
        if (el.is_complaint === 'true') el.is_complaint = true
        else el.is_complaint = false

        if (el.error_category_id === 0) {
          el.error_category_id = null
        }
        if (el.error_subcategory_id === 0) {
          el.error_subcategory_id = null
        }
      })

      if (claim) {
        if (claim.status !== 'Получено') {
          forceTopic({ id: claim.id, body: values })
        } else {
          assingTopic({ id: claim.id, body: values })
        }
      }
    }
  })

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      comment: '',
      result: '',
      autoassign: false,
      ...claim
    } as UseQueryHookResultData<typeof useGetClaimQuery>,
    validationSchema: Yup.object().shape({
      comment: Yup.string().nullable(),
      autoassign: Yup.boolean(),
      result: Yup.string()
    }),
    onSubmit: (values) => {
      if (searchParams.get('id')) values.autoassign = false
      if (values.result === '-') values.result = null
      updateClaim(values)
    }
  })

  const formikClient = useFormik({
    enableReinitialize: true,
    initialValues: {
      client_id: '',
      ...claim
    },
    validationSchema: Yup.object().shape({
      client_id: Yup.string().nullable()
    }),
    onSubmit: (values) => {
      const data = {
        client_id: values.client_id
      }
      if (claim) {
        assignClient({ claim_id: claim?.id, params: data })
      }
    }
  })

  const formikFraud = useFormik({
    enableReinitialize: true,
    initialValues: {
      fraud_comment: '',
      is_fraud: false,
      ...claim
    },
    validationSchema: Yup.object().shape({
      fraud_comment: Yup.string().nullable(),
      is_fraud: Yup.boolean()
    }),
    onSubmit: (values) => {
      const data = {
        fraud_comment: values.fraud_comment,
        is_fraud: values.is_fraud
      }
      if (claim) {
        fraudCommit({ id: claim?.id, params: data })
      }
    }
  })

  const title = t('Edit claim')
  document.title = title

  return (
    <div className='page-content'>
      <BreadCrumb title={title} pageTitle={undefined} link={undefined} />
      <Card>
        <CardBody>
          <MetaBar
            backLink={
              searchParams.get('id')
                ? `/main-claim-view?id=${claim?.id}`
                : '/claims-queue'
            }
            editLink={undefined}
            entity={claim}
            createLink={undefined}
            buttons={undefined}
            disableBorder={undefined}
          />

          <h4>{t('Fraud test result')}</h4>
          <Form onSubmit={formikFraud.handleSubmit}>
            <Row>
              <Col className='col-3'>
                <FormGroup className='mb-3'>
                  <Label htmlFor='is_fraud'>{t('Mark as fraudulent')}</Label>
                  <ButtonGroup className='w-100'>
                    <Button
                      color='primary'
                      outline
                      active={formikFraud.values.is_fraud === true}
                      size='sm'
                      onClick={() =>
                        formikFraud.setFieldValue('is_fraud', true)
                      }
                      disabled={isUpdatingFraud}
                    >
                      {t('yes')}
                    </Button>
                    <Button
                      color='primary'
                      outline
                      active={formikFraud.values.is_fraud === false}
                      size='sm'
                      onClick={() =>
                        formikFraud.setFieldValue('is_fraud', false)
                      }
                      disabled={isUpdatingFraud}
                    >
                      {t('no')}
                    </Button>
                  </ButtonGroup>
                  {formikFraud.touched.is_fraud &&
                  formikFraud.errors.is_fraud ? (
                    <FormFeedback type='invalid'>
                      {formikFraud.errors.is_fraud}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </Col>
              <Col className='col-4'>
                <Label htmlFor='fraud_comment'>{t('fraud_comment')}</Label>
                <Input
                  name='fraud_comment'
                  type='text'
                  id='fraud_comment'
                  className='form-control form-control-sm'
                  onChange={formikFraud.handleChange}
                  onBlur={formikFraud.handleBlur}
                  value={formikFraud.values.fraud_comment || ''}
                  disabled={isUpdatingFraud}
                />
              </Col>
            </Row>

            {updateErrorFraud && (
              <Alert color='warning'>
                <strong> {JSON.stringify(updateErrorFraud)} </strong>
              </Alert>
            )}

            {isUpdateSuccessFraud && (
              <Alert color='success'>
                <strong> {t('Operation success')} </strong>
              </Alert>
            )}

            <Button
              type='submit'
              color='primary'
              style={{ backgroundColor: '#405189' }}
              disabled={
                !formikFraud.values.fraud_comment ||
                formikFraud.values.is_fraud == null ||
                isUpdatingFraud
              }
            >
              {t('Send')}
            </Button>
          </Form>

          <h4 className='mt-3'>{t('Edit')}</h4>
          <Row>
            <Col md='7'>
              <Form onSubmit={validation.handleSubmit}>
                <Row>
                  <Col md='8'>
                    <Label htmlFor='comment'>{t('comment')}</Label>
                    <Input
                      name='comment'
                      type='text'
                      id='comment'
                      className='form-control form-control-sm'
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.comment || ''}
                      disabled={isUpdating}
                    />
                  </Col>

                  <Col md='4'>
                    <Label htmlFor='result'>{t('result_claim')}</Label>
                    <select
                      name='result'
                      id='result_claim'
                      className='form-select form-select-sm'
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.result || ''}
                      disabled={isUpdating}
                    >
                      <option value='-'>-</option>
                      <option value='Темы обработаны'>Темы обработаны</option>
                    </select>
                  </Col>
                </Row>

                {updateError && (
                  <Alert color='warning'>
                    <strong> {JSON.stringify(createErrorAssing)} </strong>
                  </Alert>
                )}

                {isUpdateSuccess && (
                  <Alert color='success'>
                    <strong> {t('Operation success')} </strong>
                  </Alert>
                )}

                <Col style={{ marginTop: '10px' }}>
                  <Button
                    className='text-nowrap'
                    color='primary'
                    style={{ backgroundColor: '#405189' }}
                    type='submit'
                    disabled={isFetching || isUpdating}
                  >
                    {t('Update')}
                  </Button>
                </Col>
              </Form>
            </Col>

            <Col md='5'>
              <Form onSubmit={formikClient.handleSubmit}>
                <Label htmlFor='client_id'>{t('client_id')}</Label>
                <Input
                  name='client_id'
                  id='client_id'
                  type='text'
                  className='form-control form-control-sm'
                  onChange={formikClient.handleChange}
                  onBlur={formikClient.handleBlur}
                  value={formikClient.values.client_id || ''}
                  disabled={false}
                />
                {updateErrorAssignClient && (
                  <Alert color='warning'>
                    <strong> {JSON.stringify(updateErrorAssignClient)} </strong>
                  </Alert>
                )}

                {isUpdateSuccessAssignClient && (
                  <Alert color='success'>
                    <strong> {t('Operation success')} </strong>
                  </Alert>
                )}

                <Col style={{ marginTop: '10px' }}>
                  <Button
                    className='text-nowrap'
                    color='primary'
                    style={{ backgroundColor: '#405189' }}
                    type='submit'
                    disabled={
                      isUpdatingAssignClient || !formikClient.values.client_id
                    }
                  >
                    {t('Update')}
                  </Button>
                </Col>
              </Form>
            </Col>
          </Row>

          <h4 className='mt-3'>{t('Client request')}</h4>
          <Row>
            <Col md='5'>
              <Label htmlFor='claim_topic'>{t('claim_topic')}</Label>
              <Input
                name='claim_topic'
                type='text'
                id='claim_topic'
                className='form-control form-control-sm'
                value={claim?.claim_topic}
                disabled={true}
              />
            </Col>
            <Col md='7'>
              <Label htmlFor='claim_body'>{t('claim_body')}</Label>
              <Input
                name='claim_body'
                type='textarea'
                onInput={autoResizeInput}
                id='claim_body'
                className='form-control form-control-sm'
                value={claim?.claim_body}
                disabled={true}
              />
            </Col>
          </Row>

          <h4 className='mt-3'>{t('Create topics for appeal')}</h4>
          <Form onSubmit={formik.handleSubmit}>
            {formikInitial.topics.map((field, index) => {
              return (
                <Row key={index}>
                  <h5>
                    {index + 1} {t('subject')}
                  </h5>
                  <Col md='4'>
                    <Label htmlFor={`topics[${index}].repeated`}>
                      {t('repeated')}
                    </Label>
                    <select
                      name={`topics[${index}].repeated`}
                      id={`topics[${index}].repeated`}
                      className='form-select form-select-sm'
                      onChange={(e) => {
                        formik.setFieldValue(
                          `topics[${index}].repeated`,
                          e.target.value
                        )
                      }}
                      onBlur={formik.handleBlur}
                      value={
                        String(formik.values.topics[index]?.repeated) ??
                        field.repeated
                      }
                      disabled={false}
                    >
                      <option value={'true'}>{t('yes')}</option>
                      <option value={'false'}>{t('no')}</option>
                    </select>
                  </Col>

                  <Col md='4'>
                    <FormGroup>
                      <Label htmlFor={`topics[${index}].is_complaint`}>
                        {t('is_complaint')}
                      </Label>
                      <select
                        name={`topics[${index}].is_complaint`}
                        id={`topics[${index}].is_complaint`}
                        className='form-select form-select-sm'
                        onChange={(e) => {
                          formik.setFieldValue(
                            `topics[${index}].is_complaint`,
                            e.target.value
                          )
                        }}
                        onBlur={formik.handleBlur}
                        value={
                          String(formik.values.topics[index]?.is_complaint) ??
                          field.is_complaint
                        }
                        disabled={false}
                      >
                        <option value={'true'}>{t('yes')}</option>
                        <option value={'false'}>{t('no')}</option>
                      </select>
                    </FormGroup>
                  </Col>

                  <Col md='4'>
                    <FormGroup>
                      <Label htmlFor={`topics[${index}].comment`}>
                        {t('comment')}
                      </Label>
                      <Input
                        name={`topics[${index}].comment`}
                        id={`topics[${index}].comment`}
                        type='textarea'
                        className='form-control form-control-sm'
                        onInput={autoResizeInput}
                        style={{ height: '25px' }}
                        onChange={(e) => {
                          formik.setFieldValue(
                            `topics[${index}].comment`,
                            e.target.value
                          )
                        }}
                        onBlur={formik.handleBlur}
                        value={
                          formik.values.topics[index]?.comment || field.comment
                        }
                        disabled={isFetching}
                      />
                    </FormGroup>
                  </Col>

                  <Col md='4'>
                    <FormGroup>
                      <Label htmlFor={`topics[${index}].topic_id`}>
                        {t('claim_topic_id')}
                      </Label>
                      <select
                        name={`topics[${index}].topic_id`}
                        id={`topics[${index}].topic_id`}
                        className='form-select form-select-sm'
                        onChange={(e) => {
                          const selectedTopicId = Number.parseInt(
                            e.target.value
                          )
                          formik.setFieldValue(
                            `topics[${index}].topic_id`,
                            selectedTopicId
                          )

                          const availableSubtopicId = topics?.find(
                            (topic) => topic.parent_topic_id === selectedTopicId
                          )?.id
                          formik.setFieldValue(
                            `topics[${index}].subtopic_id`,
                            availableSubtopicId
                          )
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.topics[index]?.topic_id ?? ''}
                        disabled={isFetching || isLoadingAssing}
                      >
                        <option value='0'>-</option>
                        {topics
                          ?.filter((option) => option.parent_topic_id == null)
                          .map((option) => (
                            <option key={option.topic_name} value={option.id}>
                              {option.topic_name}
                            </option>
                          ))}
                      </select>
                    </FormGroup>
                  </Col>

                  <Col md='4'>
                    <FormGroup>
                      <Label htmlFor={`topics[${index}].subtopic_id`}>
                        {t('claim_subtopic_id')}
                      </Label>
                      <select
                        name={`topics[${index}].subtopic_id`}
                        id={`topics[${index}].subtopic_id`}
                        className='form-select form-select-sm'
                        onChange={(e) =>
                          formik.setFieldValue(
                            `topics[${index}].subtopic_id`,
                            Number.parseInt(e.target.value)
                          )
                        }
                        onBlur={formik.handleBlur}
                        value={
                          formik.values.topics[index]?.subtopic_id ||
                          field.subtopic_id
                        }
                        disabled={
                          isFetching ||
                          !formik.values.topics[index]?.topic_id ||
                          isLoadingAssing
                        }
                      >
                        <option hidden disabled value='' />
                        {topics
                          ?.filter(
                            (option) =>
                              option.parent_topic_id ===
                              formik.values.topics[index]?.topic_id
                          )
                          .map((option) => (
                            <option key={option.topic_name} value={option.id}>
                              {option.topic_name}
                            </option>
                          ))}
                      </select>
                    </FormGroup>
                  </Col>

                  <Col md='4'>
                    <FormGroup>
                      <Label htmlFor='answer_type'>{t('method_answer')}</Label>
                      <select
                        name='answer_type'
                        id='answer_type'
                        className='form-select form-select-sm'
                        onChange={(e) =>
                          formik.setFieldValue(
                            `topics[${index}].answer_type`,
                            e.target.value
                          )
                        }
                        onBlur={formik.handleBlur}
                        // @ts-ignore
                        value={formik.values.topics[index]?.answer_type || ''}
                        disabled={false}
                      >
                        <option value='-'>-</option>
                        <option value='email'>E-mail</option>
                        <option value='mail'>{t('pochta_rf')}</option>
                        <option value='phone'>{t('phone')}</option>
                      </select>
                    </FormGroup>
                  </Col>
                  <Col md='4'>
                    <FormGroup>
                      <Label htmlFor={`topics[${index}].error_category_id`}>
                        {t('category')}
                      </Label>
                      <select
                        name={`topics[${index}].error_category_id`}
                        id={`topics[${index}].error_category_id`}
                        className='form-select form-select-sm'
                        onChange={(e) => {
                          const value = Number(e.target.value)
                          formik.setFieldValue(
                            `topics[${index}].error_category_id`,
                            value
                          )
                          formik.setFieldValue(
                            `topics[${index}].error_subcategory_id`,
                            0
                          )
                        }}
                        onBlur={formik.handleBlur}
                        value={
                          formik.values.topics[index]?.error_category_id ??
                          field.error_category_id ??
                          ''
                        }
                        disabled={!errorCategories}
                      >
                        <option value={0}>-</option>
                        {errorCategories
                          ?.filter((cat) => cat.parent_id === null)
                          .map((cat) => (
                            <option key={cat.id} value={cat.id}>
                              {cat.name}
                            </option>
                          ))}
                      </select>
                    </FormGroup>
                  </Col>
                  <Col md='4'>
                    <FormGroup>
                      <Label htmlFor={`topics[${index}].error_subcategory_id`}>
                        {t('subcategory')}
                      </Label>
                      <select
                        name={`topics[${index}].error_subcategory_id`}
                        id={`topics[${index}].error_subcategory_id`}
                        className='form-select form-select-sm'
                        onChange={(e) => {
                          formik.setFieldValue(
                            `topics[${index}].error_subcategory_id`,
                            Number(e.target.value)
                          )
                        }}
                        onBlur={formik.handleBlur}
                        value={
                          formik.values.topics[index]?.error_subcategory_id ??
                          field.error_subcategory_id ??
                          ''
                        }
                        disabled={
                          !formik.values.topics[index]?.error_category_id ||
                          !errorCategories
                        }
                      >
                        <option value={0}>-</option>
                        {errorCategories
                          ?.filter(
                            (cat) =>
                              cat.parent_id ===
                              formik.values.topics[index]?.error_category_id
                          )
                          .map((cat) => (
                            <option key={cat.id} value={cat.id}>
                              {cat.name}
                            </option>
                          ))}
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
              )
            })}

            {(createErrorAssing || topicError || createErrorForceTopic) && (
              <Alert color='warning'>
                <strong> {JSON.stringify(createErrorAssing)} </strong>
              </Alert>
            )}

            {(isSuccessAssing || isSuccessForceTopic) && (
              <Alert color='success'>
                <strong> {t('Operation success')} </strong>
              </Alert>
            )}

            <Col>
              <Button
                className='text-nowrap'
                color='primary'
                style={{ backgroundColor: '#405189' }}
                type='button'
                onClick={() => addTopicForm()}
              >
                {t('Add topic')}
              </Button>

              <Button
                className='text-nowrap'
                color='primary'
                style={{ backgroundColor: '#405189', marginLeft: '10px' }}
                type='button'
                onClick={() => deleteTopicForm()}
                disabled={!formik.values.topics.length}
              >
                {t('Delete topic')}
              </Button>
            </Col>

            <Col style={{ marginTop: '10px' }}>
              <Button
                className='text-nowrap'
                color='primary'
                style={{ backgroundColor: '#405189' }}
                type='submit'
                disabled={
                  isFetching ||
                  isLoadingAssing ||
                  isLoadingForceTopic ||
                  formik.values.topics.some((el) => !el.subtopic_id) ||
                  !formik.values.topics.length
                }
              >
                {t('Create claim')}
              </Button>
            </Col>
          </Form>
        </CardBody>
      </Card>
    </div>
  )
}
