import {
  Button,
  ButtonGroup,
  Input,
  Label,
  ModalHeader,
  ModalBody,
  FormFeedback
} from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate, Link } from 'react-router-dom'
import * as Yup from 'yup'
import Flatpickr from 'react-flatpickr'
import {
  CallPerson,
  CallSetting,
  useAddCallResultMutation,
  useGetCallPersonsQuery,
  useGetCallReasonsByProfileIdQuery,
  useGetCallSettingsQuery,
  useSendInfoMessageMutation
} from 'src/services/calling'
import { useFormik } from 'formik'
import { useGetProfileQuery } from 'src/services/profiles'
import { useDisclosure } from 'src/utils/useDisclosure'
import { DangerAlert } from 'src/pages/Admin/ErrorPage'
import { toast } from 'react-toastify'
import { useGetPhonesQuery } from 'src/services/phones'
import { LIST_LIMIT } from 'src/client'
import { omitBy } from 'src/utils'
import cx from 'classnames'
import { RootState, useSelector } from 'src/store'
import { useEffect, useState } from 'react'

const DOCUMENTS_SUBMITTED_TO_MVD = 9

export const CallResultModal = ({
  loan_id,
  profile_id,
  phone = '',
  open,
  close,
  isOpen,
  callResultMessage,
  ...props
}: ReturnType<typeof useDisclosure> & {
  loan_id: string
  profile_id: string
  phone?: string
  callResultMessage: string
  [key: string]: any
}) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const [incomingPhoneNumber, setIncomingPhoneNumber] = useState('')

  const [attemptId, setAttemptId] = useState('')

  const lastMessage = useSelector(
    (state: RootState) => state.calling.lastMessage
  )

  const { data: profile } = useGetProfileQuery(profile_id!, {
    skip: profile_id == null
  })

  const { data: persons = [], error: personsError } = useGetCallPersonsQuery()

  const { data: results = [], error: settingsError } = useGetCallSettingsQuery()

  const { data: reasons = [], error: reasonsError } =
    useGetCallReasonsByProfileIdQuery()

  const { data: phones = [], error: phonesError } = useGetPhonesQuery(
    {
      client_id: profile?.client.id,
      limit: LIST_LIMIT,
      offset: 0
    },
    { skip: profile == null }
  )

  useEffect(() => {
    if (lastMessage) {
      open()
      if (lastMessage.phone) {
        setIncomingPhoneNumber(lastMessage.phone)
      }
      if (lastMessage.attempt_id) {
        setAttemptId(lastMessage.attempt_id)
      }
    }
  }, [lastMessage, open])

  const [addCallResult, { error, isLoading }] = useAddCallResultMutation()
  const [sendInfoMessage] = useSendInfoMessageMutation()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      profile_id,
      loan_id,
      contact_type: persons?.[0]?.type_id,
      result: callResultMessage
        ? DOCUMENTS_SUBMITTED_TO_MVD
        : getDefaultOrFirstResultByPerson(results, persons?.[0])
            ?.result_type_id,
      reason: -1,
      amount_promise: '',
      date_promise: null,
      comment: callResultMessage || '',
      phone: incomingPhoneNumber || phone || profile?.phone || ''
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      contact_type: Yup.number().required(t('Required')),
      result: Yup.number().required(t('Required')),
      phone: Yup.string().required(t('Required')),
      reason: Yup.number()
        .nullable()
        .when('result', {
          is: (resultTypeId: number) =>
            !!results.find((result) => result.result_type_id === resultTypeId)
              ?.need_reason,
          then: (schema) =>
            schema.required(t('Required')).notOneOf([-1], t('no_reason')),
          otherwise: (schema) => schema
        }),
      amount_promise: Yup.number()
        .integer(t('The number must be an integer'))
        .nullable()
        .typeError(t('Amount must be a number'))
        .test('no-spaces', t('Spaces cannot be used'), function () {
          const originalValue = (this as any).originalValue
          if (originalValue == null) return true
          return !/\s/.test(originalValue)
        })
        .when('result', {
          is: (resultTypeId: number) =>
            !!results.find((result) => result.result_type_id === resultTypeId)
              ?.need_promise_amount,
          then: (schema) => schema.required(t('Required')),
          otherwise: (schema) => schema
        }),
      date_promise: Yup.date()
        .nullable()
        .typeError(t('Must be date'))
        .when('result', {
          is: (resultTypeId: number) =>
            !!results.find((result) => result.result_type_id === resultTypeId)
              ?.need_promise_date,
          then: (schema) =>
            schema.required(t('Required')).notOneOf([null], t('Required')),
          otherwise: (schema) => schema
        }),
      comment: Yup.string()
        .nullable()
        .when('result', {
          is: (resultTypeId: number) =>
            !!results.find((result) => result.result_type_id === resultTypeId)
              ?.need_comment,
          then: (schema) => schema.required(t('Required')),
          otherwise: (schema) => schema
        })
    }),
    onSubmit: async (values) => {
      try {
        await addCallResult(
          omitBy(
            {
              ...values,
              is_order: !!props.isOrder,
              collection_queue_id: props.collectionQueueSeq,
              fis_attempt_id: attemptId,
              date_promise: values.date_promise
                ? `${values.date_promise}T00:00:00`
                : ''
            },
            // фильтруем отсутствие причины
            (value) => value < 0,
            (value) => value == null,
            (value) => value === ''
          )
        ).unwrap()

        await sendInfoMessage({ event: 'result_saved' })

        toast.success(t('Successfully added'), {
          autoClose: 2000,
          closeOnClick: false
        })
        if (props.collectionQueueSeq) {
          navigate('/debts-queue')
        }
        close()
      } catch (error) {
        toast.error(t('Error adding'), {
          autoClose: 2000,
          closeOnClick: false
        })
      }
    }
  })

  const autoResizeInput = (event: any) => {
    event.target.style.height = '40px'
    event.target.style.height = event.target.scrollHeight + 'px'
  }

  const header = (
    <div className='d-flex flex-row justify-content-between w-100 p-3'>
      <div className='modal-title'>
        <span>{t('Fill out call result')}</span>
      </div>
    </div>
  )

  if (!isOpen) {
    return (
      <div
        id='myModal'
        className='bg-light rounded shadow border'
        style={{
          position: 'fixed',
          right: 50,
          top: 200,
          zIndex: 500
        }}
      >
        <div
          style={{ position: 'relative' }}
          className='d-flex flex-column h-100'
        >
          <div className='text-center' role='button' onClick={open}>
            <small className='fw-medium text-decoration-underline'>
              {t('expand')}
            </small>
          </div>
          {header}
        </div>
      </div>
    )
  }

  return (
    <>
      <div
        className='bg-light rounded shadow border'
        style={{
          position: 'fixed',
          right: 50,
          bottom: 50,
          top: 50,
          zIndex: 1005
        }}
      >
        <div
          style={{ position: 'relative' }}
          className='d-flex flex-column h-100'
        >
          <div className='text-center' role='button' onClick={close}>
            <small className='fw-medium text-decoration-underline'>
              {t('collapse')}
            </small>
          </div>
          <ModalHeader>
            {t('Recording the result of a conversation with')}
            <br />
            <Link
              className='btn-md pt-0 text-decoration-underline'
              to={`/client-view?id=${profile?.client.id}`}
            >
              {`${profile?.client?.last_name} ${profile?.client?.first_name} ${profile?.client?.middle_name}`}
            </Link>
          </ModalHeader>
          <div className='h-divider' />
          <ModalBody
            style={{
              overflow: 'auto',
              paddingTop: 0
            }}
          >
            <form
              className='vstack d-flex align-items-center justify-content-center p-2'
              onSubmit={formik.handleSubmit}
            >
              <div
                className='vstack mt-2 mb-0 align-self-center'
                style={{ width: '350px' }}
              >
                <div style={{ marginBottom: '15px' }}>
                  <Label htmlFor={'contact_type'}>
                    {t('Who were you talking to?')}
                  </Label>
                  <Input
                    id={'contact_type'}
                    name='contact_type'
                    value={formik.values.contact_type}
                    type={'select'}
                    onChange={(e) => {
                      const newPersonTypeId = parseInt(e.target.value)
                      formik.setFieldValue('contact_type', newPersonTypeId)

                      const defaultOrFirstResult =
                        getDefaultOrFirstResultByPerson(
                          results,
                          persons?.find(
                            (person) => person.type_id === newPersonTypeId
                          )
                        )
                      if (defaultOrFirstResult)
                        formik.setFieldValue(
                          'result',
                          defaultOrFirstResult.result_type_id
                        )
                      else
                        toast.error(
                          'Cannot find matching result by contact type'
                        )
                    }}
                    onBlur={formik.handleBlur}
                    invalid={
                      formik.touched.contact_type &&
                      !!formik.errors.contact_type
                    }
                    disabled={isLoading}
                  >
                    {persons.length > 0 ? (
                      persons.map((person) => (
                        <option key={person.type_id} value={person.type_id}>
                          {person.name}
                        </option>
                      ))
                    ) : (
                      <option value=''>{t('Loading...')}</option>
                    )}
                  </Input>
                  {formik.touched.contact_type && (
                    <FormFeedback>{formik.errors.contact_type}</FormFeedback>
                  )}
                  {!!personsError && <DangerAlert error={personsError} />}
                </div>

                <div style={{ marginBottom: '16px' }}>
                  <Label htmlFor={'call_result'}>{t('Call result')}</Label>
                  <Input
                    id='result'
                    name='result'
                    value={formik.values.result}
                    type={'select'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={formik.touched.result && !!formik.errors.result}
                    disabled={isLoading || results.length === 0}
                  >
                    {results.length > 0 ? (
                      results
                        .filter(
                          (setting) =>
                            setting.client_type_id ===
                            formik.values.contact_type
                        )
                        .map((result) => (
                          <option key={result.id} value={result.result_type_id}>
                            {result.result_type}
                          </option>
                        ))
                    ) : (
                      <option value=''>{t('No results available')}</option>
                    )}
                  </Input>
                  {formik.touched.result && (
                    <FormFeedback>{formik.errors.result}</FormFeedback>
                  )}
                  {!!settingsError && <DangerAlert error={settingsError} />}
                </div>

                <div style={{ marginBottom: '15px' }}>
                  <Label htmlFor='phone'>{t('phone')}</Label>
                  <Input
                    id='phone'
                    name='phone'
                    value={formik.values.phone}
                    type='select'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={!!formik.errors.phone}
                    disabled={isLoading}
                  >
                    <option value={incomingPhoneNumber || profile?.phone}>
                      {incomingPhoneNumber || profile?.phone}
                    </option>
                    {phones.map(({ phone }) => (
                      <option key={phone} value={phone}>
                        {phone}
                      </option>
                    ))}
                  </Input>

                  <FormFeedback>{formik.errors.phone}</FormFeedback>

                  {!!phonesError && <DangerAlert error={phonesError} />}
                </div>

                <div style={{ marginBottom: '15px' }}>
                  <Label htmlFor={'reason'}>{t('Reason')}</Label>
                  <Input
                    id={'reason'}
                    name='reason'
                    value={formik.values.reason}
                    type={'select'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={!!formik.errors.reason}
                    disabled={isLoading}
                  >
                    <option value={-1}>-</option>
                    {reasons.map((reason) => (
                      <option key={reason.type_id} value={reason.type_id}>
                        {reason.name}
                      </option>
                    ))}
                  </Input>
                  <FormFeedback>{formik.errors.reason}</FormFeedback>
                  {!!reasonsError && <DangerAlert error={reasonsError} />}
                </div>

                <div style={{ marginBottom: '15px' }}>
                  <Label htmlFor={'comment'}>{t('Comment')}</Label>
                  <Input
                    id={'comment'}
                    name='comment'
                    value={formik.values.comment}
                    style={{ height: '40px' }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onInput={autoResizeInput}
                    type={'textarea'}
                    invalid={!!formik.errors.comment}
                    disabled={isLoading}
                  />

                  <FormFeedback>{formik.errors.comment}</FormFeedback>
                </div>

                <div style={{ marginBottom: '15px' }}>
                  <Label htmlFor={'amount_promise'}>
                    {t('amount_promise')}
                  </Label>
                  <Input
                    id={'amount_promise'}
                    name='amount_promise'
                    value={formik.values.amount_promise}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={!!formik.errors.amount_promise}
                    disabled={isLoading}
                  />
                  <FormFeedback>{formik.errors.amount_promise}</FormFeedback>
                </div>

                <div style={{ marginBottom: '15px' }}>
                  <Label>{t('date_promise')}</Label>
                  <Flatpickr
                    className={cx('form-control', {
                      'is-invalid': formik.errors.date_promise
                    })}
                    id='date_promise'
                    name='date_promise'
                    value={formik.values.date_promise}
                    options={{
                      minDate: 'today',
                      dateFormat: 'Y-m-d',
                      onChange: (_: unknown, dateStr: string) => {
                        formik.setFieldValue('date_promise', dateStr)
                      }
                    }}
                    disabled={isLoading}
                  />
                  {formik.touched.date_promise && (
                    <FormFeedback style={{ display: 'block' }}>
                      {formik.errors.date_promise}
                    </FormFeedback>
                  )}
                </div>

                {error && <DangerAlert error={error} />}
              </div>
              <ButtonGroup>
                <Button
                  style={{ marginLeft: '10px' }}
                  className='mt-3'
                  type='submit'
                  disabled={isLoading}
                >
                  {t('Send')}
                </Button>
              </ButtonGroup>
            </form>
          </ModalBody>
        </div>
      </div>
    </>
  )
}

const getDefaultOrFirstResultByPerson = (
  results: CallSetting[],
  person?: CallPerson
): CallSetting | undefined => {
  const personResults = results?.filter(
    (setting) => setting.client_type_id === person?.type_id
  )
  return (
    personResults?.find((setting) => setting.is_default) || personResults?.[0]
  )
}
