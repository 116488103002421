import BreadCrumb from 'src/Components/Common/BreadCrumb'
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap'
import MetaBar from 'src/Components/Common/MetaBar'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  useCreateErrorCategoriesMutation,
  useDeleteErrorCategoriesMutation,
  useGetErrorCategoriesQuery,
  useGetErrorCategoryQuery,
  useUpdateErrorCategoriesMutation
} from 'src/services/claims'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import ErrorPage from '../../ErrorPage'

export const ErrorCategoryCreate = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const categoryIdParam = searchParams.get('id')

  const {
    data: category,
    isLoading: isLoadingCategory,
    error: categoryError
  } = useGetErrorCategoryQuery(
    { id: +categoryIdParam! },
    { skip: categoryIdParam === null }
  )

  const { data: categories } = useGetErrorCategoriesQuery()

  const [
    updateErrorCategories,
    { isLoading: isUpdating, error: updateError, isSuccess: isUpdateSuccess }
  ] = useUpdateErrorCategoriesMutation()

  const [
    deleteErrorCategories,
    {
      isLoading: isDeleteLoading,
      error: deleteError,
      isSuccess: isDeleteSuccess
    }
  ] = useDeleteErrorCategoriesMutation()

  const [
    createErrorCategories,
    { isLoading: isCreating, error: createError, isSuccess: isCreateSuccess }
  ] = useCreateErrorCategoriesMutation()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: '',
      parent_category_id: '',
      category_name: ''
    },
    validationSchema: Yup.object({
      id: Yup.string().nullable(),
      parent_category_id: Yup.number().nullable(),
      category_name: Yup.string()
    }),
    onSubmit: async (values) => {
      try {
        const parent_id = values.parent_category_id
          ? Number(values.parent_category_id)
          : null
        const data = {
          name: values.category_name,
          parent_id
        }
        if (categoryIdParam) {
          await updateErrorCategories({ id: Number(categoryIdParam), ...data })
        } else {
          await createErrorCategories(data)
        }
      } catch (err) {
        console.log('Error in onSubmit:', err)
      }
    }
  })

  const handleDeleteCategory = async () => {
    if (!categoryIdParam) return
    try {
      await deleteErrorCategories(+categoryIdParam)
      navigate('/category-of-errors')
    } catch (err) {
      console.log(err)
    }
  }

  const title = categoryIdParam ? t('edit_category') : t('create_category')

  if (categoryError) {
    return <ErrorPage title={title} error={categoryError} />
  }
  return (
    <div className='page-content'>
      <BreadCrumb title={title} pageTitle={undefined} link={undefined} />
      <Card>
        <CardBody>
          <div className='d-flex gap-2'>
            <MetaBar
              createLink={undefined}
              backLink={'/category-of-errors'}
              editLink={undefined}
              entity={category}
              buttons={undefined}
              disableBorder={undefined}
            />
            {categoryIdParam && (
              <Button
                disabled={isLoadingCategory}
                className='btn-icon'
                color='primary'
                style={{ backgroundColor: '#405189', width: '100px' }}
                onClick={handleDeleteCategory}
              >
                <i className='ri-delete-bin-line label-icon align-middle fs-16 me-2'></i>
                {t('Delete')}
              </Button>
            )}
          </div>
          <Form
            className='needs-validation mt-4'
            onSubmit={formik.handleSubmit}
          >
            <Row>
              <Col md='4'>
                <FormGroup className='mb-3'>
                  <Label htmlFor='category_name'>{t('category_name')}</Label>
                  <Input
                    name='category_name'
                    type='text'
                    className='form-control form-control-sm'
                    id='category_name'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.category_name}
                    disabled={isCreating || isLoadingCategory}
                    invalid={
                      !!(
                        formik.touched.category_name &&
                        formik.errors.category_name
                      )
                    }
                  />
                </FormGroup>
              </Col>

              <Col md='4'>
                <FormGroup className='mb-3'>
                  <Label htmlFor='parent_category_id'>
                    {t('main_category')}
                  </Label>
                  <select
                    name='parent_category_id'
                    id='parent_category_id'
                    className='form-select form-select-sm'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.parent_category_id ?? ''}
                    disabled={
                      isCreating ||
                      isUpdating ||
                      isLoadingCategory ||
                      isDeleteLoading
                    }
                  >
                    <option value={''}>-</option>
                    {categories
                      ?.filter((el) => el.parent_id == null)
                      .map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                  </select>
                </FormGroup>
              </Col>
            </Row>

            {(createError || updateError || deleteError) && (
              <Alert color='warning'>
                <strong>
                  {' '}
                  {JSON.stringify(
                    createError || updateError || deleteError
                  )}{' '}
                </strong>
              </Alert>
            )}

            {(isCreateSuccess || isUpdateSuccess || isDeleteSuccess) && (
              <Alert color='success'>
                <strong> {t('Operation success')} </strong>
              </Alert>
            )}

            <Button
              className='text-nowrap'
              color='primary'
              style={{ backgroundColor: '#405189' }}
              type='submit'
              disabled={isCreating || isLoadingCategory}
            >
              {categoryIdParam ? t('Update') : t('Create')}
            </Button>
          </Form>
        </CardBody>
      </Card>
    </div>
  )
}
