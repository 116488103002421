import { Link, useLocation } from 'react-router-dom'
import { LayoutChildItem } from '../layoutMenuData/layoutItems.types'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface IVerticalLayoutChildItem {
  childItem: LayoutChildItem
}

const VerticalLayoutChildItem: React.FC<IVerticalLayoutChildItem> = ({
  childItem
}) => {
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const { link, label } = childItem

  return (
    <li className='nav-item'>
      <Link
        className={`nav-link ${pathname === link ? 'active' : ''}`}
        to={link || '/#'}
      >
        {t(label)}
      </Link>
    </li>
  )
}

export { VerticalLayoutChildItem }
