import { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import { InputItem } from './InputItem'
import { InputFilters } from './InputFilters'

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Alert,
  Spinner,
  Container,
  Table
} from 'reactstrap'

import {
  getStrategy,
  updateStrategy,
  createStrategy
} from 'src/store/strategies/action'
import { getEntities } from 'src/store/actions'

import Client from 'src/client'

// Formik validation
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useGetActionsInfoQuery } from 'src/services/communications'
import ErrorPage from '../ErrorPage'
import LoadingPage from '../LoadingPage'
import { useGetLoansTagsQuery } from 'src/services/tags'

const inputInitials = {
  name: {
    type: 'common',
    label: 'strategy_name',
    initial: '',
    style: { maxWidth: 240 }
  },
  start_time: { type: 'time', label: 'start_time', initial: '' },

  dpd: { type: 'common', label: 'dpd', initial: '0' },
  dpd_duration: {
    type: 'common',
    label: 'dpd_duration',
    initial: ''
  },
  collection_stage_ids: {
    type: 'filter',
    label: 'segments',
    initial: [],
    isList: true
  },

  stop_tag_ids: {
    type: 'filter',
    label: 'stop_tag_ids',
    initial: [],
    isList: true
  },
  tag_ids: { type: 'filter', label: 'tag_ids', initial: [], isList: true },

  sms_actions: {
    type: 'actions',
    label: 'sms',
    time_trigger: { label: 'time_trigger', initial: '' },
    template_id: { label: 'template_id', initial: '' },
    id: {}
  },
  email_actions: {
    type: 'actions',
    label: 'email',
    time_trigger: { label: 'time_trigger', initial: '' },
    template_id: { label: 'template_id', initial: '' },
    id: {}
  },
  phonerobot_actions: {
    type: 'actions',
    label: 'phonerobot',
    time_trigger: { label: 'time_trigger', initial: '' },
    template_id: { label: 'template_id', initial: '' },
    id: {}
  },
  report_actions: {
    type: 'actions',
    label: 'report',
    dpd_start: { label: 'dpd_start', initial: '' },
    dpd_end: { label: 'dpd_end', initial: '' },
    id: {}
  },
  chat_actions: {
    type: 'actions',
    label: 'chat',
    time_trigger: { label: 'time_trigger', initial: '' },
    template_id: { label: 'template_id', initial: '' },
    id: {}
  },
  popup_actions: {
    type: 'actions',
    label: 'pop-up',
    time_trigger: { label: 'time_trigger', initial: '' },
    template_id: { label: 'template_id', initial: '' },
    id: {}
  }
}

export const StrategyEdit = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [id, setId] = useState(null)
  const [initialFormValues, setInitialFormValues] = useState(null)
  const [check, setCheck] = useState(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [segments, setSegments] = useState([])
  const [tags, setTags] = useState([])
  const [pageReady, setPageReady] = useState(false)
  const [paramError, setParamError] = useState(null)
  const [updateComp, setUpdateComp] = useState(false)

  const hours = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23'
  ]

  const {
    strategy,
    strategyError,
    updateLoading,
    updateError,
    createError,
    createLoading
  } = useSelector((state) => ({
    strategy: state.strategies.strategy,
    strategyLoading: state.strategies.strategyLoading,
    strategyError: state.strategies.strategyError,
    updateError: state.strategies.updateError,
    createError: state.strategies.createError,
    updateLoading: state.strategies.updateLoading,
    createLoading: state.strategies.createLoading
  }))

  const { list: listSegments } = useSelector((state) => ({
    list: state.entities[Client.ENTITY_TYPE.SEGMENTS].list,
    listLoading: state.entities[Client.ENTITY_TYPE.SEGMENTS].listLoading,
    listError: state.entities[Client.ENTITY_TYPE.SEGMENTS].listError
  }))

  const {
    data: actionsInfo,
    isLoading: isLoadingInfo,
    error: errorInfo
  } = useGetActionsInfoQuery()

  const { data: loansTags, error: tagsError } = useGetLoansTagsQuery()

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const id = params.get('id')
    console.log('id => ', id)
    setId(id || null)
    if (id) {
      //edit mode
      dispatch(getStrategy(id))
      setUpdateComp(false)
    } else {
      //create mode
      const initialFormValues = {}
      initialFormValues.name = inputInitials['name'].initial
      initialFormValues.dpd = inputInitials['dpd'].initial
      initialFormValues.dpd_duration = inputInitials['dpd_duration'].initial

      initialFormValues.start_time = inputInitials['start_time'].initial

      initialFormValues.collection_stage_ids =
        inputInitials['collection_stage_ids'].initial
      initialFormValues.stop_tag_ids = inputInitials['stop_tag_ids'].initial
      initialFormValues.tag_ids = inputInitials['tag_ids'].initial

      setInitialFormValues(initialFormValues)
      setPageReady(true)
    }
  }, [updateComp, tags])

  useEffect(() => {
    if (!strategy) return
    setPageReady(true)
    setCheck(strategy.is_active)
  }, [strategy])

  const getTags = async () => {
    const allTags = []
    await Client.getClientsTags()
      .then((data) => {
        allTags.push(...data)
      })
      .catch((err) => setParamError(err))

    if (loansTags) {
      allTags.push(...loansTags)
    }

    setTags(allTags)
  }

  useEffect(() => {
    dispatch(getEntities(Client.ENTITY_TYPE.SEGMENTS))
    getTags()
  }, [])

  useEffect(() => {
    inputInitials.collection_stage_ids.options = [
      { name: '-', value: '-' },
      ...listSegments.map((segment) => ({
        ...segment,
        name: segment.name,
        value: segment.id
      }))
    ]
    inputInitials.collection_stage_ids.options.sort((a, b) =>
      a.name > b.name ? 1 : a.name === b.name ? 0 : -1
    )
    setSegments(listSegments)
  }, [listSegments])

  useEffect(() => {
    inputInitials.tag_ids.options = [
      { name: '-', value: '-' },
      ...tags.map((tag) => ({
        ...tag,
        name: tag.tag,
        value: tag.id
      }))
    ]
    inputInitials.tag_ids.options.sort((a, b) =>
      a.name > b.name ? 1 : a.name === b.name ? 0 : -1
    )

    inputInitials.stop_tag_ids.options = [
      { name: '-', value: '-' },
      ...tags.map((tag) => ({
        ...tag,
        name: tag.tag,
        value: tag.id
      }))
    ]
    inputInitials.stop_tag_ids.options.sort((a, b) =>
      a.name > b.name ? 1 : a.name === b.name ? 0 : -1
    )
  }, [tags])

  useEffect(() => {
    if (id && strategy) {
      //edit mode
      const initialFormValues = {}

      //common
      initialFormValues.dpd_duration =
        strategy['dpd_duration'] !== undefined
          ? strategy['dpd_duration']?.toString()
          : ''
      initialFormValues.name =
        strategy['name'] !== undefined && strategy['name'] !== null
          ? strategy['name']
          : ''
      initialFormValues.dpd =
        strategy['loan_filters']['dpd'] !== undefined &&
        strategy['loan_filters']['dpd'] !== null
          ? strategy['loan_filters']['dpd']?.toString()
          : ''
      initialFormValues.collection_stage_ids =
        strategy['loan_filters']['collection_stage_ids']?.length > 0
          ? strategy['loan_filters']['collection_stage_ids']
          : []

      initialFormValues.start_time =
        strategy['start_time'] !== undefined && strategy['start_time'] !== null
          ? strategy['start_time']
          : ''

      initialFormValues.tag_ids =
        strategy['tag_filters']['tag_ids']?.length > 0
          ? strategy['tag_filters']['tag_ids']
          : []
      initialFormValues.stop_tag_ids =
        strategy['tag_filters']['stop_tag_ids']?.length > 0
          ? strategy['tag_filters']['stop_tag_ids']
          : []

      setInitialFormValues(initialFormValues)
      setPageReady(true)
    }
  }, [strategy, id])

  // Form validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...initialFormValues
    },
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      const data = {
        name: '',
        start_time: '',
        loan_filters: {
          dpd: 0,
          collection_stage_ids: []
        },
        tag_filters: {
          stop_tag_ids: [],
          tag_ids: []
        }
      }
      data.name = values.name
      data.start_time = values.start_time
      data.loan_filters.dpd = Number(values.dpd)
      data.loan_filters.collection_stage_ids = values['collection_stage_ids']
      data.tag_filters.stop_tag_ids = values['stop_tag_ids']
      data.tag_filters.tag_ids = values['tag_ids']
      data.dpd_duration = Number(values.dpd_duration)

      console.log(data)

      if (id) dispatch(updateStrategy({ id: strategy.id, ...data }))
      else dispatch(createStrategy(data))
    }
  })

  const deleteClick = useCallback(
    (strategy_id, action_id, label) => {
      setUpdateComp(true)
      return Client.deleteActionStrategy(strategy_id, action_id, label)
    },
    [strategy]
  )

  const actionStrategy = () => {
    if (check) Client.startStrategy(strategy.id)
    else Client.stopStrategy(strategy.id)
  }

  const title = id
    ? `${t('strategies')}: ${t('Edit')}`
    : `${t('strategies')}: ${t('Create')}`
  document.title = title
  const bread = <BreadCrumb title={title} />
  const loading = <Spinner color='primary'>{t('Loading...')}</Spinner>
  const metaBar = (
    <MetaBar
      backLink={'/strategy'}
      entity={id && !!strategy ? strategy : null}
    />
  )

  if (paramError || strategyError || tagsError) {
    return (
      <>
        <UiContent />
        <div className='page-content'>
          {bread}
          <Card>
            <CardBody>
              {metaBar}
              <Alert color='warning' className='mt-4'>
                <strong> {!!paramError && paramError.toString()} </strong>
                <strong> {!!strategyError && strategyError.toString()} </strong>
              </Alert>
            </CardBody>
          </Card>
        </div>
      </>
    )
  }

  if (!pageReady) {
    return (
      <>
        <UiContent />
        <div className='page-content'>
          {bread}
          <Card>
            <CardBody>
              {metaBar}
              <Container
                fluid={true}
                className='mt-4 d-flex justify-content-center'
              >
                {loading}
              </Container>
            </CardBody>
          </Card>
        </div>
      </>
    )
  }

  if (isLoadingInfo) {
    return <LoadingPage title={title} />
  }

  if (errorInfo) {
    return <ErrorPage title={title} error={errorInfo} />
  }

  return (
    <>
      <UiContent />
      <div className='page-content'>
        {bread}
        <Card>
          <CardBody>
            {metaBar}
            <Form
              className='needs-validation mt-4'
              onSubmit={(e) => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <Row>
                {Object.keys(inputInitials)
                  .filter((key) => inputInitials[key].type === 'common')
                  .map((fieldKey) => {
                    const htmlForId = `validation-${fieldKey}`
                    return (
                      <FormGroup className='mb-3' key={`${fieldKey}`}>
                        <Label htmlFor={htmlForId}>
                          {t(inputInitials[fieldKey].label)}
                        </Label>
                        <Input
                          name={fieldKey}
                          type='text'
                          className='form-control form-control-sm'
                          id={htmlForId}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values[fieldKey] || ''}
                          invalid={
                            !!(
                              validation.touched[fieldKey] &&
                              validation.errors[fieldKey]
                            )
                          }
                        />
                        {validation.touched[fieldKey] &&
                        validation.errors[fieldKey] ? (
                          <FormFeedback type='invalid'>
                            {validation.errors[fieldKey]}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    )
                  })}
                {Object.keys(inputInitials)
                  .filter((key) => inputInitials[key].type === 'time')
                  .map((fieldKey) => {
                    const htmlForId = `validation-${fieldKey}`
                    return (
                      <FormGroup className='mb-3' key={`${fieldKey}`}>
                        <Label htmlFor={htmlForId}>
                          {t(inputInitials[fieldKey].label)}
                        </Label>
                        <Input
                          name={fieldKey}
                          type='select'
                          className='form-control form-control-sm mb-2'
                          id={htmlForId}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values[fieldKey] || ''}
                          invalid={
                            !!(
                              validation.touched[fieldKey] &&
                              validation.errors[fieldKey]
                            )
                          }
                        >
                          {}
                          <option>--</option>
                          {hours.map((hour) => (
                            <option key={hour}>{hour}:00</option>
                          ))}
                        </Input>
                        {validation.touched[fieldKey] &&
                        validation.errors[fieldKey] ? (
                          <FormFeedback type='invalid'>
                            {validation.errors[fieldKey]}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    )
                  })}
                <div className='mb-2'>
                  <b>{t('Filters')}</b>
                </div>
                {Object.keys(inputInitials)
                  ?.filter((d) => inputInitials[d].type === 'filter')
                  ?.map((fieldKey) => (
                    <InputFilters
                      key={fieldKey}
                      description={inputInitials[fieldKey]}
                      validation={validation}
                      validationKey={fieldKey}
                    />
                  ))}
              </Row>
              {!!updateError && (
                <Alert color='warning'>
                  <strong>{t(updateError.toString())}</strong>
                </Alert>
              )}
              {!!createError && (
                <Alert color='warning'>
                  <strong>{t(createError.toString())}</strong>
                </Alert>
              )}
              {updateLoading || createLoading ? (
                loading
              ) : (
                <Button
                  className='text-nowrap'
                  color='primary'
                  style={{ backgroundColor: '#405189' }}
                  type='submit'
                >
                  {id ? t('Update') : t('Create')}
                </Button>
              )}
            </Form>
            {id ? (
              <>
                <Row className='mt-3'>
                  <Col>
                    <Table striped responsive>
                      <thead>
                        <tr>
                          <th>{t('activity')}</th>
                          <th>{t('start time')}</th>
                          <th>{t('end time')}</th>
                          <th>{t('template')}</th>
                          <th>{t('start_day')}</th>
                          <th>{t('end_day')}</th>
                          <th>{t('type_of_phone_number')}</th>
                          <th>{t('Actions')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(inputInitials)
                          .filter(
                            (key) => inputInitials[key].type === 'actions'
                          )
                          .flatMap((key) =>
                            (strategy[key] || []).map((act) => ({
                              ...act,
                              activity: inputInitials[key].label || key
                            }))
                          )
                          .map((action) => {
                            return (
                              <tr key={action.id}>
                                <td>{t(action.activity) || '-'}</td>
                                <td>{action.start_time || '-'}</td>
                                <td>{action.end_time || '-'}</td>
                                <td>{action.template_id || '-'}</td>
                                <td>{action.dpd_start || '-'}</td>
                                <td>{action.dpd_end || '-'}</td>
                                <td>{t(action.phone_type) || '-'}</td>
                                <td>
                                  <span
                                    role='button'
                                    className='text-decoration-underline'
                                    onClick={() =>
                                      deleteClick(
                                        strategy.id,
                                        action.id,
                                        action.activity
                                      )
                                    }
                                  >
                                    <small>{t('Remove')}</small>
                                  </span>
                                </td>
                              </tr>
                            )
                          })}
                        {Object.keys(inputInitials)
                          .filter(
                            (key) => inputInitials[key].type === 'actions'
                          )
                          .every(
                            (key) =>
                              !strategy[key] || strategy[key].length === 0
                          ) && (
                          <tr>
                            <td colSpan='7' className='text-center'>
                              {t('No data')}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col>
                    <div>
                      <b>{t('Actions')}:</b>
                    </div>
                    <div className='mt-2'>
                      <Label className='mx-1' for='strategy_start'>
                        {t('start')}
                      </Label>
                      <Input
                        className='me-3'
                        id='strategy_start'
                        type='radio'
                        name='actions'
                        checked={check}
                        onChange={() => setCheck(!check)}
                      />
                      <Label className='mx-1' for='strategy_stop'>
                        {t('stop')}
                      </Label>
                      <Input
                        id='strategy_stop'
                        type='radio'
                        name='actions'
                        checked={!check}
                        onChange={() => setCheck(!check)}
                      />
                    </div>
                    <Button
                      color='primary'
                      style={{ backgroundColor: '#405189' }}
                      type='button'
                      onClick={() => actionStrategy()}
                    >
                      {t('Save')}
                    </Button>
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <InputItem
                    hours={hours}
                    actionsInfo={actionsInfo}
                    strategy_id={strategy.id}
                    updateComp={updateComp}
                    setUpdateComp={setUpdateComp}
                  />
                </Row>
              </>
            ) : null}
          </CardBody>
        </Card>
      </div>
    </>
  )
}
