import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Container,
  Alert,
  Spinner,
  Input
} from 'reactstrap'
import client from 'src/client'
import {
  useGetCollectorQuery,
  useLazyCheckSipIdQuery,
  usePostSegmentToCollectorMutation
} from 'src/services/collectors'
import ErrorPage, { DangerAlert } from '../ErrorPage'

export const CollectorEdit = () => {
  const { t } = useTranslation()
  const [id, setId] = useState(null)
  const [pageReady, setPageReady] = useState(false)
  const [paramError, setParamError] = useState(null)
  const [updateSuccess, setUpdateSuccess] = useState(false)
  const [stage, setStage] = useState(null)
  const [options, setOptions] = useState(null)
  const [isSipIdExists, setIsSipIdExists] = useState(null)
  const [sipIDText, setSipIDText] = useState('')

  const {
    data: collector,
    error: errorCollector,
    isLoading: isLoadingCollector
  } = useGetCollectorQuery(id, {
    skip: id === null
  })
  const [sipIdExistsCheck, { error: errorCheckSipId }] =
    useLazyCheckSipIdQuery()
  const [
    postCollector,
    { isLoading: isLoadingSegmentToCollector, error: errorSegmentToCollector }
  ] = usePostSegmentToCollectorMutation()

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const id = params.get('id')
    setId(id || null)
    client.getSegments().then((resolve) => {
      setOptions(resolve)
      setStage(resolve[0])
      setPageReady(true)
    })
  }, [])

  useEffect(() => {
    if (collector?.length > 0) {
      setSipIDText(collector[0].sip_username ?? '')
    }
  }, [collector])

  const onSubmit = async () => {
    const body = { stage_ids: [stage.id] }
    try {
      await postCollector({ id, sip_id: sipIDText, body }).unwrap()
      setUpdateSuccess(true)
    } catch (e) {
      setParamError(e)
    }
  }

  const checkSipIdExists = async () => {
    try {
      const data = await sipIdExistsCheck(sipIDText)
      setIsSipIdExists(data.data.data.exists)
    } catch (error) {
      console.error('Ошибка при проверке SIP ID:', error)
    }
  }

  document.title = t('Edit collector')
  const bread = <BreadCrumb title={`${t('Edit collector')}`} />
  const loading = <Spinner color='primary'>{t('Loading...')}</Spinner>

  if (paramError) {
    return (
      <React.Fragment>
        <UiContent />
        <div className='page-content'>
          <Container fluid={true}>
            {bread}
            <Alert color='warning'>
              <strong> {!!paramError && paramError.toString()} </strong>
            </Alert>
          </Container>
        </div>
      </React.Fragment>
    )
  }

  if (!pageReady || isLoadingCollector) {
    return (
      <React.Fragment>
        <UiContent />
        <div className='page-content'>
          <Container fluid={true}>
            {bread}
            {loading}
          </Container>
        </div>
      </React.Fragment>
    )
  }
  if (errorCollector) {
    return <ErrorPage title={t('Edit collector')} error={errorCollector} />
  }

  let metaBar = <MetaBar backLink={`/collectors`} />

  return (
    <React.Fragment>
      <UiContent />
      <div className='page-content'>
        {bread}
        <Card>
          <CardBody>
            {metaBar}
            <Container fluid={true} className='mt-4'>
              <Row>
                <Col lg={12}>
                  <Row>
                    <Col md='4'>
                      <Label>{t('Choose segment')}</Label>
                      <select
                        className='form-select form-select-sm'
                        onChange={(evv) =>
                          setStage(
                            options.find(
                              (item) => item.name === evv.target.value
                            )
                          )
                        }
                        value={stage.name}
                      >
                        {options.map((option) => (
                          <option key={option.name}>{option.name}</option>
                        ))}
                      </select>
                    </Col>
                  </Row>

                  <div className='mt-3 mb-3 w-100' style={{ maxWidth: 300 }}>
                    <Label htmlFor={'sip_id'}>{t('SIP ID')}</Label>
                    <div className='d-flex flex-row gap-2 align-items-center'>
                      <Input
                        type='text'
                        name='sip_id'
                        className='form-control form-control-sm'
                        id='sip_id'
                        onChange={(event) => setSipIDText(event.target.value)}
                        value={sipIDText}
                      />
                      <div
                        role='button'
                        className='text-decoration-underline'
                        onClick={checkSipIdExists}
                      >
                        <small>{t('Проверить на уникальность')}</small>
                      </div>
                    </div>
                  </div>
                  {errorCheckSipId && <DangerAlert error={errorCheckSipId} />}
                  {isSipIdExists && (
                    <Alert color='danger'>
                      <strong> {t('collector_sip_id_assigned')} </strong>
                    </Alert>
                  )}
                  {isSipIdExists === false && (
                    <Alert style={{ marginTop: '10px' }} color='success'>
                      <strong>
                        {' '}
                        {t(
                          '"Данный SIP ID свободен. Вы можете его использовать."'
                        )}{' '}
                      </strong>
                    </Alert>
                  )}
                  {updateSuccess && (
                    <Alert style={{ marginTop: '10px' }} color='success'>
                      <strong> {t('Operation success')} </strong>
                    </Alert>
                  )}
                  {errorSegmentToCollector && (
                    <DangerAlert error={errorSegmentToCollector} />
                  )}
                  <Button
                    disabled={isLoadingSegmentToCollector}
                    style={{ marginTop: '10px' }}
                    color='success'
                    onClick={onSubmit}
                  >
                    {t('Update')}
                  </Button>
                </Col>
              </Row>
            </Container>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
