import { LayoutItem, LayoutSubItem } from '../layoutItems.types'

export const getProfilesData = () => {
  const subItems: LayoutSubItem[] = [
    {
      id: 'profiles-all',
      label: 'View all',
      icon: ' ri-contacts-book-2-line',
      link: '/profiles'
    },
    {
      id: 'profile-view',
      link: '/profile-view',
      hidden: true,
      label: 'View'
    }
  ]

  const profilesData: LayoutItem = {
    id: 'profiles',
    label: 'Profiles',
    icon: 'ri-account-circle-line',
    subItems
  }

  return profilesData
}
