import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'reactstrap'
import Loader from '../Loader'
import { DangerAlert } from 'src/pages/Admin/ErrorPage'
import { toast } from 'react-toastify'
import {
  useGetLoansTagsQuery,
  useSetLoansTagsByIDMutation,
  useRemoveLoansTagsByIDMutation
} from 'src/services/tags'

interface EntityContentProps {
  entityId: string
}

const EntityContent: FC<EntityContentProps> = ({ entityId: loanId }) => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState<number>(-1)

  const {
    data: tags,
    isLoading: tagsIsLoading,
    error: tagsError
  } = useGetLoansTagsQuery()

  const [setLoansTags, { isLoading: setLoansTagsLoading }] =
    useSetLoansTagsByIDMutation()

  const [removeLoansTags, { isLoading: removeLoansTagsLoading }] =
    useRemoveLoansTagsByIDMutation()

  const setTags = async () => {
    try {
      await setLoansTags({ id: loanId, body: [selected] }).unwrap()
      toast.success(t('Successfully saved'), {
        autoClose: 2000
      })
    } catch (error: any) {
      toast.error(
        `${t('Error saving')}: ${error.status} ${error?.data?.detail}`,
        {
          autoClose: false
        }
      )
    }
  }

  const removeTags = async () => {
    try {
      await removeLoansTags({ id: loanId, body: [selected] }).unwrap()
      toast.success(t('Successfully delete'), {
        autoClose: 2000
      })
    } catch (error: any) {
      toast.error(
        `${t('Error deleting')}: ${error.status} ${error?.data?.detail}`,
        {
          autoClose: false
        }
      )
    }
  }

  return (
    <div>
      {tagsError && <DangerAlert error={tagsError} />}
      {tagsIsLoading && <Loader />}

      {!tagsIsLoading && (
        <div className='d-flex flex-column gap-2'>
          <select
            className='form-select form-select-sm'
            style={{ width: '80%', textOverflow: 'ellipsis' }}
            defaultValue={selected}
            onChange={(e) => setSelected(Number(e.target.value))}
          >
            <option value={-1}></option>
            {tags?.map((item) => (
              <option key={item.id} value={item.id}>
                {item.tag}
              </option>
            ))}
          </select>
          {setLoansTagsLoading || removeLoansTagsLoading ? (
            <Loader />
          ) : (
            <div className='d-flex gap-3'>
              <Button size='sm' onClick={setTags} disabled={selected === -1}>
                {t('Save')}
              </Button>
              <Button
                size='sm'
                onClick={removeTags}
                color='danger'
                disabled={selected === -1}
              >
                {t('Delete')}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default EntityContent
