import { useTranslation } from 'react-i18next'

import CircleChart from './Chart'

const configDefault = {
  amount_principal_debt: { color: '#ed5e5e' }, //danger
  amount_interest_debt: { color: '#BADBAD' }, //green
  amount_premium_account_debt: { color: '#B39F7A' }, //coffee
  amount_insurance_debt: { color: '#EFCDB8' }, //beige
  amount_juridical_debt: { color: '#e0e0e0' }, //grey
  amount_telemedicine_debt: { color: '#fc7cf0' }, //lite pink
  amount_penalty_debt: { color: '#7FC7FF' }, //blue
  amount_principal_paid: { color: '#6691e7' }, //primary
  amount_issuance_fee_paid: { color: '#e8bc52' }, //warning
  amount_penalty_paid: { color: '#50c3e6' }, //info
  amount_interest_paid: { color: '#13c56b' }, //success
  amount_ext_fee_paid: { color: '#865ce2' }, //secondary
  amount_premium_account_paid: { color: '#363d48' }, //dark
  amount_insurance_paid: { color: '#405189' }, //indigo
  amount_juridical_paid: { color: '#7d050d' }, //cherry
  amount_telemedicine_paid: { color: '#0e5e07' }, //dark greed
  amount_repayment_fee_paid: { color: '#f672a7' }, //pink
  amount_writeoff: { color: '#000' }, // black
  amount_paid_overpayment: { color: '#d0fe1d' } //lime
}

const Widget = ({ loan, style, config = configDefault }) => {
  const { t } = useTranslation()

  return (
    <div
      id='portfolio_donut_charts'
      dir='ltr'
      className='d-flex flex-xxl-column flex-xl-row gap-0 align-items-center'
      style={style}
    >
      {loan && (
        <CircleChart
          loanId={loan.id}
          dataColors={`[
            ${Object.keys(config).map((item) => `"${config[item].color}"`)}
          ]`}
          series={Object.keys(config).map((item) => loan[item] || 0)}
          labels={Object.keys(config).map((item) => t(item))}
          total={loan.amount_debt}
        />
      )}
      <div className='align-items-center mx-n5'>
        <div className='vstack'>
          {Object.keys(config).map((item, key) => (
            <div key={key}>
              <div className='flex-grow-1 ms-2'>
                <p className='fs-12 mb-0 text-muted'>
                  <i
                    className={'mdi mdi-circle fs-10 align-middle me-1 '}
                    style={{ color: config[item].color }}
                  ></i>
                  {t(item)}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Widget
