import BreadCrumb from 'src/Components/Common/BreadCrumb'
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Row
} from 'reactstrap'
import MetaBar from 'src/Components/Common/MetaBar'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ErrorPage from '../../ErrorPage'
import { useState } from 'react'
import {
  ErrorCategory,
  useGetErrorCategoriesQuery,
  useUpdateErrorCategoriesMutation
} from 'src/services/claims'
import { useFormik } from 'formik'
import * as Yup from 'yup'

export const ErrorCategorySetting = () => {
  const { t } = useTranslation()
  const [parentCategoryId, setParentCategoryId] = useState(0)
  const [subCategoryId, setSubCategoryId] = useState(0)

  const {
    data: categories,
    isLoading: categoriesLoading,
    error: categoriesError
  } = useGetErrorCategoriesQuery()

  const [
    updateErrorCategories,
    { isLoading: isUpdating, error: updateError, isSuccess: isUpdateSuccess }
  ] = useUpdateErrorCategoriesMutation()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: 0,
      parent_category_id: 0,
      category_name: ''
    },
    validationSchema: Yup.object().shape({
      parent_category_id: Yup.number().nullable(),
      category_name: Yup.string()
    }),
    onSubmit: async (values) => {
      const data: ErrorCategory = {
        id: values.id,
        name: values.category_name,
        parent_id: parentCategoryId
      }
      try {
        await updateErrorCategories(data)
      } catch (err) {
        console.log('Error in onSubmit:', err)
      }
    }
  })

  const title = t('category_of_errors')

  if (categoriesError) {
    return <ErrorPage title={title} error={categoriesError} />
  }
  return (
    <div className='page-content'>
      <BreadCrumb title={title} pageTitle={undefined} link={undefined} />
      <Card>
        <CardBody>
          <MetaBar
            createLink={'/category-of-errors-create'}
            backLink={undefined}
            editLink={undefined}
            entity={undefined}
            buttons={undefined}
            disableBorder={undefined}
          />
          <Form
            className='needs-validation mt-4'
            onSubmit={(e) => {
              e.preventDefault()
              formik.handleSubmit()
              return false
            }}
          >
            <Col className='col-6' style={{ maxWidth: 300 }}>
              <Label for='category'>{t('select_category')}</Label>
              <div style={{ display: 'flex' }}>
                <Input
                  id='category'
                  type='select'
                  className='form-control form-control-sm me-3'
                  style={{ width: '350px' }}
                  onChange={(e) => {
                    setParentCategoryId(parseInt(e.target.value))

                    const currentSubcategory = categories?.find(
                      (el) => el.parent_id === parseInt(e.target.value)
                    )
                    formik.setFieldValue('id', currentSubcategory?.id)
                    formik.setFieldValue(
                      'category_name',
                      currentSubcategory?.name
                    )
                  }}
                  value={parentCategoryId}
                  disabled={isUpdating || categoriesLoading}
                >
                  <option value={0}>-</option>
                  {categories
                    ?.filter((el) => el.parent_id == null)
                    .map((top) => {
                      return (
                        <option key={top.id} value={top.id}>
                          {top.name}
                        </option>
                      )
                    })}
                </Input>
                {!!parentCategoryId && (
                  <Link
                    to={`/category-of-errors-create/?id=${parentCategoryId}`}
                    className='link-success fs-20'
                    style={{ alignSelf: 'center' }}
                  >
                    <i className='ri-edit-2-line'></i>
                  </Link>
                )}
              </div>
            </Col>
            <Row className='mt-3 mb-3'>
              <Col className='col-6' style={{ maxWidth: 300 }}>
                <Label for='subcategory'>{t('select_subcategory')}</Label>
                <div style={{ display: 'flex' }}>
                  <select
                    id='subcategory'
                    name='subcategory'
                    className='form-control form-control-sm me-3'
                    onChange={(e) => {
                      setSubCategoryId(parseInt(e.target.value))
                      const currentSubCategory = categories?.find(
                        (el) => el.id === parseInt(e.target.value)
                      )
                      formik.setFieldValue('id', currentSubCategory?.id)
                      formik.setFieldValue(
                        'category_name',
                        currentSubCategory?.name
                      )
                    }}
                    onBlur={formik.handleBlur}
                    value={subCategoryId}
                    disabled={
                      isUpdating ||
                      categoriesLoading ||
                      !parentCategoryId ||
                      !formik.values.category_name
                    }
                  >
                    <option hidden disabled value='' />
                    {categories
                      ?.filter(
                        (option) => option.parent_id === parentCategoryId
                      )
                      .map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                  </select>

                  {formik.values.category_name && (
                    <Link
                      to={`/category-of-errors-create/?id=${formik.values.id}`}
                      className='link-success fs-20'
                      style={{ alignSelf: 'center' }}
                    >
                      <i className='ri-edit-2-line'></i>
                    </Link>
                  )}
                </div>
              </Col>
            </Row>
            {updateError && (
              <Alert color='warning'>
                <strong> {JSON.stringify(updateError)} </strong>
              </Alert>
            )}
            {isUpdateSuccess && (
              <Alert color='success'>
                <strong> {t('Operation success')} </strong>
              </Alert>
            )}
            <Button
              className='text-nowrap'
              color='primary'
              style={{ backgroundColor: '#405189' }}
              type='submit'
              disabled={
                isUpdating ||
                categoriesLoading ||
                !parentCategoryId ||
                !formik.values.id
              }
            >
              {t('Update')}
            </Button>
          </Form>
        </CardBody>
      </Card>
    </div>
  )
}
