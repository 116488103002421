import { LayoutItem, LayoutSubItem } from '../layoutItems.types'

export const getPromotionsData = () => {
  const subItems: LayoutSubItem[] = [
    {
      id: 'view-all-promotions',
      label: 'View all',
      link: '/promotions'
    }
  ]

  const promotionsData: LayoutItem = {
    id: 'promotions',
    label: 'Promotions',
    icon: 'bx bxs-offer',
    subItems
  }

  return promotionsData
}
