import { useState, useEffect, useMemo } from 'react'
import { Row, Col, Input } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import Table4Columns from './Table4Columns'
import Widget from './LoanWidget'
import Client from '../../../client'
import {
  useGetLoanCardQuery,
  useGetExternalServicesQuery
} from 'src/services/loans'
import { ConfirmButton } from '../ConfirmButton'
import {
  useDownloadPaymentScheduleMutation,
  useSetReloadContractMutation
} from 'src/services/payments'

const columnsDescriptionDefault = (productCode) => [
  ['id'],
  ['status'],
  ['loan_issue_date', 'loan_close_date'],
  ['loan_issue_card'],
  ['term', 'loan_repayment_date'],
  ['amount_writeoff', 'prolongation_count'],
  [productCode === 'IL' ? 'repayment_today' : 'amount_debt', 'dpd'],

  ['amount_principal_debt'],
  ['amount_interest_debt'],
  ['amount_insurance_debt'],
  ['amount_telemedicine_debt'],
  ['amount_juridical_debt'],
  ['amount_premium_account_debt'],
  ['amount_penalty_debt'],

  ['amount_interest_accrued', 'amount_interest_paid'],
  ['amount_principal_accrued', 'amount_principal_paid'],
  ['amount_issuance_fee_accrued', 'amount_issuance_fee_paid'],
  ['amount_premium_account_accrued', 'amount_premium_account_paid'],
  ['amount_insurance_accrued', 'amount_insurance_paid'],
  ['amount_telemedicine_accrued', 'amount_telemedicine_paid'],
  ['amount_juridical_accrued', 'amount_juridical_paid'],
  ['amount_penalty_accrued', 'amount_penalty_paid'],
  ['amount_ext_fee_accrued', 'amount_ext_fee_paid'],
  ['amount_repayment_fee_accrued', 'amount_repayment_fee_paid'],

  ['interest', 'interest_rate_overdue'],
  ['amount_overpayment'],
  ['extension_available', 'amount_for_extension']
]

const fieldsDescription = (isInstallment) => ({
  id: { isLink: true, linkFieldName: 'loan_link' },
  new_line: { isEmpty: true, newLine: true },

  term: { label: 'approved_period' },

  loan_repayment_date: { fieldName: 'repayment_date', isDate: true },
  loan_close_date: { fieldName: 'closing_date', isDate: true },
  loan_issue_date: { fieldName: 'issue_date', isDate: true },

  amount_for_extension: { isAmount: true },
  amount_writeoff: { isAmount: true },
  amount_debt: { isAmount: true },

  amount_principal_accrued: { isAmount: true },
  amount_principal_paid: { isAmount: true },

  amount_interest_accrued: { isAmount: true },
  amount_interest_paid: { isAmount: true },

  amount_issuance_fee_accrued: { isAmount: true },
  amount_issuance_fee_paid: { isAmount: true },

  amount_penalty_accrued: { isAmount: true },
  amount_penalty_paid: { isAmount: true },

  amount_ext_fee_accrued: { isAmount: true },
  amount_ext_fee_paid: { isAmount: true },

  amount_repayment_fee_accrued: { isAmount: true },
  amount_repayment_fee_paid: { isAmount: true },

  amount_premium_account_accrued: { isAmount: true },
  amount_premium_account_paid: { isAmount: true },

  amount_insurance_accrued: { isAmount: true },
  amount_insurance_paid: { isAmount: true },

  amount_juridical_accrued: { isAmount: true },
  amount_juridical_paid: { isAmount: true },

  amount_telemedicine_accrued: { isAmount: true },
  amount_telemedicine_paid: { isAmount: true },

  amount_overpayment: {
    label: 'amount_overpayment',
    isAmount: true,
    fieldName: isInstallment ? 'over_payment' : 'amount_overpayment'
  },

  interest: { label: 'loan_interest', isAmount: true, decimals: 3 },
  interest_rate_overdue: { isAmount: true, decimals: 3 },

  sale_date: { fieldName: 'sale_date', isDate: true },
  company_short_name: { label: 'company_short_name' },
  repayment_today: { isAmount: true },

  amount_principal_debt: { isAmount: true },
  amount_interest_debt: { isAmount: true },
  amount_juridical_debt: { isAmount: true },
  amount_telemedicine_debt: { isAmount: true },
  amount_premium_account_debt: { isAmount: true },
  amount_insurance_debt: { isAmount: true },
  amount_penalty_debt: { isAmount: true },

  activation_date: { isDate: true },
  expiration_date: { isDate: true },
  amount_to_return: { isAmount: true },
  main_body_writeoff: { label: 'main_body_writeoff_promo', isPercent: true },
  interest_writeoff: { label: 'interest_writeoff_promo', isPercent: true }
})

const generateOperationsData = (entity, operationTypes, categories) => {
  const initialResult = categories.reduce((acc, category) => {
    operationTypes.forEach((type) => {
      acc[`amount_${type.toLowerCase()}_${category}`] = 0
    })
    return acc
  }, {})

  const result = { ...initialResult }

  categories.forEach((category) => {
    entity?.operations?.[category]?.forEach((value) => {
      const key = `amount_${value.operation_type.toLowerCase()}_${category}`

      if (result[key] !== undefined) {
        result[key] = value.amount
      }
    })
  })

  return result
}

const EntityContent = ({ entity, columnsDescription }) => {
  const { data: loan_issue_card } = useGetLoanCardQuery(entity?.id, {
    skip: entity?.id == null
  })

  const isInstallment = useMemo(
    () => entity?.product_copy?.product_code === 'IL',
    [entity?.product_copy?.product_code]
  )

  const { data: externalServicesData } = useGetExternalServicesQuery(
    entity?.id,
    {
      skip: entity?.id == null
    }
  )
  const columns = useMemo(
    () => columnsDescriptionDefault(isInstallment),
    [isInstallment]
  )

  const { t } = useTranslation()
  const [detailsUrl, setDetailsUrl] = useState('')
  const [dropPA, setDropPA] = useState(false)
  const [dropInsurance, setDropInsurance] = useState(false)
  const [dropJuridical, setDropJuridical] = useState(false)
  const [dropTelemedicine, setDropTelemedicine] = useState(false)

  const [contractStatus, setContractStatus] = useState('')
  const [contractError, setContractError] = useState('')
  const [contractLoading, setContractLoading] = useState(false)
  const [operationsList, setOperationsList] = useState({})
  const [externalServicesAmount, setExternalServicesAmount] = useState({})
  const [externalServicesLoading, setExternalServicesLoading] = useState(false)
  const [externalServicesId, setExternalServicesId] = useState({})
  const [externalServicesRelaod, setExternalServicesRelaod] = useState([])

  const [setReloadContract] = useSetReloadContractMutation()
  const [downloadSchedule, { data: scheduleFileUrl }] =
    useDownloadPaymentScheduleMutation()

  useEffect(() => {
    if (externalServicesData?.length) {
      const externalServicesIdList = {
        juridical: '',
        telemedicine: '',
        insurance: ''
      }
      const externalServices = {
        amount_insurance_debt: 0,
        amount_insurance_paid: 0,
        amount_insurance_accrued: 0,
        amount_juridical_debt: 0,
        amount_juridical_paid: 0,
        amount_juridical_accrued: 0,
        amount_telemedicine_debt: 0,
        amount_telemedicine_paid: 0,
        amount_telemedicine_accrued: 0
      }
      externalServicesData?.forEach((value) => {
        if (value.tag === 'juridical') {
          externalServices.amount_juridical_accrued = value.amount
          externalServices.amount_juridical_paid = value.amount_paid
          externalServices.amount_juridical_debt =
            value.amount - value.amount_paid
          externalServicesIdList.juridical = value.id
        }
        if (value.tag === 'insurance') {
          externalServices.amount_insurance_accrued = value.amount
          externalServices.amount_insurance_paid = value.amount_paid
          externalServices.amount_insurance_debt =
            value.amount - value.amount_paid
          externalServicesIdList.insurance = value.id
        }
        if (value.tag === 'telemedicine') {
          externalServices.amount_telemedicine_accrued = value.amount
          externalServices.amount_telemedicine_paid = value.amount_paid
          externalServices.amount_telemedicine_debt =
            value.amount - value.amount_paid
          externalServicesIdList.telemedicine = value.id
        }
      })
      setExternalServicesAmount(externalServices)
      setExternalServicesId(externalServicesIdList)
    } else {
      const externalServices = {
        amount_juridical_debt: 0,
        amount_telemedicine_debt: 0
      }
      setExternalServicesAmount(externalServices)
    }
    setExternalServicesLoading(true)
  }, [entity, externalServicesData?.length])

  useEffect(() => {
    const externalReload = []
    if (entity.externalServices?.length) {
      if (dropJuridical && externalServicesId.juridical)
        externalReload.push(externalServicesId.juridical)
      if (dropInsurance && externalServicesId.insurance)
        externalReload.push(externalServicesId.insurance)
      if (dropTelemedicine && externalServicesId.telemedicine)
        externalReload.push(externalServicesId.telemedicine)
    }
    setExternalServicesRelaod(externalReload)
  }, [
    dropJuridical,
    dropInsurance,
    dropTelemedicine,
    externalServicesId,
    entity
  ])

  useEffect(() => {
    console.log('loan =>', entity)

    return () => {
      if (detailsUrl) URL.revokeObjectURL(detailsUrl)
    }
  }, [detailsUrl, entity, entity.id])

  let ext_details = {}
  if (entity.ext_details) {
    ext_details = {
      extension_available: entity.ext_details.extension_available,
      amount_for_extension: entity.ext_details.amount_for_extension
    }
  }
  const saveContract = () => {
    setContractStatus('')
    setContractError('')
    setContractLoading(true)
    setReloadContract({
      loanId: entity.id,
      body: {
        drop_pa: entity.externalServices?.length ? false : dropPA,
        drop_insurance: entity.externalServices?.length ? false : dropInsurance,
        drop_external_services_ids: externalServicesRelaod
      }
    })
      .then((res) => {
        setContractStatus(t('saved'))
      })
      .catch((err) => {
        setContractError(err.toString())
      })
      .finally(() => setContractLoading(false))
  }

  const downloadDetails = (
    url,
    fileName = `credit-details-${entity?.id}.xlsx`
  ) => {
    console.log('file url =>', url)

    const a = document.createElement('a')
    document.body.appendChild(a)
    a.href = url
    a.download = fileName
    a.click()
    document.body.removeChild(a)
    console.log('SUCC')
  }

  const download = (loanId) => {
    if (detailsUrl) {
      downloadDetails(detailsUrl)
      return
    }

    switch (entity?.product_copy?.product_code) {
      case 'il':
      case 'IL':
        Client.getILLoanCredit(loanId)
          .then((file) => file.blob())
          .then((blob) => window.URL.createObjectURL(blob))
          .then((url) => {
            setDetailsUrl(url)
            downloadDetails(url)
          })
          .catch((err) => {
            console.log(err)
          })
        break

      case 'pdl':
      case 'PDL':
      default:
        Client.getLoanCredit(loanId)
          .then((file) => file.blob())
          .then((blob) => window.URL.createObjectURL(blob))
          .then((url) => {
            setDetailsUrl(url)
            downloadDetails(url)
          })
          .catch((err) => {
            console.log(err)
          })
        break
    }
  }

  const downloadPaymentschedule = async (loanId) => {
    if (scheduleFileUrl) {
      downloadDetails(scheduleFileUrl, `schedule.csv`)
      return
    }
    const blob = await downloadSchedule(loanId)
    if ('data' in blob) downloadDetails(blob.data, `schedule.csv`)
  }

  useEffect(() => {
    const operationTypes = ['principal', 'interest', 'penalty', 'overpayment']
    const categories = ['accrued', 'debt', 'paid', 'writeoff']

    const tableData = generateOperationsData(entity, operationTypes, categories)

    setOperationsList(tableData)
  }, [entity])

  return (
    <Row>
      <Col xxl='8' xl='12' className='order-2 order-xxl-1'>
        <div className='d-flex align-items-center gap-3'>
          <div
            className='note text-info text-decoration-underline mt-2 mb-3'
            role='button'
            onClick={() => download(entity?.id)}
          >
            {t('download_loan_detail')}
          </div>
          {isInstallment && (
            <div
              className='note text-info text-decoration-underline mt-2 mb-3'
              role='button'
              onClick={() => downloadPaymentschedule(entity?.id)}
            >
              {t('download_payment_schedule')}
            </div>
          )}
        </div>
        <div className='d-flex flex-row align-items-center gap-3 mb-3 rounded border border-1 p-2'>
          <div className='d-flex flex-row align-items-center'>
            <Input
              id='drop-pa-id'
              type='checkbox'
              checked={dropPA}
              className='me-2'
              onChange={(e) => {
                setDropPA(e.target.checked)
                setContractError('')
                setContractStatus('')
              }}
            />
            <div className='mt-1'>{t('drop_pa')}</div>
          </div>
          <div className='d-flex flex-row align-items-center'>
            <Input
              id='drop-insurance-id'
              type='checkbox'
              checked={dropInsurance}
              className='me-2'
              onChange={(e) => {
                setDropInsurance(e.target.checked)
                setContractError('')
                setContractStatus('')
              }}
            />
            <div className='mt-1'>{t('drop_insurance')}</div>
          </div>
          <div className='d-flex flex-row align-items-center'>
            <Input
              id='drop-juridical-id'
              type='checkbox'
              checked={dropJuridical}
              className='me-2'
              onChange={(e) => {
                setDropJuridical(e.target.checked)
                setContractError('')
                setContractStatus('')
              }}
            />
            <div className='mt-1'>{t('drop_juridical')}</div>
          </div>
          <div className='d-flex flex-row align-items-center'>
            <Input
              id='drop-telemedicine-id'
              type='checkbox'
              checked={dropTelemedicine}
              className='me-2'
              onChange={(e) => {
                setDropTelemedicine(e.target.checked)
                setContractError('')
                setContractStatus('')
              }}
            />
            <div className='mt-1'>{t('drop_telemedicine')}</div>
          </div>
          <ConfirmButton
            size='sm'
            color='secondary'
            block={false}
            disabled={contractLoading}
            onConfirm={saveContract}
          >
            {t('Save')}
          </ConfirmButton>
          {contractLoading && (
            <small className='text-warning'>{t('loading')}</small>
          )}
          {contractStatus && (
            <small className='text-success'>{contractStatus}</small>
          )}
          {contractError && (
            <small className='text-danger'>{contractError}</small>
          )}
        </div>
        <Table4Columns
          columnsDescription={columnsDescription || columns}
          fieldsDescription={fieldsDescription(
            entity?.product_copy?.product_code === 'IL'
          )}
          className='table-sm align-middle mb-0'
          entity={{
            ...operationsList,
            ...entity,
            loan_issue_card,
            loan_link: `/loan-view?id=${entity.id}`,
            ...ext_details,
            ...externalServicesAmount
          }}
        />
      </Col>
      <Col
        xxl='4'
        xl='12'
        className='order-1 order-xxl-2 d-flex align-items-center justify-content-center'
      >
        {!!entity && externalServicesLoading && (
          <Widget
            loan={{ ...entity, ...externalServicesAmount }}
            style={{ marginLeft: -70 }}
          />
        )}
      </Col>
    </Row>
  )
}

export default EntityContent
