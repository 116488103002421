import React, { memo, useMemo } from 'react'
import { LayoutItem, LayoutSubItem } from '../layoutMenuData/layoutItems.types'
import { useTranslation } from 'react-i18next'
import { Collapse, Row } from 'reactstrap'
import { VerticalLayoutSubItem } from './VerticalLayoutSubItem'
import { Link, useLocation } from 'react-router-dom'

interface IVerticalLayoutItem {
  item: LayoutItem
  isOpened: boolean
  onToggle: (itemId: string) => void
}

const VerticalLayoutItemNoMemo: React.FC<IVerticalLayoutItem> = ({
  item,
  isOpened,
  onToggle
}) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { icon, id, label, subItems } = item

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault()
    onToggle(id)
  }

  const activeClass = useMemo(() => {
    const isSubItemActive = (subItem: LayoutSubItem) =>
      pathname === subItem.link ||
      subItem.childItems?.some((childItem) => childItem.link === pathname)
    return subItems.some(isSubItemActive) ? 'active' : ''
  }, [subItems, pathname])

  return (
    <li className='nav-item'>
      <Link
        onClick={handleClick}
        to={'/#'}
        className={`nav-link menu-link ${activeClass}`}
        data-bs-toggle='collapse'
      >
        <i className={icon}></i>
        <span data-key='t-apps'>{t(label)}</span>
      </Link>
      <Collapse className='menu-dropdown' id='sidebarApps' isOpen={isOpened}>
        <Row>
          {subItems?.map(
            (subItem) =>
              !subItem.hidden && <VerticalLayoutSubItem subItem={subItem} />
          )}
        </Row>
      </Collapse>
    </li>
  )
}

const VerticalLayoutItem = memo(VerticalLayoutItemNoMemo)
export { VerticalLayoutItem }
