import { api, omitBlankEntries } from './api'
import { createSocketsFactory } from './chats'

type Call = any

export type CallPerson = {
  id: number
  is_deleted: boolean
  name: string
  type: string
  type_id: number
}

export type Group = {
  id: number
  name: string
  created_at: string
}

export type CallReason = {
  id: string
  name: string
  type: string
  type_id: number
}

export type CallResult = {
  id: string
  name: string
}

type ExtraCondition = {
  name: 'string'
  label: 'string'
  validOperators: ['string']
  valueType: string
}

export type CallSetting = {
  id: number
  client_type: string
  result_type: string
  client_type_id: number
  result_type_id: number
  need_reason: boolean
  need_promise_amount: boolean
  need_promise_date: boolean
  need_comment: boolean
  is_default: boolean
  is_success: boolean
}

export type QueuesResponse = {
  groups: any[]
  id: number
  order_promise_date: {
    order: number
    priority: number
  }
  promise_date: Date[]
  queue_name: string
  seq: number
  users: string[]
}

export type QueueOrder = {
  order: number
  priority: number
}

export type QueueResponse = {
  id: number
  queue_name: string
  users: string[] | []
  groups: number[] | []
  seq: number
  income?: number[]
  login_date?: string[]
  promise_date?: string[]
  day_past_due_promise?: number[]
  prolongation_count?: number[]
  loan_stage?: number[]
  collection_score?: number[]
  amount_debt?: number[]
  timezone?: string[]
  order_income?: QueueOrder
  order_timezone?: QueueOrder
  order_amount_debt?: QueueOrder
  order_dpd?: QueueOrder
  order_collection_score?: QueueOrder
  order_loan_stage?: QueueOrder
  order_day_past_due_promise?: QueueOrder
  order_promise_date?: QueueOrder
  order_login_date?: QueueOrder
  order_prolongation_count?: QueueOrder
  dpd?: unknown
}

type QueuesParams = {
  keyword?: string
  limit: number
  offset: number
}

type QueueParams = {
  queue_params_id: number
}

export type QueueBody = {
  amount_debt?: number[]
  collection_score?: number[]
  day_past_due_promise?: number[]
  dpd?: number[]
  groups?: [4]
  income?: number[]
  loan_stage?: number[]
  login_date?: string[]
  order_income?: { order: number; priority: number }
  order_login_date?: { order: number; priority: number }
  order_promise_date?: { order: number; priority: number }
  prolongation_count?: number[]
  promise_date?: string[]
  queue_name: string
  seq: number
  timezone?: string[]
  users?: string[]
}

export type Segment = {
  accrual_stop: number | null
  auto_sale: boolean | null
  debt_load: number | null
  extra_conditions: string | null
  id: number
  ignore_tags_mismatch: boolean | null
  ip_count: number | null
  is_auto: boolean | null
  loans_per_user: number | null
  max_dpd: number
  max_score: number | null
  min_dpd: number
  min_score: number | null
  name: string
  promotion_id: number | null
  request_type: number
  tags: Record<string, unknown>
}

export type Collector = {
  blocked_cause: string | null
  company: string | null
  email: string | null
  group_id: number
  id: string
  midname: string
  name: string
  phone: string | null
  position: string | null
  sip_password: string | null
  sip_username: string | null
  state: string | null
  status: string
  surname: string
  username: string
}

interface SortRule {
  priority: number
  order: number
}

export interface SortRules {
  order_income: SortRule
  order_login_date: SortRule
  order_promise_date: SortRule
  order_day_past_due_promise: SortRule
  order_prolongation_count: SortRule
  order_loan_stage: SortRule
  order_collection_score: SortRule
  order_dpd: SortRule
  order_amount_debt: SortRule
  order_timezone: SortRule
}

export type CallList = {
  id: number
  created_at: string
  updated_at: string
  name: string
  stages: number[]
  users: string[]
  phones_priority: PhonesPriority
  sort_rules: SortRules
  extra_settings: { queue_id: string } | null
  is_federal_law_limited: boolean
  exclude_gray_tag: boolean
  is_active: boolean
  recall_weekdays: { [key: string]: number } | null
  tags: number[]
}

export type PhonesPriority = {
  mobile: number
  thirdparty: number
  work: number
  extra: number
}

export type CallListRequest = {
  name: string
  stages?: number[]
  users?: string[]
  phones_priority?: PhonesPriority | null
  sort_rules?: Partial<SortRules> | null
  extra_settings: { queue_id: string } | null
  is_federal_law_limited?: boolean
  exclude_gray_tag?: boolean
  is_active?: boolean
  recall_weekdays: any | null
  tags?: number[]
}

type WebSocketMessage = CommandMessage | LoginMessage

interface CommandMessage {
  type: 'COMMAND'
  payload: Message
}

interface LoginMessage {
  type: 'LOGIN'
  payload: {
    login: string
    password: string
  }
}

export interface Message {
  event_type: string
  loan_id: number
  agent_id: string
  action: string
  phone: string
  attempt_id: string
}

interface SubscribeToCallsResponse {
  messages: Message[]
  login?: string
  password?: string
}

type UploadFileResecuringLoans = {
  successful: boolean
  message: string
}

export type LoansAssignmentList = {
  id: string
  created_at: string
  file_name: string
  status: string
  description: string
}

export const getCallsSocket = createSocketsFactory(
  process.env.REACT_APP_CALLS_SOCKET ||
    'wss://crm.dev.cyberprod.ru/crm/v1/calling/ws'
)

export const {
  useGetCallsQuery,
  useGetCallPersonsQuery,
  useGetGroupsQuery,
  useUpdateGroupMutation,
  useDeleteSegmentMutation,
  useGetCallReasonsByProfileIdQuery,
  useAddCallResultMutation,
  useAddCallEntityMutation,
  useUpdateCallEntityMutation,
  useDeleteCallEntityMutation,
  useGetCallSettingsQuery,
  useAddCallSettingsMutation,
  useUpdateCallSettingsMutation,
  useDeleteCallSettingsMutation,
  useGetCallResultsByProfileIdQuery,
  useGetSegmentExtraConditionsQuery,
  useGetSegmentCsvMutation,
  useArchiveEntityMutation,
  useGetQueuesQuery,
  useGetQueueQuery,
  useDeleteQueueMutation,
  useCreateQueueMutation,
  useUpdateQueueMutation,
  useGetCallListsQuery,
  useLazyGetGroupCollectorsQuery,
  useGetSegmentsQuery,
  useCreateCallListMutation,
  useGetCallListQuery,
  useUpdateCallListMutation,
  useDeleteCallListMutation,
  useSubscribeToCallsQuery,
  useReUploadCallListMutation,
  useGetCallListCsvMutation,
  useSendInfoMessageMutation,
  useGetCalledTodayLoansQuery,
  useUploadFileResecuringLoansMutation,
  useGetLoansAssignmentListQuery
} = api.injectEndpoints({
  endpoints: (builder) => ({
    getCalls: builder.query<Call[], string>({
      query: (id) => `crm/v1/calling/${id}`,
      providesTags: (_result, _err, id) => [{ type: 'Calls', id }]
    }),
    getCallPersons: builder.query<CallPerson[], void>({
      query: () => `crm/v1/calling/person`,
      providesTags: ['CallPerson']
    }),
    getGroups: builder.query<Group[], void>({
      query: () => `crm/v1/calling/groups`,
      providesTags: (result = []) => [
        ...result.map(({ id }) => ({ type: 'Groups', id }) as const),
        { type: 'Groups' as const, id: 'LIST' }
      ]
    }),
    getGroupCollectors: builder.query<Collector[], string>({
      query: (id) => `crm/v1/calling/groups` + `/${id}`
    }),
    getSegments: builder.query<Segment[], void>({
      query: () => `crm/v1/calling/stages`
    }),
    getCallReasonsByProfileId: builder.query<
      CallReason[],
      Partial<{ person_id: number }> | void
    >({
      query: (params) => ({
        url: `crm/v1/calling/reason`,
        params: params || undefined
      }),
      providesTags: ['CallReason']
    }),
    getCallResultsByProfileId: builder.query<CallResult[], any>({
      query: (params) => ({
        url: `crm/v1/calling/result`,
        params: params || undefined
      }),
      providesTags: ['CallResult']
    }),
    archiveEntity: builder.mutation<void, string>({
      query: (id) => ({
        url: `crm/v1/calling/queue-params/${id}`,
        method: 'POST'
      })
    }),
    addCallResult: builder.mutation<void, Partial<any>>({
      query: (body) => ({
        url: `crm/v1/calling/call-result`,
        method: 'POST',
        body: omitBlankEntries(body)
      }),
      invalidatesTags: ['Calls']
    }),
    updateGroup: builder.mutation<
      void,
      Pick<Group, 'id'> & { user_ids: string[]; new_name: string }
    >({
      query: ({ id, ...body }) => ({
        url: `crm/v1/calling/groups/${id}`,
        method: 'PUT',
        body: omitBlankEntries(body)
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: 'Groups', id: request.id }
      ]
    }),
    deleteSegment: builder.mutation<any, string | null>({
      query: (id) => ({
        url: `crm/v1/calling/stages/${id}`,
        method: 'DELETE'
      })
    }),
    getSegmentExtraConditions: builder.query<ExtraCondition[], void>({
      query: () => ({
        url: `crm/v1/calling/stages/extra-conditions`
      })
    }),
    addCallEntity: builder.mutation<void, Partial<any>>({
      query: (body) => ({
        url: `crm/v1/calling/entity`,
        method: 'POST',
        body: omitBlankEntries(body)
      }),
      invalidatesTags: ['CallPerson', 'CallReason']
    }),
    updateCallEntity: builder.mutation<void, Partial<any>>({
      query: (body) => ({
        url: `crm/v1/calling/entity`,
        method: 'PUT',
        body: omitBlankEntries(body)
      }),
      invalidatesTags: ['CallPerson', 'CallReason']
    }),
    deleteCallEntity: builder.mutation<any, Partial<any>>({
      query: (body) => ({
        url: `crm/v1/calling/entity`,
        method: 'DELETE',
        body: omitBlankEntries(body)
      }),
      invalidatesTags: ['CallPerson', 'CallReason', 'CallSettings']
    }),
    getCallSettings: builder.query<
      CallSetting[],
      Partial<{ person_id: number }> | void
    >({
      query: (params) => ({
        url: `crm/v1/calling/settings`,
        params: params || undefined
      }),
      providesTags: ['CallSettings']
    }),
    addCallSettings: builder.mutation<void, Partial<any>>({
      query: (body) => ({
        url: `crm/v1/calling/settings`,
        method: 'POST',
        body: omitBlankEntries(body)
      }),
      invalidatesTags: ['CallSettings']
    }),
    updateCallSettings: builder.mutation<void, Partial<any>>({
      query: (body) => {
        const { id, ...rest } = body
        return {
          url: `crm/v1/calling/settings?settings_id=${id}`,
          method: 'PUT',
          body: omitBlankEntries(rest)
        }
      },
      invalidatesTags: ['CallSettings']
    }),
    deleteCallSettings: builder.mutation<any, Partial<any>>({
      query: (body) => ({
        url: `crm/v1/calling/settings`,
        method: 'DELETE',
        body: omitBlankEntries(body)
      }),
      invalidatesTags: ['CallSettings']
    }),
    getSegmentCsv: builder.mutation<Blob, number>({
      query: (stage_id) => ({
        url: `crm/v1/calling/stages/${stage_id}/export-csv`,
        method: 'GET',
        responseHandler: (response) => response.blob()
      })
    }),
    getQueues: builder.query<QueuesResponse[], QueuesParams>({
      query: (params) => ({
        url: `crm/v1/calling/queue-params`,
        params
      }),
      providesTags: ['Queues']
    }),
    getQueue: builder.query<QueueResponse[], QueueParams>({
      query: (params) => ({
        url: `crm/v1/calling/queue-params`,
        params
      })
    }),
    deleteQueue: builder.mutation<boolean, number>({
      query: (id) => ({
        url: `crm/v1/calling/queue-params/${id}`,
        method: 'POST'
      }),
      invalidatesTags: ['Queues']
    }),
    updateQueue: builder.mutation<boolean, { id: string } & QueueBody>({
      query: ({ id, ...body }) => ({
        url: `crm/v1/calling/queue-params/${id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['Queues']
    }),
    createQueue: builder.mutation<boolean, QueueBody>({
      query: (body) => ({
        url: `crm/v1/calling/queue-params`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Queues']
    }),
    getCallLists: builder.query<CallList[], void>({
      query: () => ({
        url: `crm/v1/calling/call-list-templates`
      }),
      providesTags: ['CallList']
    }),
    getCallList: builder.query<CallList, string>({
      query: (id) => ({
        url: `crm/v1/calling/call-list-templates/${id}`
      }),
      providesTags: (result, error, id) => [{ type: 'CallList', id }]
    }),
    createCallList: builder.mutation<void, CallListRequest>({
      query: (body) => ({
        url: `crm/v1/calling/call-list-templates`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['CallList']
    }),
    updateCallList: builder.mutation<void, { id: string } & CallListRequest>({
      query: ({ id, ...body }) => ({
        url: `crm/v1/calling/call-list-templates/${id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['CallList']
    }),
    deleteCallList: builder.mutation<void, string>({
      query: (id) => ({
        url: `crm/v1/calling/call-list-templates/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['CallList']
    }),
    reUploadCallList: builder.mutation<void, string>({
      query: (template_id) => ({
        url: `crm/v1/calling/call-list-templates/${template_id}/reupload`,
        method: 'POST'
      }),
      invalidatesTags: ['CallList']
    }),
    getCallListCsv: builder.mutation<Blob, string>({
      query: (template_id) => ({
        url: `crm/v1/calling/call-list-templates/${template_id}/export-csv`,
        method: 'GET',
        responseHandler: (response) => response.blob()
      })
    }),
    sendInfoMessage: builder.mutation<void, { event: string }>({
      queryFn: async ({ event }) => {
        try {
          const socket = await getCallsSocket({ path: '' })
          await socket.send(
            JSON.stringify({
              type: 'INFO',
              payload: { event }
            })
          )
          return { data: undefined }
        } catch (error) {
          return { error: error as any }
        }
      }
    }),
    subscribeToCalls: builder.query<SubscribeToCallsResponse, void>({
      queryFn: () => ({ data: { messages: [] } }),
      async onCacheEntryAdded(
        _arg,
        { updateCachedData, cacheEntryRemoved, cacheDataLoaded }
      ) {
        try {
          await cacheDataLoaded
          const socket = await getCallsSocket({ path: '' })
          const listener = (event: MessageEvent) => {
            try {
              const data: WebSocketMessage = JSON.parse(event.data)
              switch (data.type) {
                case 'COMMAND':
                  updateCachedData((draft) => {
                    draft.messages.push(data.payload)
                  })
                  break
                case 'LOGIN':
                  updateCachedData((draft) => {
                    draft.login = data.payload.login
                    draft.password = data.payload.password
                  })
                  break
                default:
                  console.warn('Неизвестный тип сообщения:', data)
              }
            } catch (e) {
              console.error('Ошибка при разборе сообщения WebSocket:', e)
            }
          }
          socket.addEventListener('message', listener)
          await cacheEntryRemoved
          socket.removeEventListener('message', listener)
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
        await cacheEntryRemoved
      }
    }),
    getCalledTodayLoans: builder.query<string[], QueuesParams>({
      query: (params) => ({
        url: `crm/v1/calling/called-today-loans`,
        params
      })
    }),
    uploadFileResecuringLoans: builder.mutation<UploadFileResecuringLoans, any>(
      {
        query: (body) => ({
          url: `/crm/v1/supervisor/upload-loans-csv`,
          method: 'POST',
          body,
          formData: true
        })
      }
    ),
    getLoansAssignmentList: builder.query<LoansAssignmentList[], void>({
      query: () => ({
        url: `/crm/v1/supervisor/loans-assignment-list`,
        method: 'GET'
      }),
      providesTags: (result = []) => [
        ...result.map(
          ({ id }) => ({ type: 'LoansAssignmentList', id }) as const
        ),
        { type: 'LoansAssignmentList' as const, id: 'LIST' }
      ]
    })
  })
})
