import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, Table } from 'reactstrap'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import FieldsFilter from 'src/Components/Common/FieldsFilter'
import { OffsetNotice } from 'src/Components/Common/LimitNotice'
import Loader from 'src/Components/Common/Loader'
import ErrorPage from '../ErrorPage'

import { useTranslation } from 'react-i18next'

import model from 'src/model'

import { formatDateTime, formatAmount } from 'src/utils'
import { FieldName } from './types/applications.types'
import {
  ENABLE_ACTIONS,
  ENABLE_DELETE,
  ENABLE_EDIT,
  HEADERS
} from './constants/applications.constants'
import { UseQueryHookArgs } from '../../../services/api'
import { useGetApplicationsQuery } from '../../../services/applications'
import {
  headerStyle,
  replaceEmptyStringsWithUndefined,
  valueAlign
} from './utils/applications.utils'
import { useArchiveEntityMutation } from '../../../services/calling'
type ExtendedApplicationFilters = UseQueryHookArgs<
  typeof useGetApplicationsQuery
> & {
  series?: string
  number?: string
}
export const Applications = () => {
  const { t } = useTranslation()

  const [fieldsFilter, setFieldsFilter] = useState<
    UseQueryHookArgs<typeof useGetApplicationsQuery>
  >({ offset: 0, limit: 50 })

  const {
    data: list = [],
    isLoading: listLoading,
    error: listError
  } = useGetApplicationsQuery(fieldsFilter)
  const [archiveEntity] = useArchiveEntityMutation()

  const nextPage = () => {
    setFieldsFilter((prev) => ({
      ...prev,
      offset: Math.max(prev.offset + model.LIST_LIMIT, 0)
    }))
  }

  const prevPage = () => {
    setFieldsFilter((prev) => ({
      ...prev,
      offset: Math.max(prev.offset - model.LIST_LIMIT, 0)
    }))
  }

  const title = t('Applications')
  document.title = title

  if (listError) {
    return <ErrorPage title={title} error={listError} />
  }

  const minimizeTextStyle = {
    textOverflow: 'ellipsis',
    maxWidth: 200,
    overflow: 'hidden'
  }

  const bread = <BreadCrumb title={title} />

  const archive = async (entityId: string) => {
    await archiveEntity(entityId)
  }

  return (
    <>
      <UiContent />
      <div className='page-content'>
        {bread}
        <Card>
          <CardBody>
            <div className='table-responsive table-card'>
              <FieldsFilter
                fields={{
                  application_id: {},
                  application_status: {
                    options: [
                      'Received',
                      'Autoverification',
                      'ManualVerificationRequired',
                      'ManualVerification',
                      'RevisionRequired',
                      'Approved',
                      'Declined',
                      'Signed',
                      'NotSigned',
                      'Issued'
                    ]
                  },
                  loan_id: {},
                  created_at: { date: true },
                  client_id: {},
                  profile_id: {},
                  phone: {},
                  email: {},
                  group_delimiter: {},
                  first_name: {},
                  last_name: {},
                  middle_name: {},
                  birth_date: { date: true },
                  gender: { options: ['male', 'female'] },
                  application_short_name: {},
                  verificator_surname: {},
                  is_repeated: { options: ['true', 'false'] },
                  product_code: {
                    label: 'product_type',
                    options: ['PDL', 'IL']
                  },
                  inn: {},
                  snils: {},
                  pasport: {
                    label: 'серия и номер паспорта'
                  },
                  uuid_cbr: {}
                }}
                applyFilter={(values: ExtendedApplicationFilters) => {
                  let newValues: ExtendedApplicationFilters = { ...values }

                  if (values.pasport && typeof values.pasport === 'string') {
                    const cleanedPasport = String(values.pasport)
                    const series = cleanedPasport.slice(0, 4)
                    const number = cleanedPasport.slice(5)
                    newValues = {
                      ...newValues,
                      series,
                      number
                    }
                    delete newValues.pasport
                  }
                  const updatedValues =
                    replaceEmptyStringsWithUndefined(newValues)

                  setFieldsFilter({
                    ...updatedValues,
                    offset: 0,
                    limit: 50,
                    gender: model.getGenderCode(updatedValues.gender)
                  })
                }}
              />
              <div
                className='btn-group gap-2 p-3'
                role='group'
                aria-label='Basic example'
              >
                <button
                  type='button'
                  className='btn btn-primary d-flex align-items-center'
                  disabled={fieldsFilter.offset === 0}
                  onClick={prevPage}
                >
                  <i className='ri-arrow-left-line fs-15 me-2' />
                  {`${t('Prev')} ${model.LIST_LIMIT}`}
                </button>
                <button
                  type='button'
                  className='btn btn-primary d-flex align-items-center'
                  disabled={list?.length < model.LIST_LIMIT}
                  onClick={nextPage}
                >
                  {`${t('Next')} ${model.LIST_LIMIT}`}
                  <i className='ri-arrow-right-line fs-15 ms-2' />
                </button>
              </div>
              <OffsetNotice
                offset={fieldsFilter.offset}
                limit={model.LIST_LIMIT}
              />
              {listLoading ? (
                <Loader />
              ) : (
                <Table
                  hover
                  className='table-sm align-middle table-nowrap mb-0'
                >
                  <thead>
                    <tr>
                      {Object.keys(HEADERS).map((header) => (
                        <th
                          style={headerStyle(HEADERS[header])}
                          key={header}
                          scope='col'
                        >
                          {t(HEADERS[header].name)}
                        </th>
                      ))}
                      {ENABLE_ACTIONS && <th scope='col'>{t('Actions')}</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {list &&
                      list.length > 0 &&
                      list.map((entity) => {
                        return (
                          <tr key={entity.id}>
                            {Object.keys(HEADERS).map((fieldName) => {
                              const value = entity[fieldName as FieldName]
                              const key = `${fieldName}`

                              if (fieldName === 'id')
                                return (
                                  <th scope='row' key={key}>
                                    <Link
                                      to={`/application-view?id=${value}`}
                                      className='fw-medium'
                                    >
                                      <i className='ri-eye-line fs-15' />
                                    </Link>
                                  </th>
                                )

                              if (fieldName === 'mobile')
                                return (
                                  <td key={key} style={minimizeTextStyle}>
                                    {entity.profile_phone}
                                  </td>
                                )
                              if (fieldName === 'email')
                                return (
                                  <td key={key} style={minimizeTextStyle}>
                                    {entity.profile_email}
                                  </td>
                                )
                              if (fieldName === 'client')
                                return (
                                  <td
                                    key={key}
                                    style={{
                                      whiteSpace: 'nowrap',
                                      ...minimizeTextStyle
                                    }}
                                  >
                                    {`${entity.client_surname} ${entity.client_name} ${entity.client_midname}`}
                                  </td>
                                )

                              if (fieldName === 'updated_at')
                                return (
                                  <td key={key} style={minimizeTextStyle}>
                                    {formatDateTime(
                                      entity.updated_at || entity.created_at
                                    )}
                                  </td>
                                )

                              if (
                                fieldName === 'approved_amount' ||
                                fieldName === 'requested_amount' ||
                                fieldName === 'signed_amount'
                              ) {
                                return (
                                  <td
                                    style={minimizeTextStyle}
                                    align={valueAlign(HEADERS[fieldName])}
                                    key={key}
                                  >
                                    {formatAmount(
                                      typeof value === 'number' ? value : null,
                                      undefined
                                    )}
                                  </td>
                                )
                              }
                              if (fieldName === 'product_copy') {
                                return (
                                  <td
                                    style={minimizeTextStyle}
                                    align={valueAlign(HEADERS[fieldName])}
                                  >
                                    {typeof value === 'object'
                                      ? value?.product_code
                                      : '-'}
                                  </td>
                                )
                              }

                              return (
                                <td
                                  style={minimizeTextStyle}
                                  align={valueAlign(HEADERS[fieldName])}
                                  key={key}
                                >
                                  {value?.toString() || '-'}
                                </td>
                              )
                            })}
                            {ENABLE_ACTIONS && (
                              <td>
                                <div className='hstack gap-3 flex-wrap'>
                                  {
                                    <Link
                                      to={`/application-view?id=${entity.id}`}
                                      className='link-success fs-15'
                                    >
                                      <i className='ri-eye-line'></i>
                                    </Link>
                                  }
                                  {ENABLE_EDIT && (
                                    <Link
                                      to={`/application-view?id=${entity.id}&edit=true`}
                                      className='link-success fs-15'
                                    >
                                      <i className='ri-edit-2-line'></i>
                                    </Link>
                                  )}
                                  {ENABLE_DELETE && (
                                    <Link
                                      to='#'
                                      onClick={() => archive(entity.id)}
                                      className='link-danger fs-15'
                                    >
                                      <i className='ri-delete-bin-line'></i>
                                    </Link>
                                  )}
                                </div>
                              </td>
                            )}
                          </tr>
                        )
                      })}
                  </tbody>
                </Table>
              )}
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  )
}
