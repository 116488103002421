import React, { FC, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import LoadingPage from '../LoadingPage'
import Loader from 'src/Components/Common/Loader'
import ErrorPage, { DangerAlert } from '../ErrorPage'
import { crateLinkButtonView } from 'src/Components/Common/CreateLinkButtonView'
import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  Button,
  Alert
} from 'reactstrap'

import { InputField } from './types'
import { paymentsLink } from './constants'

import model from 'src/model'
import {
  useGetPaymentTypesQuery,
  useGetTransactionByIdQuery,
  useUpdateTransactionMutation
} from 'src/services/payments'
import { useGetUserInfoQuery } from 'src/services/keycloak'

export const PaymentView: FC = () => {
  const { t } = useTranslation()
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [searchParams] = useSearchParams()

  const id = searchParams.get('id') as string
  const edit = searchParams.get('edit') as string

  const { data: paymentTypes } = useGetPaymentTypesQuery()
  const { data: userInfo } = useGetUserInfoQuery()

  const {
    data: payment,
    error: paymentError,
    isLoading: paymentLoading
  } = useGetTransactionByIdQuery(id, {
    skip: !id
  })

  const [
    updatePayment,
    {
      isLoading: isUpdating,
      isSuccess: updatePaymentIsSuccess,
      error: updatePaymentError
    }
  ] = useUpdateTransactionMutation()

  const inputInitials: Record<string, InputField> = {
    loan_id: { label: 'id', initial: '', placeholder: '-' },
    direction: { label: 'direction', initial: '', placeholder: '-' },
    amount: { isAmount: true, label: 'amount', initial: '', placeholder: '-' },
    comment: {
      label: 'comment',
      initial: '',
      placeholder: '-'
    },
    payment_type: {
      label: 'payment_type',
      initial: '',
      placeholder: '-',
      options: (paymentTypes ?? []).map((value) => ({
        name: value.name,
        value: value.id
      })),
      mappingValue: (value) =>
        paymentTypes?.find((it) => it.id === value)?.name ?? '-'
    },
    is_accepted: { label: 'is_accepted', initial: '-', placeholder: '-' },
    prepayment: {
      label: 'prepayment',
      fieldName: 'is_prepayment',
      initial: '-',
      placeholder: '-',
      mappingValue: (value: boolean) => (value ? '1' : '-')
    },
    receipt_date: {
      isDateTime: true,
      label: 'Дата поступления',
      initial: '',
      placeholder: '-'
    },
    accept_date: {
      isDateTime: true,
      label: 'accept_date',
      initial: '',
      placeholder: '-'
    },
    last_name: { label: 'last_name', initial: '', placeholder: '-' },
    first_name: { label: 'first_name', initial: '', placeholder: '-' },
    middle_name: { label: 'middle_name', initial: '', placeholder: '-' }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...payment
    },
    onSubmit: (values) => {
      updatePayment({
        fund_id: payment!.id,
        body: {
          loan_id: payment!.loan_id,
          payment_type: values.payment_type ? Number(values.payment_type) : null
        }
      })
    }
  })

  //Условие, при котором можно редактировать транзакцию
  const isEditable =
    payment?.payment_type === 1 &&
    payment?.is_prepayment === true &&
    new Date(payment?.accept_date).setHours(0, 0, 0, 0) >
      new Date().setHours(0, 0, 0, 0) &&
    userInfo?.permissions.includes('РЕДАКТИРОВАНИЕ ТИПА ПЛАТЕЖЕЙ')

  useEffect(() => {
    if (edit === 'true' && isEditable) {
      setIsEdit(true)
    } else {
      setIsEdit(false)
    }
  }, [edit, isEditable])

  const pageTitle = t(isEdit ? 'Edit payment' : 'View payment')
  document.title = t(pageTitle)

  if (!id || paymentError) {
    return (
      <ErrorPage
        backLink={paymentsLink}
        title={pageTitle}
        error={paymentError ? paymentError : 'No payment id found'}
      />
    )
  }

  if (paymentLoading) {
    return <LoadingPage backLink={paymentsLink} title={pageTitle} />
  }

  const profileLink = crateLinkButtonView(
    `/profile-view?id=${payment?.profile_id?.toString()}`,
    <i className='ri-account-circle-line fs-16'></i>
  )
  const loanLink = crateLinkButtonView(
    `/loan-view?id=${payment?.loan_id?.toString()}`,
    <i className='ri-pie-chart-line fs-16'></i>
  )

  return (
    <>
      <UiContent />
      <div className='page-content'>
        <BreadCrumb title={pageTitle} />
        <Card>
          <CardBody>
            <MetaBar
              backLink={paymentsLink}
              editLink={
                !isEdit && isEditable
                  ? `/payment-view?id=${payment.id}&edit=true`
                  : null
              }
              entity={payment}
              buttons={[profileLink, loanLink]}
            />
            <Row className='mt-3'>
              {Object.keys(inputInitials).map((fieldKey, _i) => {
                const htmlForId = `validation-${fieldKey}`
                return (
                  <Col className='col-auto' key={`${fieldKey}`}>
                    <Label htmlFor={htmlForId}>
                      {t(inputInitials[fieldKey].label)}
                    </Label>
                    {isEdit && fieldKey === 'payment_type' ? (
                      <select
                        name={fieldKey}
                        id={fieldKey}
                        className='form-select form-select-sm'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        /*@ts-ignore*/
                        value={formik.values[fieldKey] || ''}
                        disabled={updatePaymentIsSuccess}
                      >
                        {inputInitials[fieldKey].options?.map((option: any) => (
                          <option key={option.name} value={option.value}>
                            {t(option.name)}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <Input
                        name={`${fieldKey}`}
                        type='text'
                        className='form-control form-control-sm'
                        id={htmlForId}
                        value={model.fieldValueToText(
                          fieldKey,
                          payment,
                          inputInitials
                        )}
                        disabled
                      />
                    )}
                  </Col>
                )
              })}
            </Row>
            {!!updatePaymentError && <DangerAlert error={updatePaymentError} />}
            {isEdit &&
              (isUpdating ? (
                <Loader />
              ) : (
                <Button
                  onClick={() => formik.handleSubmit()}
                  color='primary'
                  style={{ backgroundColor: '#405189' }}
                  className='mt-3'
                  disabled={isUpdating || updatePaymentIsSuccess}
                >
                  {t('Edit')}
                </Button>
              ))}
            {updatePaymentIsSuccess && (
              <Alert className='mt-3' color='success'>
                <strong> {t('Operation success')} </strong>
              </Alert>
            )}
          </CardBody>
        </Card>
      </div>
    </>
  )
}
