import { LayoutItem, LayoutSubItem } from '../layoutItems.types'

export const getAdminData = () => {
  const subItems: LayoutSubItem[] = [
    {
      id: 'admin-promocodes',
      label: 'Promocodes',
      icon: 'bx bx-pencil',
      link: '/promocodes',
      childItems: [
        {
          id: 'promocodes',
          label: 'View all',
          link: '/promocodes'
        },
        {
          id: 'promocodes-create',
          label: 'Create promocode',
          link: '/promocodes-create'
        },
        {
          id: 'promocodes-view',
          label: 'View promocode',
          hidden: true,
          link: '/promocodes-view'
        },
        {
          id: 'promocodes-edit',
          label: 'Edit promocode',
          hidden: true,
          link: '/promocodes-edit'
        }
      ]
    },
    {
      id: 'admin-promocodes-groups',
      label: 'promo_code_groups',
      icon: 'bx bx-pencil',
      link: '/promocodes-groups',
      childItems: [
        {
          id: 'promocodes-groups',
          label: 'View all',
          link: '/promocodes-groups'
        },
        {
          id: 'promocodes-groups-create',
          label: 'Создать новую группу промокодов',
          link: '/promocodes-groups-create'
        }
      ]
    },
    {
      id: 'admin-holidays',
      label: 'Типы кредитных каникул',
      icon: 'bx bx-pencil',
      link: '/holidays_types',
      childItems: [
        {
          id: 'view-all-holidays',
          label: 'View all',
          link: '/holidays'
        },
        {
          id: 'holidays-create',
          label: 'Создать тип КК',
          link: '/holidays-create'
        }
      ]
    },
    {
      id: 'admin-products',
      label: 'Products',
      icon: 'bx bx-pencil',
      link: '/products',
      childItems: [
        { id: 'view-all-products', label: 'View all', link: '/products' },
        {
          id: 'product-create',
          label: 'Create product',
          link: '/product-create'
        },
        {
          id: 'product-view',
          label: 'View Product',
          hidden: true,
          link: '/product-view'
        },
        {
          id: 'product-edit',
          label: 'Edit Product',
          hidden: true,
          link: '/product-edit'
        }
      ]
    },
    {
      id: 'roles',
      link: '/roles',
      icon: ' ri-contacts-book-2-line',
      label: 'Roles'
    },
    {
      id: 'users',
      label: 'Users',
      link: '/users',
      icon: ' ri-contacts-book-2-line'
    },
    {
      id: 'admin-tags',
      link: '/mass-add-tags',
      icon: '',
      label: 'Tags'
    },
    {
      id: 'admin-product-extensions',
      label: 'extensions_settings',
      icon: 'bx bx-pencil',
      link: '/product-extensions',
      childItems: [
        {
          id: 'product-extensions',
          label: 'View all',
          link: '/product-extensions'
        },
        {
          id: 'product-extensions-view',
          label: 'View Extension',
          hidden: true,
          link: '/product-extensions-view'
        },
        {
          id: 'product-extensions-edit',
          label: 'Edit Extension',
          hidden: true,
          link: '/product-extensions-edit'
        },
        {
          id: 'extension-types',
          label: 'Extension types',
          link: '/extension-types'
        }
      ]
    },
    {
      id: 'admin-external-services-settings',
      label: 'External_services_settings',
      link: '/external-services-settings',
      childItems: [
        {
          id: 'external-services-settings',
          label: 'View all',
          link: '/external-services-settings'
        },
        {
          id: 'external-services-settings-view',
          label: 'View',
          hidden: true,
          link: '/external-services-settings-view'
        },
        {
          id: 'external-services-settings-edit',
          label: 'Edit',
          hidden: true,
          link: '/external-services-settings-edit'
        }
      ]
    },
    {
      id: 'admin-document-types',
      label: 'Document_types',
      link: '/document-types',
      childItems: [
        {
          id: 'document-types',
          label: 'View all',
          link: '/document-types'
        }
        //{ id: 2, label: "Create", link: "/document-types-create", parentId: "admin" },
        //{ id: 3, label: "View", link: "/document-types-view", parentId: "admin", hidden: true },
        //{ id: 4, label: "Edit", link: "/document-types-edit", parentId: "admin", hidden: true },
      ]
    },
    {
      id: 'admin-notifications-templates',
      label: 'Notifications_templates',
      link: '/notifications-templates',
      childItems: [
        {
          id: 'notifications-templates',
          label: 'View all',
          link: '/notifications-templates'
        },
        {
          id: 'notifications-templates-create',
          label: 'Create',
          link: '/notifications-templates-create'
        },
        {
          id: 'notifications-templates-view',
          label: 'View',
          hidden: true,
          link: '/notifications-templates-view'
        },
        {
          id: 'notifications-templates-edit',
          label: 'Edit',
          hidden: true,
          link: '/notifications-templates-edit'
        }
      ]
    },
    {
      id: 'admin-notifications-mapping-templates',
      label: 'Notifications_mapping_templates',
      link: '/notifications-mapping-templates',
      childItems: [
        {
          id: 'notifications-mapping-templates',
          label: 'View all',
          link: '/notifications-mapping-templates'
        },
        {
          id: 'notifications-mapping-templates-create',
          label: 'Create',
          link: '/notifications-mapping-templates-create'
        },
        {
          id: 'notifications-mapping-templates-view',
          label: 'View',
          hidden: true,
          link: '/notifications-mapping-templates-view'
        },
        {
          id: 'notifications-mapping-templates-edit',
          label: 'Edit',
          hidden: true,
          link: '/notifications-mapping-templates-edit'
        }
      ]
    },
    {
      id: 'admin-notifications-senders',
      label: 'Notifications_senders',
      link: '/notifications-senders',
      childItems: [
        {
          id: 'notifications-senders',
          label: 'View all',
          link: '/notifications-senders'
        },
        {
          id: 'notifications-senders-create',
          label: 'Create',
          link: '/notifications-senders-create'
        },
        {
          id: 'notifications-senders-view',
          label: 'View',
          hidden: true,
          link: '/notifications-senders-view'
        },
        {
          id: 'notifications-senders-edit',
          label: 'Edit',
          hidden: true,
          link: '/notifications-senders-edit'
        }
      ]
    },
    {
      id: 'admin-ros-fin-monitoring',
      label: 'Rosfinmonitoring',
      link: '/ros-fin-monitoring'
    },
    {
      id: 'admin-strategy',
      label: 'Communications',
      link: '/strategy',
      childItems: [
        {
          id: 'strategies',
          label: 'strategies',
          link: '/strategy'
        },
        {
          id: 'strategy-templaes',
          label: 'templates',
          link: '/strategy/templates'
        }
      ]
    },
    {
      id: 'bki-sent-file',
      label: 'Viewing files in BKI',
      link: '/bki-sent-file'
    },
    {
      id: 'payment-gate',
      label: 'Payment gate',
      link: '/payment-gate'
    },
    {
      id: 'writeoff-statuses',
      label: 'statuses',
      link: '/writeoff-statuses'
    },
    {
      id: 'monitoring-system',
      label: 'Monitoring System',
      link: '/monitoring'
    }
  ]

  const adminData: LayoutItem = {
    id: 'admin',
    label: 'Admin',
    icon: 'ri-settings-3-fill',
    subItems
  }

  return adminData
}
