import Client from '../client'

import { formatAmount, formatDateTime, formatDateWithDash } from '../utils'

function sortByCreated(entity1, entity2) {
  return new Date(entity1.created_at) - new Date(entity2.created_at)
}

function getDocumentImgUrl(doc) {
  if (!doc) return null
  const imgSource = `${Client.END_POINTS[Client.ENTITY_TYPE.DOCUMENTS]}/${
    doc.id
  }` // doc.fileData;
  return imgSource
}

function getShortId(id) {
  if (!id) return '-'
  return `${id.substr(0, 9)}...`
}

function getStrGender(genderCode) {
  const code = parseInt(genderCode)
  if (isNaN(code)) return '-'
  return code === 0 ? 'female' : 'male'
}

function getGenderCode(strGender) {
  return strGender === 'female' ? 0 : strGender === 'male' ? 1 : strGender
}

function getObjKeys(obj) {
  return Object.values(obj).join(' ')
}

function getObjList(obj) {
  if (!obj) {
    return '-'
  }
  if (Object.keys(obj).length == 0) {
    return '-'
  }
  return JSON.stringify(obj, null, 2)
}

function formatEx(bool) {
  if (bool === null) return 'внутренняя'
  else return 'внешняя'
}

function formatPercent(num) {
  if (!num) return '-'
  else return `${num * 100}%`
}

export function fieldValueToText(fieldKey, entity, fieldsDescription) {
  if (!entity) return '-'

  const descr = fieldsDescription ? fieldsDescription[fieldKey] || {} : {}
  const fieldName = descr.fieldName || fieldKey

  if (descr.isEmpty) return ''

  if (descr.isGender) return getStrGender(entity[fieldName])

  if (descr.isDate) return formatDateWithDash(entity[fieldName])

  if (descr.isDateTime) return formatDateTime(entity[fieldName])

  if (descr.isAmount)
    return formatAmount(entity[fieldName], fieldsDescription[fieldKey].decimals)

  if (descr.isShortId) return getShortId(entity[fieldName])

  if (descr.isObj) return getObjKeys(entity[fieldName])

  if (descr.isObjList) return getObjList(entity[fieldName])

  if (descr.isExternal) return formatEx(entity[fieldName])

  if (descr.isPercent) return formatPercent(entity[fieldName])

  if (descr.mappingValue) return descr.mappingValue(entity[fieldName])

  if (descr.isAddress && entity[descr.addressField]) {
    const address = entity[descr.addressField]
    const {
      address_region,
      address_city,
      address_zipcode,
      address_street,
      address_apt
    } = address

    if (descr.address_region) {
      return address_region
    }

    if (descr.address_city) {
      return address_city
    }

    if (descr.full) {
      return `${address_zipcode}, ${address_street}, apt ${address_apt}, ${address_city} ${address_region}`
    }
  }

  if (descr.isContact) {
    return `${entity[descr.nameField]}: ${entity[descr.phoneField]}`
  }

  return entity[fieldName] ||
    entity[fieldName] === false ||
    entity[fieldName] === 0
    ? entity[fieldName].toString()
    : '-'
}

function getPaymentStatus(status) {
  return status === 'Confirmed'
}

export const LIST_LIMIT = Client.LIST_LIMIT

export default {
  getShortId,
  getStrGender,
  getGenderCode,
  fieldValueToText,
  getDocumentImgUrl,
  sortByCreated,
  getPaymentStatus,
  LIST_LIMIT
}
