import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'
import Select, { MultiValue } from 'react-select'

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Alert,
  Spinner
} from 'reactstrap'

// Formik validation
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { INITIAL_AGENCIES_FIELDS } from './constants/agencies.constants'
import {
  AgencyBody,
  AgencyResponse,
  useAddAgencyMutation,
  useLazyGetAgencyQuery,
  useLinkKAMutation,
  useUpdateAgencyMutation
} from '../../../services/agencies'
import { useNavigate } from 'react-router-dom'
import { useGetAllSMSTemplatesQuery } from '../../../services/communications'
import { Options } from './types/agencies.types'
import { handleMessageError } from 'src/utils'

export const AgencyEdit = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = new URLSearchParams(window.location.search)

  const [id, setId] = useState<string>('')
  const [show, setShow] = useState(false)
  const [options, setOptions] = useState<Options[]>([])
  const [initialFormValues, setInitialFormValues] = useState<
    Record<string, string | boolean | null>
  >({})
  const [selectedTemplate, setSelectedTemplate] = useState<number[]>([])
  const [messageError, setMessageError] = useState('')

  const [getAgency, { data: agency, isLoading, error: agencyError }] =
    useLazyGetAgencyQuery()
  const { data: templates } = useGetAllSMSTemplatesQuery()
  const [createAgency, { isLoading: createLoading, error: createError }] =
    useAddAgencyMutation()
  const [updateAgency, { isLoading: updateLoading, error: updateError }] =
    useUpdateAgencyMutation()
  const [linkKA] = useLinkKAMutation()

  useEffect(() => {
    if (templates) {
      const choices = templates.map((obj) => ({
        value: obj.id,
        label: obj.template
      }))

      setOptions(choices)
    }
  }, [templates])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const agencyId = params.get('id')

    setId(agencyId || '')

    if (agencyId) {
      //edit mode
      getAgency(agencyId)
    } else {
      //create mode
      const initialFormValues: Record<string, string | boolean> = {}

      for (const [key, value] of Object.entries(INITIAL_AGENCIES_FIELDS)) {
        initialFormValues[key] = value.initial
      }

      setInitialFormValues(initialFormValues)
    }
  }, [getAgency])

  useEffect(() => {
    if (id && agency) {
      //edit mode
      const initialFormValues: Record<string, string | boolean | null> = {}

      Object.keys(INITIAL_AGENCIES_FIELDS).forEach((key) => {
        const typedKey = key as keyof AgencyResponse

        if (INITIAL_AGENCIES_FIELDS[key].label === 'is_external') {
          initialFormValues[key] =
            agency[typedKey] !== null ? agency[typedKey] : false
        } else {
          const value = agency ? agency[typedKey] : undefined
          initialFormValues[key] =
            value !== null && value !== undefined ? value.toString() : ''
        }
      })

      setInitialFormValues(initialFormValues)
    }
  }, [agency, id])

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: { ...initialFormValues },
    validationSchema: Yup.object({}),
    onSubmit: async (values) => {
      const { ...data } = values

      if (values.is_external === true || values.is_external === 'true') {
        data.is_external = true
      } else {
        data.is_external = null
      }

      if (id) {
        const updateAgencyBody = { id, ...data } as AgencyBody & { id: string }
        const linkKABody = { id, selectedTemplate }

        try {
          await Promise.all([
            updateAgency(updateAgencyBody),
            linkKA(linkKABody)
          ])
          navigate(`/external-agencies-view?id=${id}`)
        } catch (error) {
          console.error(error)
        }
      } else {
        const { success } = await createAgency(data as AgencyBody).unwrap()

        if (success) {
          navigate('/external-agencies')
        }
      }
    }
  })

  useEffect(() => {
    if (validation.values.is_external === 'false') {
      setShow(true)
    } else if (validation.values.is_external === 'true') {
      setShow(false)
    }
  }, [validation.values.is_external])

  const innMessage = t('This inn already exists')

  useEffect(() => {
    if (createError || updateError) {
      const message = handleMessageError(createError, innMessage)
      setMessageError(message || 'Something went Wrong')
    }
  }, [innMessage, createError, updateError])

  const handleSelectChangeTemplate = (selectedValues: MultiValue<Options>) => {
    const selectedOptionValues = selectedValues.map((option) => option.value)
    setSelectedTemplate(selectedOptionValues)
  }

  const title = `${t('Agencies')}: ${id ? t('Edit') : t('Create')}`
  document.title = title

  const bread = <BreadCrumb title={title} />
  const loading = <Spinner color='primary'>{t('Loading...')}</Spinner>
  const metaBar = <MetaBar backLink={'/external-agencies'} entity={agency} />

  if (isLoading) {
    return <LoadingPage backLink={'/external-agencies'} title={title} />
  }

  if (!agency?.id && id) {
    return (
      <ErrorPage
        backLink={'/external-agencies'}
        title={title}
        error={`invalid agency_id : ${id}`}
      />
    )
  }

  if (agencyError) {
    return (
      <ErrorPage
        backLink={'/external-agencies'}
        title={title}
        error={[agencyError]}
      />
    )
  }

  return (
    <>
      <UiContent />
      <div className='page-content'>
        {bread}
        <Card>
          <CardBody>
            {metaBar}
            <Form
              className='needs-validation mt-4'
              onSubmit={(e) => {
                e.preventDefault()
                validation.handleSubmit()

                return false
              }}
            >
              <Row>
                {Object.keys(INITIAL_AGENCIES_FIELDS).map((fieldKey) => {
                  if (INITIAL_AGENCIES_FIELDS[fieldKey].emptyLine)
                    return <Col key={`${fieldKey}`} className='col-12' />

                  const htmlForId = `validation-${fieldKey}`

                  return (
                    <Col
                      className={
                        INITIAL_AGENCIES_FIELDS[fieldKey].fullWidth
                          ? 'col-12'
                          : 'col-auto'
                      }
                      key={`${fieldKey}`}
                    >
                      <FormGroup className='mb-3'>
                        <Label htmlFor={htmlForId}>
                          {t(INITIAL_AGENCIES_FIELDS[fieldKey].label)}
                        </Label>
                        {INITIAL_AGENCIES_FIELDS[fieldKey].options &&
                        INITIAL_AGENCIES_FIELDS[fieldKey].options?.length ? (
                          <select
                            name={`${fieldKey}`}
                            id={htmlForId}
                            className='form-select form-select-sm'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={String(validation.values[fieldKey]) ?? ''}
                          >
                            {INITIAL_AGENCIES_FIELDS[fieldKey].options?.map(
                              (option) => (
                                <option
                                  key={option.name}
                                  value={String(option.value)}
                                >
                                  {option.name}
                                </option>
                              )
                            )}
                          </select>
                        ) : (
                          <Input
                            name={`${fieldKey}`}
                            placeholder={
                              INITIAL_AGENCIES_FIELDS[fieldKey].placeholder
                            }
                            type='text'
                            className='form-control form-control-sm'
                            id={htmlForId}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={String(validation.values[fieldKey]) ?? ''}
                            invalid={
                              !!(
                                validation.touched[fieldKey] &&
                                validation.errors[fieldKey]
                              )
                            }
                          />
                        )}
                        {validation.touched[fieldKey] &&
                        validation.errors[fieldKey] ? (
                          <FormFeedback type='invalid'>
                            {validation.errors[fieldKey]}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                  )
                })}
              </Row>

              {show && Boolean(params.get('id')) && (
                <>
                  {t('Template')}
                  <Select
                    isMulti
                    options={options}
                    onChange={handleSelectChangeTemplate}
                  />
                </>
              )}

              {(!!updateError || !!createError) && (
                <Alert color='warning'>
                  <strong> {messageError} </strong>
                </Alert>
              )}

              {updateLoading || createLoading ? (
                loading
              ) : (
                <Button
                  className='text-nowrap'
                  color='primary'
                  style={{ backgroundColor: '#405189' }}
                  type='submit'
                >
                  {id ? t('Update') : t('Create')}
                </Button>
              )}
            </Form>
          </CardBody>
        </Card>
      </div>
    </>
  )
}
