import { Link, useLocation } from 'react-router-dom'
import { LayoutSubItem } from '../layoutMenuData/layoutItems.types'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Collapse } from 'reactstrap'
import { VerticalLayoutChildItem } from './VerticalLayoutChildItem'

interface IVerticalLayoutSubItem {
  subItem: LayoutSubItem
}

const VerticalLayoutSubItem: React.FC<IVerticalLayoutSubItem> = ({
  subItem
}) => {
  const { t } = useTranslation()
  const [isOpen, setOpen] = useState<boolean>(false)
  const { pathname } = useLocation()
  const { label, link, childItems } = subItem

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (childItems?.length) {
      e.preventDefault()
      setOpen((isOpen) => !isOpen)
    }
  }

  const activeClass = useMemo(
    () =>
      pathname === link ||
      childItems?.some((childItem) => childItem.link === pathname)
        ? 'active'
        : '',
    [link, childItems, pathname]
  )

  return (
    <Col lg={4}>
      <ul className='nav nav-sm flex-column'>
        <li className='nav-item'>
          <Link
            onClick={handleClick}
            data-bs-toggle={childItems ? 'collapse' : ''}
            className={`nav-link ${activeClass}`}
            to={link || '/#'}
          >
            {t(label)}
          </Link>
          {childItems?.length && (
            <Collapse
              className='menu-dropdown'
              id='sidebarEcommerce'
              isOpen={isOpen}
            >
              <ul className='nav nav-sm flex-column'>
                {childItems.map(
                  (childItem) =>
                    !childItem.hidden && (
                      <VerticalLayoutChildItem
                        childItem={childItem}
                        key={childItem.id}
                      />
                    )
                )}
              </ul>
            </Collapse>
          )}
        </li>
      </ul>
    </Col>
  )
}

export { VerticalLayoutSubItem }
