import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'src/store'

import { Row, Container } from 'reactstrap'

import Client from '../../client'

import CPA from './EntitiesView/CPA'
import EntityClient from './EntitiesView/Client'
import Documents from './EntitiesView/Documents'
import Dialogues from './EntitiesView/Dialogues'
import Verification from './EntitiesView/Verification'
import Notifications from './EntitiesView/Notifications'
import DocumentsSigned from './EntitiesView/DocumentsSigned'
import CallResult from './EntitiesView/CallResult'
import VerificationHistory from './EntitiesView/VerificationHistory'
import Transactions from './EntitiesView/Funds'
import SideList from './EntitiesView/SideList'
import Operations from './EntitiesView/Operations'
import Tranches from './EntitiesView/Tranches'
import Holidays from './EntitiesView/Holidays'
import Agencies from './EntitiesView/Agencies'
import Profile from './EntitiesView/Profile'
import Loan from './EntitiesView/Loan'
import Application from './EntitiesView/Application'
import Product from './EntitiesView/Product'
import ProductOriginal from './EntitiesView/ProductOriginal'
import Extension from './EntitiesView/Extension'
import BlockClient from './EntitiesView/BlockClient'
import LoansBKI from './EntitiesView/LoansBki'
import LoansTags from './EntitiesView/LoansTags'
import ClientTags from './EntitiesView/ClientsTags'
import RecPayments from './EntitiesView/RecPayments'
import Communications from './EntitiesView/Communications'
import HistoryClaims from './EntitiesView/HistoryClaims'
import { Chargeback } from './EntitiesView/Chargeback'
import { AtolLoan } from './EntitiesView/AtolLoan'
import { ClientSignedDocuments } from './EntitiesView/ClientSignedDocuments'
import { ApplicationsUprid } from './EntitiesView/ApplicationsUprid'
import { ApplicationsKI } from './EntitiesView/ApplicationsKI'
import { IdentificationHistory } from './EntitiesView/IdentificationHistory'
import { MembershipInfo } from '../kpkEntity/MembershipInfo'
import { LoanHistory } from './EntitiesView/LoanHistory'
import { PaymentSchedule } from './EntitiesView/PaymentSchedule'
import { TrafficSource } from './EntitiesView/TrafficSource'

const DefaultView = ({ entity }) => {
  const { t } = useTranslation()
  return (
    <Container fluid={true}>
      <Row>
        {Object.keys(entity).map((entityKey, rank) => {
          return (
            <React.Fragment key={`${entityKey}-${rank}`}>
              <dl className='col-md-4 col-sm-6'>
                <dt>{`${t(entityKey)}`}</dt>
                <dd>{entity[entityKey]?.toString()}</dd>
              </dl>
            </React.Fragment>
          )
        })}
      </Row>
    </Container>
  )
}

const EntityInfo = (props) => {
  const entity = props.entity || {}

  let Comp = null

  const error = useSelector(
    (state) =>
      state.entities[props.entityType]?.listError ||
      state.entities[props.entityType]?.entityError ||
      state.entities[props.entityType]?.updateError ||
      state.entities[props.entityType]?.createError
  )

  if (props.entityType !== Client.ENTITY_TYPE.DIALOGUES && !entity) return null

  switch (props.entityType) {
    case 'block_client':
      Comp = BlockClient
      break
    case 'documents_signed':
      Comp = DocumentsSigned
      break
    case 'client_documents_signed':
      Comp = ClientSignedDocuments
      break
    case 'communications':
      Comp = CallResult
      break
    case 'profile_notifications':
      Comp = Notifications
      break
    case 'membership_history_kpk':
      Comp = MembershipInfo
      break
    case 'application_auto_verification':
      Comp = Verification
      break
    case 'application_verification_history':
      Comp = VerificationHistory
      break
    case 'loan_transactions':
      Comp = Transactions
      break
    case 'loan_operations':
      Comp = Operations
      break
    case 'loan_tranches':
      Comp = Tranches
      break
    case 'loan_holidays':
      Comp = Holidays
      break
    case 'loan_agencies':
      Comp = Agencies
      break
    case 'loans_bki':
      Comp = LoansBKI
      break
    case 'profile_cpa_lead':
      Comp = CPA
      break
    case 'extension_setting':
      Comp = Extension
      break
    case 'addon_applications':
      Comp = SideList
      break
    case 'addon_loans':
      Comp = SideList
      break
    case 'addon_payments':
      Comp = SideList
      break
    case 'loans_tags':
      Comp = LoansTags
      break
    case 'clients_tags':
      Comp = ClientTags
      break
    case 'rec_payments':
      Comp = RecPayments
      break
    case Client.ENTITY_TYPE.DIALOGUES:
      Comp = Dialogues
      break
    case Client.ENTITY_TYPE.CLIENTS:
      Comp = EntityClient
      break
    case Client.ENTITY_TYPE.DOCUMENTS:
      Comp = Documents
      break
    case Client.ENTITY_TYPE.PROFILES:
      Comp = Profile
      break
    case Client.ENTITY_TYPE.APPLICATIONS:
      Comp = Application
      break
    case Client.ENTITY_TYPE.PRODUCT:
      Comp = entity.product_loan ? Product : ProductOriginal
      break
    case Client.ENTITY_TYPE.EXTENSIONS:
      Comp = Extension
      break
    case Client.ENTITY_TYPE.LOANS:
      Comp = Loan
      break
    case Client.ENTITY_TYPE.COMMUNICATIONS:
      Comp = Communications
      break
    case 'history_claims':
      Comp = HistoryClaims
      break
    case 'chargeback':
      Comp = Chargeback
      break
    case 'atol':
      Comp = AtolLoan
      break
    case 'loan_history':
      Comp = LoanHistory
      break
    case 'applications_uprid':
      Comp = ApplicationsUprid
      break
    case 'applications_bki':
      Comp = ApplicationsKI
      break
    case 'identification_history':
      Comp = IdentificationHistory
      break
    case 'payment_schedule':
      Comp = PaymentSchedule
      break
    case 'traffic_source':
      Comp = TrafficSource
      break
    default:
      Comp = DefaultView
  }
  return <Comp {...props} error={error} />
}

export default EntityInfo
